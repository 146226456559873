import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid } from "@mui/material";

const SkeletonLoaderAddForm = () => {

    return (
        <Stack sx={{mt: "-10px"}}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={3}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={2}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={2}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={2}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={2}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={4}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={1}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}

export default SkeletonLoaderAddForm