import axios from "axios";
import axiosRetry from "axios-retry";
import { signOut } from "../../slices/EnvironmentSlice";
import { signout } from "../../components/user/userServices";
import { API } from "./../../config";

/* Retry request */
axiosRetry(axios, { retries: 3 });

export const apiHeader = (token) => {
    return {
        'Accept': "application/json",
        'Authorization': `Bearer ${token}`
    }
}

export const apiHeaderX = (token) => {
    return {
        'Accept': "application/json",
        "x-auth-token": `${token}`
    }
}

export const apiHeaderMultipart = (token) => {
    return {
        'Accept': "application/json",
        'content-type': 'multipart/form-data',
        "x-auth-token": `${token}`
    }
}

export const requestNewAccessToken = async ( refreshToken )=> {
    let bodyParameters = {
        refreshToken,
    };

    return await axios.post(`${API}/api/auth/token`,
        bodyParameters
    )
}

export const handleSignOut = (dispatch) => {
    signout(() => {
        dispatch(signOut());
    })
}

export const uploadImage = async ( image, token)=> {
    const config = {
        headers: apiHeaderMultipart(token)
    };

    const formData = new FormData();
    formData.append('uploadFile', image);

    return await axios.post(`${API}/api/files/upload`,
        formData,
        config
    )
}

export const loadCustomData= async ( filter, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/common/queries`,
        filter,
        config
    )
}