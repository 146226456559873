import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagUsa, faFlag } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from "@mui/styles";
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
    root: props => ({
        background: props.backgroundColor,
        color: 'white',
        '& .MuiButton-root': {
            textTransform: "none !important",
            color: props.color,
            borderColor: "white !important",
            '&:hover': {
                backgroundColor: "transparent",
            },
        },
        '& .MuiTypography-root': {
            color: props.color,
        }

    }),

    toolbar: {
        minHeight: 15,
        maxHeight: 24,
        justifyContent: "end",
        padding: 0,
        WebkitFontSmoothing: "antialiased",
    },

    button: {
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "24px",
        fontWeight: "normal",
        margin: "0 7px",
        letterSpacing: 0,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white'
    },

    label: {
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "24px",
        margin: "0 15px",
        letterSpacing: 0,
    },

    separator: props => ({
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "24px",
        marginTop: "-3px",
        color: props.color,
    }),
}));

const DropdownLanguage = () => {
    const [t, i18n] = useTranslation('common')
    const environment = useSelector((state) => state.environment);
    const navTheme = environment?.user?.theme?.navTheme || {};
    const classes = useStyles(navTheme);

    const [selectedLanguage, setSelectedLanguage] = useState('en'); // Mặc định là tiếng Anh

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        // Xử lý thay đổi ngôn ngữ tại đây, ví dụ: gọi hàm để đổi ngôn ngữ
        i18n.changeLanguage(language);
    };

    const handleSelectLanguageClick = () => {
        switch(selectedLanguage){
            case 'en':
                setSelectedLanguage('vi');
                handleLanguageChange('vi');
                break;
            case 'vi':
                setSelectedLanguage('en');
                handleLanguageChange('en');
                break;
        }
    };

    return (
        <div className={classes.root}>
            <button className={classes.button}
                onClick={handleSelectLanguageClick}>
                {selectedLanguage === 'en' ? (
                    <Typography
                        className={classes.label}
                    >
                        <FontAwesomeIcon icon={faFlagUsa} /> English
                    </Typography>
                ) : (
                    <Typography
                        className={classes.label}
                    >
                        <FontAwesomeIcon icon={faFlag} /> Tiếng Việt
                    </Typography>
                )}
            </button>
        </div>
    );
};

export default DropdownLanguage;