import axios from "axios";
import { apiHeaderX } from "./dataServices";
import { API } from "./../../config";

export const addNewTopUp = async ( topUp, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/topup/add`,
        topUp,
        config
    )
}

export const updateTopUp = async ( topUp, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/topup/update`,
        topUp,
        config
    )
}

export const loadTopUpRecordsQuery = async ( filter, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/topup/query`,
        filter,
        config
    )
}

export const updateTopUpImageActions = async ( topUp, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/topup/updateImageActions`,
        topUp,
        config
    )
}