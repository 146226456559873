import axios from "axios";
import { apiHeaderX } from "../../dataServices";
import { API } from "./../../../../config";

export const addNewBlock = async ( block, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/car_parking/block/add`,
        block,
        config
    )
}

export const updateBlock = async ( block, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/car_parking/block/update`,
        block,
        config
    )
}

export const loadBlockRecordsQuery = async ( filter, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };
    let deviceFilter = filter.conditions.filter(cond => cond.target !== "@DeviceNumber");

    let bodyParameters = {
        filter: {conditions: deviceFilter},
    };

    return await axios.post(`${API}/api/car_parking/block/query`,
        bodyParameters,
        config
    )
}
