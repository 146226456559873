
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import mqtt from 'mqtt';
import * as uuid from "uuid";
import { API } from "../../../config";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "../../data/dataHelpers";
import SHMessage from "../../../custom-components/SHMessage";
import {
    CONST_CMD_REQUEST_INFO,
    CONST_STATE_CONNECTED,
    MQTT_HOST,
    CONST_CMD_REQUEST_INFO_RESPONSE,
    CONST_CMD_CHARGER_LOG,
    CONST_CMD_TURN_ON_RELAY_RESPONSE,
    FIELD_NAME_START,
    FIELD_NAME_END,
    CONST_CMD_TURN_OFF_RELAY_RESPONSE,
    FIELD_NAME_TIEN_SAC,
    FIELD_NAME_CONG_SUAT_TIEU_THU,
    FIELD_NAME_DONG_DIEN,
    FIELD_NAME_CUONG_DO,
    FIELD_NAME_CONG_SUAT,
    FIELD_NAME_GIA,
    FIELD_NAME_DEVICE_NAME,
    FIELD_NAME_ADDRESS,
    FIELD_NAME_PHONE,
    CONST_CMD_TURN_ON_RELAY,
    CONST_CMD_TURN_OFF_RELAY
} from "../../../constants";

import { base64Decode, getStoreData } from "../../core/utils/utils";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from "moment";
import { SCREEN_KH01, ScreenKH01 } from "./ScreenKH01";
import { SCREEN_KH02, ScreenKH02 } from "./ScreenKH02";
import { SCREEN_KH03, ScreenKH03 } from "./ScreenKH03";
import { SCREEN_KH04, ScreenKH04 } from "./ScreenKH04";
import { SCREEN_KH05, ScreenKH05 } from "./ScreenKH05";
import { userSignIn } from "../../user/userServices";
import { generateShortUuid } from "../../core/utils/utils";
import { CoreProvider } from "../../../custom-components/context/CoreContext"; 
import {SCREEN_FOGOT_PASSWORD, ForgotPassword} from "./ForgotPassword";

import { SCREEN_PAYMENT, PaymentForm } from "./PaymentForm";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root': {
            textDecoration: "none"
        }
    },
    dataGridToolbar: {
        minHeight: 20,
        paddingLeft: '0px',
        paddingBottom: '8px'
    },
    dialog: {
        '& .MuiButton-root': {
            textTransform: "none !important",
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
    },
    headingContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
    },
    headingLabel: {
        display: "block",
        minWidth: "200px",
    },
    headingAction: {
        display: "flex",
        alignItems: "center",
    },
    headingProjectSelection: {
        display: "inline-flex",
        width: "24em",
        maxWidth: "24em",
    },
    icon: props => ({
        color: props.color,
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
    formControl: {
        width: "100%",
        margin: "8px 0px !important",
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    formControlArray: {
        width: "100%",
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    alignRight: {
        textAlign: "right"
    },
    cardHeader: {
        border: "1px solid gray",
        backgroundColor: "lightgray",
        height: "40px",
        fontSize: "20px",
        padding: "10px",
        borderBottom: "none",
        display: "flex",
        alignItems: "center"
    },
    cardBody: {
        border: "1px solid gray",
        padding: "10px 20px",
    },
    cardFooter: {
        borderTop: "none",
        border: "1px solid gray",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    arrayItemRow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .ColorPicker-MuiButtonBase-root .ColorPicker-MuiButton-label div': {
            border: '1px solid black !important'
        }
    },
    customValueBox: {
        width: 175,
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    dateField: {
        marginLeft: "0px"
    },
    odd: {
        backgroundColor: "azure",
        '& .Mui-readOnly': {
            backgroundColor: "lightgray",
        },
    },
    even: {
        backgroundColor: "white",
        '& .Mui-readOnly': {
            backgroundColor: "lightgray",
        },
    }
}));
const goiTheoGiaTienArr = [
    {
        value: 10000,
        name: '10K (tối đa 1h)'
    },
    {
        value: 20000,
        name: '20K (tối đa 2h)'
    },
    {
        value: 30000,
        name: '30K (tối đa 3h)'
    },
    {
        value: 40000,
        name: '40K (tối đa 4h)'
    }];
const goiTheoGioArr = [
    {
        value: 5000,
        name: '30 phút (5K)'
    },
    {
        value: 10000,
        name: '60 phút (10K)'
    },
    {
        value: 15000,
        name: '90 phút (15K)'
    },
    {
        value: 20000,
        name: '120 phút (20K)'
    }];



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ItemLeft = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
}));

const CustomerModule = (props) => {
    const { environment, onClose } = props;
    const [t] = useTranslation('common');

    const { accessToken } = environment;
    const [values, setValues] = useState({});
    const {
        username,
        password,
        chkDongY,
        curDate,
        curTime,
        address,
        phone,
        truSac,
        congSac,
        dienAp,
        cuongDo,
        giaDien,
        tienThue,
        thoiGianThue,
        thoiGianBatDau,
        thoiGianKetThuc} = values;


    const [user, setUser] = useState({});
    const [showScreen, setShowScreen] = useState(SCREEN_KH01);
    const [showScreenBack, setShowScreenBack] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [chanelInfos, setChanelInfos] = useState([]);
    const [topic, setTopic] = useState(null);
    const [clientId, setClientId] = useState("");
    const [mqttClientStatus, setMQTTClientStatus] = useState('UNSUBSCRIBED');
    const [mqttClient, setMQTTClient] = useState(null);
    const [thoiGianDaSac, setThoiGianDaSac] = useState("");
    const [openConfirmStopCharge, setOpenConfirmStopCharge] = useState(false);
    const [thoiGianKetThucSac, setThoiGianKetThucSac] = useState("");
    const [tongThoiGianSac, setTongThoiGianSac] = useState("");;
    const [thanhTien, setThanhTien] = useState("");
    const [tienChuaThanhToan, setTienChuaThanhToan] = useState(0);
    const [listChuaThanhToan, setListChuaThanhToan] = useState([]);
    const [listDaThanhToan, setListDaThanhToan] = useState([]);

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />

    const handleMessageClosed = () => {
        setError(null);
    }

    const setValue = (name, value) => {
        setValues({
            ...values,
            [name]: value
        });
    }

    const handleChange = (event) => {
        setValue(event.target.name, event.target.value);
    }

    const handleCheckChange = (event) => {
        setValue(event.target.name, event.target.checked);
    }

    const subscribeToTopic = () => {
        if (mqttClientStatus == "SUBSCRIBED") {
            return;
        }
        console.log(`Subscribing to Topic: ${topic}`);

        mqttClient.subscribe(topic + "_publisher", { qos: 0 });
        setMQTTClientStatus("SUBSCRIBED");

        requestDeviceInfo();
    }

    const unsubscribeToTopic = () => {
        if (mqttClientStatus == "UNSUBSCRIBED") {
            return;
        }
        console.log(`Unsubscribing to Topic: ${topic}`);

        mqttClient.unsubscribe(topic + "_publisher", { qos: 0 });
        setMQTTClientStatus("UNSUBSCRIBED");
        mqttClient.end();
    }

    const requestDeviceInfo = () => {
        setLoading(true);
        var i = chanelInfos[0].stt;
        const newChanelInfo = [...chanelInfos];
        newChanelInfo[0].waiting = CONST_CMD_REQUEST_INFO_RESPONSE;
        setChanelInfos(newChanelInfo);
        var message = {
            kenh: i + 1,
            stt: i,
            cmd: CONST_CMD_REQUEST_INFO,
            clientId: clientId
        };

        fetch(API + "/publisher", {
            method: "POST",
            body: JSON.stringify({
                topic: topic,
                message: JSON.stringify(message),
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log(data);
            })
            .catch((error) => console.error("Error:", error));
    }

    useEffect(() => {
        const queryString = window.location.search;
        console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const base64Data = urlParams.get('id');

        let truSacData;
        try {
            truSacData = JSON.parse(base64Decode(base64Data));
        } catch (e) {
            truSacData = {};
        }

        let i = truSacData.stt;
        const topic = truSacData.station_id;
        setTopic(topic);
        chanelInfos.push({
            topic: truSacData.station_id,
            stt: truSacData.stt
        });

        // Kết nối tới broker MQTT
        const clientId = generateShortUuid(uuid.v4());
        setClientId(clientId);

        // Cleanup khi component unmount
        return () => {
            unsubscribeToTopic();// Đóng kết nối khi component bị unmount
        };
    }, [])

    useEffect(() => {
        if (topic == null) {
            return;
        }

        const options = {
            keepalive: 60,
            reschedulePings: true,
            clientId: clientId,
            protocolId: "MQTT",
            protocolVersion: 4,
            clean: true,
            reconnectPeriod: 1000,
            connectTimeout: 30 * 1000,
            resubscribe: true
        };
        const client = mqtt.connect(MQTT_HOST, options); // Thay đổi URL của broker tại đây

        setMQTTClient(client);
    }, [topic])

    useEffect(() => {
        if (mqttClient == null) {
            return;
        }
        // Xử lý khi kết nối thành công
        mqttClient.on('connect', () => {
            console.log('Connected to MQTT broker');

            setMQTTClientStatus(CONST_STATE_CONNECTED);
            subscribeToTopic();
        });

        // Xử lý khi nhận được tin nhắn từ topic đã subscribe
        mqttClient.on('message', (topic, message) => {
            console.log(
                "Received Message: " + message.toString() + ", length:" + message.length + "\nOn topic: " + topic
            );

            var recvInfo = JSON.parse(message);
            chanelDataReceive(topic, recvInfo);
        });

        // Xử lý khi mất kết nối
        mqttClient.on('offline', () => {
            console.log('Disconnected from MQTT broker');
        });
    }, [mqttClient])

    const handleClose = () => {
        onClose();
    }

    const isShowScreen = (screenId) => {
        if (showScreen == screenId) {
            return true;
        }
        return false;
    }

    const handleDangNhap = () => {
        if (!chkDongY) {
            setError(t('common:misc.acceptCondition'));
            return;
        }

        setLoading(true);
        userSignIn({ username, password, account: "", usertype: "customer" }).then((res) => {
            if (res.data.user.length === 0) {
                setError(t('common:signIn.signinError'));
            } else {
                //setUserData(res.data.user);
                //goToNewPortal(res.data);
                setUser(res.data.user[0]);
                setShowScreen(SCREEN_KH02);
            }
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleQuenMatKhau = () => {
        setShowScreen(SCREEN_FOGOT_PASSWORD);
    }

    const finishedCharge = (i) => {
        let updateChanelInfos = [...chanelInfos];
        updateChanelInfos[0].waiting = CONST_CMD_TURN_OFF_RELAY_RESPONSE;
        var message = {
            kenh: i + 1,
            stt: i,
            topic: topic,
            state: 0,
            cmd: CONST_CMD_TURN_OFF_RELAY,
            userId: user.user_id,
            clientId: clientId
        };

        setChanelInfos(updateChanelInfos);
        fetch(API + "/publisher", {
            method: "POST",
            body: JSON.stringify({
                topic: topic,
                message: JSON.stringify(message),
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log(data);
            })
            .catch((error) => console.error("Error:", error));
    }

    const startCharge = () => {
        let updateChanelInfos = [...chanelInfos];
        if (updateChanelInfos[0].state != 0) {
            const chanelId = updateChanelInfos[0].stt;
            var curTime = (new Date()).getTime();
            updateChanelInfos[0].chargedSeconds = Math.floor((curTime - updateChanelInfos[0].startTime) / 1000);
            var chargedSeconds = updateChanelInfos[0].chargedSeconds;
            if (chargedSeconds >= (updateChanelInfos[0].time * 60)) {
                updateChanelInfos[0].state = 0;
                chargedSeconds = updateChanelInfos[0].time * 60;

                finishedCharge(chanelId);
            }
            var hours = Math.floor(chargedSeconds / (60 * 60));
            var minutes = Math.floor(chargedSeconds % (60 * 60) / 60);
            var seconds = chargedSeconds % (60 * 60) % 60;

            setThoiGianDaSac(hours + ':' + minutes + ':' + seconds);
            setInterval(() => {
                startCharge(chanelId);
            }, 1000); // Cập nhật dữ liệu mỗi giây
        }
    }

    var showChargerInfo = function (updatedChanelInfos, recvInfo) {
        updatedChanelInfos[0]["name"] = recvInfo.name;
        updatedChanelInfos[0]["dien_the"] = recvInfo[FIELD_NAME_DONG_DIEN];
        updatedChanelInfos[0]["cuong_do"] = recvInfo[FIELD_NAME_CUONG_DO];
        updatedChanelInfos[0]["cong_suat"] = recvInfo[FIELD_NAME_CONG_SUAT];
        updatedChanelInfos[0]["gia_tren_1_kw"] = recvInfo[FIELD_NAME_GIA];
        updatedChanelInfos[0]["device_name"] = recvInfo[FIELD_NAME_DEVICE_NAME];
        updatedChanelInfos[0]["address"] = recvInfo[FIELD_NAME_ADDRESS];
        updatedChanelInfos[0]["phone"] = recvInfo[FIELD_NAME_PHONE];
        updatedChanelInfos[0]["congSuatTieuThu"] = recvInfo[FIELD_NAME_CONG_SUAT_TIEU_THU]?recvInfo[FIELD_NAME_CONG_SUAT_TIEU_THU]:0;
    }

    const tienThueTheoPhut = (phut) => {
        let selectedHour = phut / 60;
        let tienThue = selectedHour * 10000;
        return tienThue
    }

    var chanelDataReceive = function (topic, recvInfo) {
        var chanelId = recvInfo.stt;
        if (chanelId == null || chanelInfos[0] == null) {
            return;
        }
        const updatedChanelInfos = [...chanelInfos];
        let changed = false;
        let momentObj = null;
        switch (recvInfo.cmd) {
            case CONST_CMD_CHARGER_LOG:
                var inputString = recvInfo.data;
                // Tách chuỗi thành các phần riêng biệt dựa trên ký tự "]"
                var parts = inputString.split("][");
                // Mảng để lưu trữ các đối tượng JSON
                var jsonArray = [];
                // Duyệt qua các phần và tạo đối tượng JSON tương ứng
                parts.forEach(function (part) {
                    // Xóa dấu "[" hoặc "]" từ các phần
                    part = part.replace("[", "").replace("]", "");

                    // Tách các giá trị trong mảng con
                    var values = part.split(",");

                    // Tạo đối tượng JSON từ các giá trị và đặt chúng vào các thuộc tính tương ứng
                    var obj = {
                        userId: parseInt(values[0]),
                        stt: parseInt(values[1]),
                        cuong_do: parseFloat(values[2]),
                        congSuatTieuThu: parseFloat(values[3])
                    };

                    if (obj.stt == updatedChanelInfos[0].stt) {
                        updatedChanelInfos[0].cuong_do = obj.cuong_do;
                        updatedChanelInfos[0].congSuatTieuThu = obj.congSuatTieuThu;
                        changed = true;
                        // $('.lbl-dong-sac').empty().append(chanelInfos[0].cuong_do);
                        // $('.lbl-cong-suat-tieu-thu').empty().append(chanelInfos[0].congSuatTieuThu / 1000.0);
                        return;
                    }
                });
                if (changed) {
                    setChanelInfos(updatedChanelInfos);
                }
                break;
            case CONST_CMD_TURN_ON_RELAY_RESPONSE:
                if (recvInfo.clientId != clientId) {
                    //Not available
                    return;
                }
                if (updatedChanelInfos[0].waiting != CONST_CMD_TURN_ON_RELAY_RESPONSE) {
                    return;
                }

                updatedChanelInfos[0].startTime = (new Date()).getTime();
                updatedChanelInfos[0].startMilis = recvInfo[FIELD_NAME_START];
                updatedChanelInfos[0].endMilis = recvInfo[FIELD_NAME_END];
                setChanelInfos(updatedChanelInfos);
                setShowScreen(SCREEN_KH03);
                startCharge(chanelId);
                break;
            case CONST_CMD_TURN_OFF_RELAY_RESPONSE:
                updatedChanelInfos[0].state = 0;
                updatedChanelInfos[0]['state'] = 0;
                updatedChanelInfos[0]['congSuatTieuThu'] = recvInfo[FIELD_NAME_CONG_SUAT_TIEU_THU];
                updatedChanelInfos[0]['tienSac'] = recvInfo[FIELD_NAME_TIEN_SAC];

                var endTime = new Date(updatedChanelInfos[0].startTime + updatedChanelInfos[0].chargedSeconds * 1000);
                // $('.thoi-gian-ket-thuc-sac').empty().append(endTime.format("hh:MM:ss TT"));

                var chargedSeconds = updatedChanelInfos[0].chargedSeconds;
                var hours = Math.floor(chargedSeconds / (60 * 60));
                var minutes = Math.floor(chargedSeconds % (60 * 60) / 60);
                var seconds = chargedSeconds % (60 * 60) % 60;

                momentObj = moment(endTime);
                setThoiGianKetThucSac(momentObj.format("hh:mm A"));
                setTongThoiGianSac(hours + ':' + minutes + ':' + seconds);

                setThanhTien(recvInfo[FIELD_NAME_TIEN_SAC]);

                setChanelInfos(updatedChanelInfos);
                setShowScreen(SCREEN_KH04);
                break;
            case CONST_CMD_REQUEST_INFO_RESPONSE:
                if (recvInfo.clientId != clientId) {
                    //request from another device ignore it
                    return;
                }
                if (updatedChanelInfos[0].waiting != CONST_CMD_REQUEST_INFO_RESPONSE) {
                    return;
                }

                showChargerInfo(updatedChanelInfos, recvInfo);
                var khachHang = updatedChanelInfos[0].khachhang;
                if (khachHang == null || khachHang.trim() == '') {
                    khachHang = 'Unknow';
                }
                if (recvInfo.state != 0) {
                    if (getStoreData("user") != null) {
                        setUser(JSON.parse(getStoreData("user")));
                    }

                    var tienThue = tienThueTheoPhut(recvInfo.time);
                    var selectedHour = recvInfo.time / 60;
                    updatedChanelInfos[0].state = recvInfo.state;
                    updatedChanelInfos[0].time = recvInfo.time;
                    updatedChanelInfos[0].price = tienThue;
                    // $('.tien-thue').empty().append(tienThue);
                    // $('.thoi-gian-thue').empty().append(selectedHour);
                    // $('.tien-thue-session').removeClass('hide-item');

                    updatedChanelInfos[0].t = recvInfo.t * 60 * 60;
                    updatedChanelInfos[0].chargedSeconds = updatedChanelInfos[0].t;

                    var curDate = new Date((new Date()).getTime() - updatedChanelInfos[0].t * 1000);
                    updatedChanelInfos[0].startTime = curDate;
                    // $(".thoi-gian-bat-dau").empty().append(curDate.format("hh:MM:ss TT"));
                    var endDate = new Date(curDate.getTime() + updatedChanelInfos[0].time * 60 * 1000);
                    // $(".thoi-gian-ket-thuc").empty().append(endDate.format("hh:MM:ss TT"));

                    setChanelInfos(updatedChanelInfos);
                    setShowScreen(SCREEN_KH03);
                    //showScreen("#screen-KH03");
                    startCharge(chanelId);
                } else {
                    setChanelInfos(updatedChanelInfos);
                }
                const date = new Date();
                momentObj = moment(date);
                setValues({
                    ...values,
                    curDate: momentObj.format("DD/MM/YYYY"),
                    curTime: momentObj.format("hh:mm A"),
                    truSac: updatedChanelInfos[0].device_name,
                    congSac: updatedChanelInfos[0].name,
                    dienAp: updatedChanelInfos[0].dien_the,
                    cuongDo: updatedChanelInfos[0].cuong_do,
                    giaDien: updatedChanelInfos[0].gia_tren_1_kw,
                    address: updatedChanelInfos[0].address,
                    phone: updatedChanelInfos[0].phone
                });
                setLoading(false);
                break;
        }
    }

    const handleGiaTienChange = (event) => {
        let tienThue = event.target.value;
        let thoiGianThue = tienThue / 10000;
        setValues({ ...values, tienThue, thoiGianThue });
        let updatedChanelInfos = [...chanelInfos];
        updatedChanelInfos[0].time = thoiGianThue * 60;
        setChanelInfos(updatedChanelInfos);
    }

    const handleXemTaiKhoan = () => {
        var message = {
            userId: user.user_id,
            clientId: clientId
        };
        fetch(API + "/api/smarthome/getChargerHistory", {
            method: "POST",
            body: JSON.stringify({
                topic: topic,
                message: message,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (responseData) {
                let dsChuaTT = [];
                let dsDaTT = [];
                let tongTienChuaThanhToan = 0;
                if (responseData.length > 0) {
                    for (var i = 0; i < responseData.length; i++) {
                        let data = responseData[i];
                        let startTime = new Date(data.start);
                        let momentObj = moment(startTime);
                        let stringData = "";
                        stringData += "<" + momentObj.format("DD/MM/YYYY") + ">";
                        stringData += "<" + momentObj.format("hh:mm A") + ">";
                        stringData += "<" + data.tram_sac + ">";
                        stringData += "<" + data.cong_sac + ">";
                        stringData += "<" + data.time + " phút>";
                        stringData += "<" + data.charged_wattage + " W>";
                        stringData += "<" + data.price + " đồng>";
                        if (data.paid == 1) {
                            dsDaTT.push(stringData);
                        } else {
                            dsChuaTT.push(stringData);
                            tongTienChuaThanhToan += data.price;
                        }
                    }
                }
                setTienChuaThanhToan(tongTienChuaThanhToan);
                setListChuaThanhToan(dsChuaTT);
                setListDaThanhToan(dsDaTT);
                setShowScreenBack(showScreen);
                setShowScreen(SCREEN_KH05);
            })
            .catch((error) => console.error("Error:", error));
    }

    const handleThoat = () => {
        switch (showScreen) {
            case SCREEN_KH05:
                setShowScreen(showScreenBack);
                return;
        }
        setShowScreen(SCREEN_KH01);
    }

    const clearChargeInfo = () => {
        chanelInfos[0].tienSac = 0;
        chanelInfos[0].chargedSeconds = 0;
    }

    const handleDungSac = () => {
        setOpenConfirmStopCharge(true);
    }

    const handleXacNhanSac = () => {
        if (thoiGianThue <= 0) {
            setError(t('common:misc.timeRequired'));
            return;
        }
        let updateChanelInfos = [...chanelInfos];
        let i = updateChanelInfos[0].stt;
        let curDate = new Date();
        let endDate = curDate;
        curDate = curDate.setMinutes(curDate.getMinutes() + 1);
        let momentObj = moment(curDate);
        const thoiGianBatDau = momentObj.format("hh:mm A");
        updateChanelInfos[0].start = momentObj.format("HH:mm");
        endDate = endDate.setMinutes(endDate.getMinutes() + thoiGianThue * 60 + 1);
        momentObj = moment(endDate);
        const thoiGianKetThuc = momentObj.format("hh:mm A");
        updateChanelInfos[0].end = momentObj.format("HH:mm");
        setValues({
            ...values,
            thoiGianBatDau,
            thoiGianKetThuc
        });

        const cmd = CONST_CMD_TURN_ON_RELAY;
        updateChanelInfos[0].waiting = CONST_CMD_TURN_ON_RELAY_RESPONSE;

        var message = {
            kenh: i + 1,
            stt: i,
            time: updateChanelInfos[0].time,
            price: updateChanelInfos[0].price,
            cmd: cmd,
            userId: user.user_id,
            clientId: clientId,
            start: updateChanelInfos[0].start,
            end: updateChanelInfos[0].end
        };

        updateChanelInfos[0].state = 1;
        updateChanelInfos[0].startTime = curDate;
        updateChanelInfos[0].tienSac = 0;
        updateChanelInfos[0].chargedSeconds = 0;
        setChanelInfos(updateChanelInfos);
        fetch(API + "/publisher", {
            method: "POST",
            body: JSON.stringify({
                topic: topic,
                message: JSON.stringify(message),
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log(data);
            })
    }

    const handleConfirmOk = () => {
        if (openConfirmStopCharge) {
            setOpenConfirmStopCharge(false);
            finishedCharge(chanelInfos[0].stt);
        }
    }

    const handleConfirmClose = () => {
        if (openConfirmStopCharge) {
            setOpenConfirmStopCharge(false);
        }
    }
    const showConfirmDialog = (title, message, openConfirm) => {
        return <Dialog
            open={openConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {capitalizeFirstLetter(title)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="body2" color="text.secondary">
                        {message}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmOk}>Ok</Button>
                <Button onClick={handleConfirmClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    }

    return (
        <>
            <CoreProvider values={values}>
                {showErrorMessage()}
                {showConfirmDialog(t('common:misc.confirm'), t('common:misc.confirmChargeEnd'), openConfirmStopCharge)}
                {ScreenKH01({
                    show: isShowScreen(SCREEN_KH01),
                    curDate,
                    curTime,
                    address,
                    phone,
                    truSac,
                    congSac,
                    dienAp,
                    cuongDo,
                    giaDien,
                    chkDongY,
                    handleChange,
                    username,
                    password,
                    handleDangNhap,
                    handleQuenMatKhau,
                    handleCheckChange,
                    loading
                })}
                {ScreenKH02({
                    show: isShowScreen(SCREEN_KH02),
                    curDate,
                    curTime,
                    tenKhachHang: user?.name,
                    maKhachHang: user?.user_id,
                    truSac,
                    congSac,
                    dienAp,
                    cuongDo,
                    handleGiaTienChange,
                    handleXemTaiKhoan,
                    handleThoat,
                    handleXacNhanSac,
                    tienThue,
                    thoiGianThue
                })}
                {ScreenKH03({
                    show: isShowScreen(SCREEN_KH03),
                    curDate,
                    curTime,
                    tenKhachHang: user?.name,
                    maKhachHang: user?.user_id,
                    truSac,
                    congSac,
                    tienThue,
                    thoiGianThue,
                    thoiGianBatDau,
                    thoiGianKetThuc,
                    thoiGianDaSac,
                    dienApSac: chanelInfos[0]?.dien_the,
                    dongSac: chanelInfos[0]?.cuong_do,
                    congSuatTieuThu: chanelInfos[0]?.congSuatTieuThu / 1000,
                    handleXemTaiKhoan,
                    handleDungSac
                })}
                {ScreenKH04({
                    show: isShowScreen(SCREEN_KH04),
                    curDate,
                    curTime,
                    tenKhachHang: user?.name,
                    maKhachHang: user?.user_id,
                    truSac,
                    congSac,
                    tienThue,
                    thoiGianThue,
                    thoiGianKetThucSac,
                    tongThoiGianSac,
                    congSuatTieuThu: chanelInfos[0]?.congSuatTieuThu / 1000,
                    thanhTien,
                    handleXemTaiKhoan,
                    handleThoat
                })}
                {ScreenKH05({
                    show: isShowScreen(SCREEN_KH05),
                    curDate,
                    curTime,
                    tenKhachHang: user?.name,
                    maKhachHang: user?.user_id,
                    address,
                    phone,
                    tienChuaThanhToan,
                    listChuaThanhToan,
                    listDaThanhToan,
                    handleThoat
                })}
                {isShowScreen(SCREEN_FOGOT_PASSWORD) && <ForgotPassword handleThoat={handleThoat}></ForgotPassword>}
                {isShowScreen(SCREEN_PAYMENT) && <PaymentForm></PaymentForm>}
            </CoreProvider>
        </>
    );
}

export default CustomerModule;