import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    data: {
    },
    filter: {
        startDate: null,
        endDate: null
    },
    refreshData: false,
};
const CPDashboardSlice = createSlice({
    name: 'CPDashboard',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        setFilter:(state, action) => {
            return {...state, filter: action.payload};
        },
        setFilterStartDate:(state, action) => {
            return {...state, filter:{...state.filter, startDate: action.payload}};
        },
        setFilterEndDate:(state, action) => {
            return {...state, filter:{...state.filter, endDate: action.payload}};
        },
        setDataCPDashboard:(state, action) => {
            return {...state, refreshData: false, data: action.payload};
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, data: {}};
        }
    }
});

export const {
    reset,
    setRefreshData,
    setFilter,
    setFilterStartDate,
    setFilterEndDate,
    setDataCPDashboard} = CPDashboardSlice.actions;
export default CPDashboardSlice.reducer;