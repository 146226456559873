export const base64Encode = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
};

export const base64Decode = (str) => {
    return decodeURIComponent(escape(atob(str)));
};

export const storeData = (name, value) => {
    sessionStorage.setItem(name, value);
}

export const getStoreData = (name) => {
    return sessionStorage.getItem(name);
}

export const generateShortUuid = (characters) => {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};