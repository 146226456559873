import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { getGridDateOperators } from "@mui/x-data-grid-pro";
import _, { isEmpty } from "lodash";
import moment from "moment";
import enUS from "date-fns/locale/en-US";
import {
  compareDates,
  formatDate,
} from "../../custom-components/helperFunctions/DMDateUtil";

export const computeColType = (type) => {
  switch (type) {
    case "DATETIME":
      return "dateTime";
    case "DATE":
      return "date";
    case "BOOLEAN":
      return "boolean";
    case "FLOAT":
    case "INTEGER":
      return "number";
    default:
      return "string";
  }
};

export const currencyFormatter = (columnFormat) => {
  return new Intl.NumberFormat(navigator.language, {
    style: columnFormat.style,
    currency: columnFormat.currency,
    maximumFractionDigits: columnFormat.maximumFractionDigits,
    minimumFractionDigits: columnFormat.minimumFractionDigits,
  });
};

export const stringToHTML = (str) => {
  let parser = new DOMParser();
  let doc = parser.parseFromString(str, "text/html");
  return doc.body.innerText;
};

export const DateInputValue = (props) => {
  const [t] = useTranslation("common");
  const { item, applyValue} = props;
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    const importLocaleFile = async () => {
      const localeToSet = await import(
        `date-fns/locale/${t("common:languages.dateFnsLocale")}/index.js`
      );
      setLocale(localeToSet.default);
    };

    if (locale.code !== t("common:languages.dateFnsLocale")) {
      importLocaleFile();
    }
  }, [locale.code, t]);

  const handleFilterChange = (newValue) => {
    let momentObj = moment(newValue);
    if (momentObj.isValid()) {
      applyValue({ ...item, value: momentObj.format("YYYY-MM-DDTHH:mm") });
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
        <DatePicker
          label={t("common:filters.value")}
          value={item.value}
          onChange={handleFilterChange}
          renderInput={(params) => (
            <TextField {...params} variant="standard" focused />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export const DateTimeInputValue = (props) => {
  const [t] = useTranslation("common");
  const { item, applyValue } = props;
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    const importLocaleFile = async () => {
      const localeToSet = await import(
        `date-fns/locale/${t("common:languages.dateFnsLocale")}/index.js`
      );
      setLocale(localeToSet.default);
    };

    if (locale.code !== t("common:languages.dateFnsLocale")) {
      importLocaleFile();
    }
  }, [locale.code, t]);

  const handleFilterChange = (newValue) => {
    let momentObj = moment(newValue);
    if (momentObj.isValid()) {
      applyValue({ ...item, value: momentObj.format("YYYY-MM-DDTHH:mm") });
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
        <DateTimePicker
          label={t("common:filters.value")}
          value={item.value}
          onChange={handleFilterChange}
          renderInput={(params) => (
            <TextField {...params} variant="standard" focused />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export const parsedDataGridColumns = (environment, record, columns) => {
  const fullDateFormat = "MM/DD/YYYY h:mm A";
  let offset = getTimeZoneOffset(environment, record);
  let columnsResult = (columns || []).map((column) => {
    const format = column?.format?.date ?? fullDateFormat;
    const isNeedToFormat = !isEmpty(column?.format);

    if (column.type === "DATE") {
      return {
        field: column.name,
        headerName: column.title,
        title: column.title,
        type: computeColType(column.type),
        orgType: column.type,
        headerAlign: "left",
        align: "left",
        hide: column.hidden ? column.hidden : false,
        format: column.format,
        width: column.width || 170,
        filterOperators: getGridDateOperators().map((operator) => ({
          ...operator,
          InputComponent: operator.InputComponent ? DateInputValue : undefined,
          InputComponentProps: { type: "date" },
        })),
        sortComparator: compareDates,
        valueFormatter: ({ value }) =>
          _.isEmpty(value)
            ? value
            : moment(value?.split("T")?.[0]).format(format),
      };
    } else if (["TIME", "DATETIME"].includes(column.type)) {
      return {
        field: column.name,
        headerName: column.title,
        title: column.title,
        type: computeColType(column.type),
        orgType: column.type,
        headerAlign: "left",
        align: "left",
        hide: column.hidden ? column.hidden : false,
        format: column.format,
        width: column.width || 170,
        filterOperators: getGridDateOperators(true).map((operator) => ({
          ...operator,
          InputComponent: operator.InputComponent
            ? DateTimeInputValue
            : undefined,
          InputComponentProps: { type: "dateTime" },
        })),
        sortComparator: compareDates,
        valueFormatter: ({ value }) =>
          _.isEmpty(value) ? value : formatDate(value, offset, format),
      };
    } else if (isNeedToFormat) {
      return {
        field: column.name,
        headerName: column.title,
        title: column.title,
        type: computeColType(column.type),
        orgType: column.type,
        headerAlign: "left",
        align: "left",
        hide: column.hidden ? column.hidden : false,
        format: column.format,
        width: column.width || 170,
        valueFormatter: ({ value }) => {
          const isNumber = !isNaN(value);

          // If the value is a number, format it as a currency
          if (isNumber) {
            const formattedValue = new Intl.NumberFormat(
              column?.format?.currency,
              column.format
            ).format(value);

            // If the value is an integer, remove the decimal places
            const trimmedAmount = Number.isInteger(value)
              ? formattedValue.replace(/\.?0+$/, "")
              : formattedValue;

            return !value ? "" : trimmedAmount;
          }

          return !value ? "" : `${value}`;
        },
      };
  } else if(column.type === "image"){
    return {
      field: column.name,
      headerName: column.title,
      title: column.title,
      type: computeColType(column.type),
      orgType: column.type,
      headerAlign: "left",
      align: "left",
      hide: column.hidden ? column.hidden : false,
      format: column.format,
      width: column.width || 170,
      renderCell: (params) => <img src={params.value} alt="" width={column.width?column.width:'50px'}/>
    };
  } else {
      return {
        field: column.name,
        headerName: column.title,
        title: column.title,
        type: computeColType(column.type),
        orgType: column.type,
        headerAlign: "left",
        align: "left",
        hide: column.hidden ? column.hidden : false,
        format: column.format,
        width: column.width || 170,
      };
    }
  });

  return columnsResult;
};

export const parsedDataGridColumnsHide = (environment, record, columns) => {
  let columnsResult = {};
  (columns || []).forEach((column) => {
    if(column.hidden){
      columnsResult[column.name] = false;
    }
  });

  return columnsResult;
};

export const revertDataGridColumns = (columns) => {
  let columnsResult = columns.map((column) => {
    return {
      name: column?.field,
      title: column?.headerName,
      type: column?.orgType,
      width: column?.width,
      format: column?.format,
    };
  });

  return columnsResult;
};

export const parsedDataGridRecords = (
  records,
  columns,
  environment,
  gridRowIDs
) => {
  let rowsResult = (records || []).map((record, index) => {
    return {
      id: index + 1,
      "@displayId": index + 1,
      ...mapColRecords(columns, record),
    };
  });
  return rowsResult;
};

export const computeColumns = (view) => {
  let array = [{ field: "id", headerName: "ID", width: "75", hide: true }];
  view.columns.forEach((column) => {
    let result = {
      field: column.name,
      headerName: column.title,
      type: computeColType(column.type),
      headerAlign: "left", //align left column header
      align: "left", //align left cell row
      format: column.format,
      flex: 1,
    };
    if (!_.isEmpty(column.format)) {
      let formatStyle = column.format.style;
      let formatUnderlying = column.format.underlying;
      if (formatStyle) {
        if (column.format.style === "currency") {
          result = {
            ...result,
            valueFormatter: ({ value }) =>
              currencyFormatter(column.format).format(Number(value)),
          };
        } else if (column.format.style === "percent") {
          result = {
            ...result,
            valueFormatter: (params) => {
              return `${params.value} %`;
            },
          };
        }
      } else if (formatUnderlying) {
        result = {
          ...result,
          valueFormatter: (params) => {
            if (params.value) {
              return stringToHTML(params.value)
                .replace(/\s/gi, "; ")
                .replace(/_/g, " ");
            }
            return params.value;
          },
        };
      }
    } else if (["INTEGER", "FLOAT"].includes(column.type)) {
      result = {
        ...result,
        valueFormatter: (params) => {
          if (params.value) {
            let val = params.value.toString();
            if (val.length > 3) {
              val = val.replace(/,/g, "");
            }
            val = parseInt(val);
            return val;
          }
          return;
        },
      };
    }
    array.push(result);
  });
  return array;
};

export const computeRows = (view, columns, records, environment) => {
  if (!columns.length) return;
  let rows = [];
  if (records.length) {
    records.forEach((record, recordIndex) => {
      let values = record.values;
      let result = mapColRecords(view.columns, values);
      result.id = recordIndex + 1;
      result.key = computeRecordKey(record);
      result.type = record.type;
      rows.push(result);
    });
  }
  return rows;
};

const computeRecordKey = (record) => {
  if (record.type === "SUBMISSION") {
    return record.submissionKey;
  } else if (record.type === "DISPATCH") {
    return record.dispatchKey;
  } else {
    return "";
  }
};

const mapColRecords = (columns, records) => {
  let map = new Map();

  for (let i = 0; i < columns.length; i++) {
    //if (columns[i].hidden) continue;
    map.set(columns[i].name, records[columns[i].id]);
    map.set(columns[i].id, records[columns[i].id]);
  }
  return Object.fromEntries(map.entries());
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSelectedProject = (environment, formSelected, tab) => {
  let result = "";
  if (formSelected?.projectName?.toLowerCase() === "lookups") {
    if (_.isEmpty(environment.lookups)) return;
    result = environment.lookups;
    return result;
  }
  switch (tab) {
    case "lookups":
      if (_.isEmpty(environment.lookups)) break;
      result = environment.lookups;
      break;
    default:
      if (!environment.projects.length) return formSelected;
      result =
        environment.projects.find(
          (project) => project.key === formSelected.projectKey
        ) ?? formSelected;
      break;
  }
  return result;
};

export const getSelectedViewOwner = (environment, viewSelected, tab) => {
  return environment.owners.find(
    (owner) => owner.key === viewSelected.ownerKey
  );
};

export const getLinkTarget = (links, linkObj) => {
  let target = links.find(
    (el) => el.target === linkObj?.target && el.operation === linkObj?.operation
  );
  if (!target) return null;

  return target;
};

export const showColumnTitleByName = (target, columns, t) => {
  if (!target || !columns.length) return "";
  if (target === "@StartTime") return t("common:filters.startDate");
  if (target === "@UserStatus") return t("common:filters.status");
  let foundColumn = columns.find((col) => col.name === target);
  return foundColumn ? foundColumn.title : t("common:filters.noTitle");
};

export const computeDateOperatorValue = (condition) => {
  const preset = condition.preset ? condition.preset : null;
  const type = condition.type ? condition.type : null;

  return preset ? preset : type;
};

export const computeNonDateOperatorValue = (condition) => {
  const preset = condition.preset ? condition.preset : null;
  const type = condition.type ? condition.type : null;

  if (condition.target === "@UserStatus") {
    if (preset !== null && preset !== undefined) {
      return preset;
    }
  }
  return type;
};

export const computeOwnerNameByType = (ownerType, t) => {
  if (ownerType === "USER") {
    return t("formsData.personalViews");
  } else if (ownerType === "CUSTOMER") {
    return t("formsData.sharedViews");
  }
};

export const getTimeZoneOffset = (environment, record) => {
  return 0;
};
