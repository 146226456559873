import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@UserStatus', type: 'EQ'}
        ]
    }
}

export const DEVICE_TYPE = {
    CHARGER: "Trạm sạc điện"
}
const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [{name: 'Ma TB', id: "device_id", hidden:true},
        {name: 'Device id', translateId: 'common:device.deviceId', id: "station_id"},
        {name: 'Device name', translateId: 'common:device.deviceName', id: "name"},
        {name: 'LAN IP', translateId: 'common:device.lanIP', id: "lan_ip"},
        {name: 'FW Version', translateId: 'common:device.fwVersion', id: "fw_version"},
        {name: 'last updated', translateId: 'common:device.lastUpdate', id: "fw_update_date"},
        {name: 'Status', translateId: 'common:misc.status', id: "status"},
        {name: 'Ngày lắp đặt', translateId: 'common:device.ngayLapDat', id: "ngayLapDat"},
        {name: 'Ngày hết bảo hành', translateId: 'common:device.ngayHetBaoHanh', id: "ngayHetHanBaoHanh"},
        {name: 'Mã HKD', translateId: 'common:user.maHKD', id: "account_id"},
        {name: 'Mã trạm sạc', translateId: 'common:tramsac.maTramSac', id: "ma_tram_sac"},
        {name: 'Nhiệt độ, độ ẩm', translateId: 'common:device.nhietDoDoAm', id: "nhietDoDoAm"}],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddDevice: false,
    showEditDevice: false,
    showControlDevice: false,
    showFirmwareUpdate: false,
    showDeviceBarcodes: false,
    recordsUpdated: 0,
    rowPerPage: 10
};
const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addDevice:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, recordsUpdated: state.recordsUpdated+1, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, recordsUpdated: state.recordsUpdated+1, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddDevice:(state, action) => {
            if(action.payload === false){
                return {...state, showAddDevice: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddDevice: action.payload};
            }
        },
        setShowEditDevice:(state, action) => {
            if(action.payload === false){
                return {...state, showEditDevice: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditDevice: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var device = state.dataGrid.records.filter(item => item.device_id === action.payload.device_id);
            if(device.length>0){
                return {...state, rowDataSelected: device[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        },
        setShowControlDevice:(state, action) => {
            if(action.payload === false){
                return {...state, showControlDevice: action.payload, rowDataSelected: null};
            }else{
                return {...state, showControlDevice: action.payload};
            }
        },
        setShowFirmwareUpdate:(state, action) => {
            if(action.payload === false){
                return {...state, showFirmwareUpdate: action.payload, rowDataSelected: null};
            }else{
                return {...state, showFirmwareUpdate: action.payload};
            }
        },
        setShowDeviceBarcodes:(state, action) => {
            if(action.payload === false){
                return {...state, showDeviceBarcodes: action.payload, rowDataSelected: null};
            }else{
                return {...state, showDeviceBarcodes: action.payload};
            }
        },
        setDeviceInfo:(state, action) => {
            const recvData = action.payload;
            var device = state.dataGrid.records.find(item => item.station_id === recvData.station_id);
            if(device != null){
                device.lan_ip = recvData.localIp;
                device.fw_version = recvData.FWVersion;
                device.fw_update_date = recvData.updateDate;
                device.nhietDoDoAm = recvData.ndda;
                device.status = "Connected";
            }
        }
    }
});

export const {
    reset,
    addDevice,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddDevice,
    setShowEditDevice,
    setRowDataSelected,
    setShowControlDevice,
    setShowFirmwareUpdate,
    setShowDeviceBarcodes,
    setDeviceInfo } = devicesSlice.actions;
export default devicesSlice.reducer;