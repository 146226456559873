
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IconThemeContext } from "../../../../custom-components/context/IconThemesContext";
import SHMessage from "../../../../custom-components/SHMessage";

import LoadingSpinner from "../../../../custom-components/LoadingSpinner";
import SkeletonLoaderDataGrid from "../../../../custom-components/skeletons/SkeletonLoaderDataGrid";
import DMDataGrid from "../../../data/datagrid/DMDataGrid";
import { setDataGrid, setRowDataSelected } from "../../../../slices/car_parking/BlockSlice";
import { useTranslation } from "react-i18next";
import { loadBlockRecordsQuery } from "../../../data/car_parking/block/blockServices";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    dataGridToolbar: {
        minHeight: 20,
        paddingLeft: '0px',
        paddingBottom: '8px'
    },
    dialog: {
        '& .MuiButton-root': {
            textTransform: "none !important",
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
    },
    headingContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
    },
    headingLabel: {
        display: "block",
        minWidth: "200px",
    },
    headingAction: {
        display: "flex",
        alignItems: "center",
    },
    headingBlockSelection: {
        display: "inline-flex",
        width: "24em",
        maxWidth: "24em",
    },
    icon: props => ({
        color: props.color,
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
}));

const BlockData = ({environment, module}) => {
    const {iconTheme} = useContext(IconThemeContext);
    const classes = useStyles(iconTheme);

    const apiToken = environment.accessToken;
    const [t, i18n] = useTranslation('common');
    const {
        action,
        dataGrid: { columns, query, queryView, records },
        blockRefresh,
        blockSelected,
        viewData,
        rowsPerPage,
        clientFilters,
        refreshData
    } = module;
    const dispatch = useDispatch();

    const [translatedColumns, setTranslatedColumns] = useState(columns);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);

    const [recordLoading, setRecordLoading] = useState(false);

    useEffect(() => {
        console.log('useEffect has been called!');
        setRecordLoading(true);
    }, []);

    useEffect(() => {
        if(columns != null){
            setTranslatedColumns(columns);
        }
    }, [columns]);

    useEffect(() => {
        const loadRecords = () => {
            setLoading(true);
            loadBlockRecordsQuery(query.filter, apiToken).then(response => {
                dispatch(setDataGrid({
                        customColumns: response.data.customColumns,
                        records: response.data.blocks,
                    }));
            }).catch(err => {
                setError(err.response ? 'Code ' + err.response.data.code + ": " + err.response.data.message : err.message);
            }).finally(() => {
                setLoading(false);
            });
        }
        if(refreshData){
            console.log('useEffect has been called! refreshData');
            loadRecords();
        }
    }, [refreshData, apiToken, dispatch, query.filter]);


    useEffect(() => {
        const loadRecords = () => {
            setLoading(true);
            loadBlockRecordsQuery(query.filter, apiToken).then(response => {
                dispatch(setDataGrid({
                        customColumns: response.data.customColumns,
                        records: response.data.blocks,
                    }));
            }).catch(err => {
                setError(err.response ? 'Code ' + err.response.data.code + ": " + err.response.data.message : err.message);
            }).finally(() => {
                setLoading(false);
            });
        }
        if (recordLoading) {
            loadRecords();
            setRecordLoading(false);
        }
    }, [recordLoading, apiToken, dispatch, query.filter])

    useEffect(() => {
        const columnsTmp = columns.map(item => {
            if(item.translateId){
                return { ...item, name: t(item.translateId)};
            }else{
                return { ...item };
            }
        });
        setTranslatedColumns(columnsTmp);
    }, [i18n.language]);

    const showLoading = () => {
        if(loading) return (
            <>
                <LoadingSpinner/>
                {_.isEmpty(viewData) && <SkeletonLoaderDataGrid/>}
            </>
        )
    }

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed}/>

    const showInfoMessage = () =>
        info && <SHMessage message={info} severity={'success'} onMessageClosed={handleMessageClosed}/>

    const handleMessageClosed = () => {
        setError(null);
        setInfo(null);
    }


    const handleLoadAllRecords = () => {
    }

    const handleRowSelected = (newRowSelectionModel) => {
        dispatch(setRowDataSelected(newRowSelectionModel));
    }

    const showActivityAction = () =>
        <DMDataGrid action={action}
                                                  isLoading={loading}
                                                  isRefresh={blockRefresh}
                                                  title={""}
                                                  environment={environment}
                                                  query={query}
                                                  viewData={viewData}
                                                  columns={translatedColumns}
                                                  records={records}
                                                  queryView={queryView}
                                                  clientFilters={clientFilters}
                                                  recordsLoading={false}
                                                  allRecordsLoading = {false}
                                                  blockSelected={blockSelected}
                                                  tab={"blocks"}
                                                  rowsPerPage={rowsPerPage}
                                                  setError={setError}
                                                  onHandledLoadAllRecords={handleLoadAllRecords}
                                                  onHandleRowSelected={handleRowSelected}/>



    return (
        <div className={classes.root}>
            {showLoading()}
            {showErrorMessage()}
            {showInfoMessage()}
            {showActivityAction()}
        </div>
    )
}

export default BlockData;