import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@HKDStatus', type: 'EQ'}
        ]
    }
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Mã HKD', translateId: 'common:hkd.maHKD', id: "account_id"},
            {name: 'Tên HKD', translateId: 'common:hkd.tenHKD', id: "account_name"},
            {name: 'Địa chỉ', translateId: 'common:misc.address', id: "address"},
            {name: 'Điện thoại', translateId: 'common:misc.phone', id: "phone"},
            {name: 'Email', translateId: 'common:user.email', id: "email"},
            {name: 'SL Device', translateId: 'common:misc.slDevice', id: "sl_device", type: "INTEGER"},
            {name: 'SL account khách hàng', translateId: 'common:misc.slAccountKH', id: "sl_account_kh", type: "INTEGER"},
            {name: 'Doanh thu trong tháng', translateId: 'common:misc.dtTrongThang', id: "doanh_thu_thang", type: "INTEGER"},
            {name: 'Doanh thu trong năm', translateId: 'common:misc.dtTrongNam', id: "doanh_thu_nam", type: "INTEGER", },
            {name: 'Doanh thu năm trước', translateId: 'Last year\'s revenue', id: "doanh_thu_nam_truoc", type: "INTEGER", },
            {name: 'Doanh thu Lũy kế', translateId: 'common:misc.dtLuyKe', id: "doanh_thu_luy_ke", type: "INTEGER", },
            {name: 'Loại', translateId: 'common:user.type', id: "type"}],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddHKD: false,
    showEditHKD: false,
    rowPerPage: 10
};
const hkdsSlice = createSlice({
    name: 'HKDs',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addHKD:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddHKD:(state, action) => {
            if(action.payload === false){
                return {...state, showAddHKD: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddHKD: action.payload};
            }
        },
        setShowEditHKD:(state, action) => {
            if(action.payload === false){
                return {...state, showEditHKD: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditHKD: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var hkd = state.dataGrid.records.filter(item => item.account_id === action.payload.account_id);
            if(hkd.length>0){
                return {...state, rowDataSelected: hkd[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        }
    }
});

export const {
    reset,
    addHKD,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddHKD,
    setShowEditHKD,
    setRowDataSelected } = hkdsSlice.actions;
export default hkdsSlice.reducer;