import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: undefined,
    isAuthenticated: false,
    isLoggedIn: false,
    isLoading: false,
    isLoaded: false,
    showLeftPanel: true,
    navToPage: null,
    theme: {},
};
const environmentSlice = createSlice({
    name: 'environment',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        setUser:(state, action) => {
            const user = action.payload.user;
            if(user.theme && (typeof user.theme === 'string' || user.theme instanceof String) ){
                user.theme = JSON.parse(user.theme);
            }
            return {...state,
                user: user,
                isAuthenticated: true,
                isLoggedIn: true,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                userUpdateDate: new Date()
            };
        },
        initializedEnvironment:(state, action) => {
            return {
                ...state,
                isInitialized: true,
            };
        },
        signOut:(state, action) => {
            return {
                user: undefined,
                isAuthenticated: false,
                isLoggedIn: false,
                isLoading: false,
                isLoaded: false,
                showLeftPanel: true
            }
        },
        loadCoreData:(state, action) => {
            return {
                ...state
            }
        },
        setFullScreenMode:(state, action) => {
            return {
                ...state,
                fullscreenMode: action.payload,
                showLeftPanel: !action.payload
            }
        },
        setAccessToken:(state, action) => {
            return {...state,
                accessToken: action.payload
            };
        },
        setNavigateToPage:(state, action) => {
            return {...state,
                navToPage: action.payload
            };
        },
        updateSystemInfo:(state, action) => {
            const account = action.payload;
            let theme = {};
            if(account.theme && (typeof account.theme === 'string' || account.theme instanceof String) ){
                theme = JSON.parse(account.theme);
            }else{
                theme = account.theme;
            }
            return {...state,
                user: {...state.user, 
                    logo: account.logo, 
                    theme: theme, 
                    title:account.title, 
                    title2:account.title2, 
                    account_name: account.account_name,
                    account_address: account.account_address,
                    account_phone: account.account_phone,
                    account_email: account.account_email,
                    description: account.description
                },
                userUpdateDate: new Date()
            };
        }
    }
});

export const {
    reset,
    setUser,
    initializedEnvironment,
    signOut,
    loadCoreData,
    setFullScreenMode,
    setAccessToken,
    setNavigateToPage,
    updateSystemInfo } = environmentSlice.actions;
export default environmentSlice.reducer;