import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import VehicleDashboard from "./VehicleDashboard";
import VehicleData from "./VehicleData";
import { useDispatch, useSelector } from "react-redux";
import SHMessage from "../../../../custom-components/SHMessage";
import { IconThemeProvider } from "../../../../custom-components/context/IconThemesContext";
import DashboardSplitPane from "../../../core/Dashboard/DashboardSplitPane";
import SHAddForm from "../../common/SHAddForm";
import {setShowAddVehicle, setShowEditVehicle} from "../../../../slices/car_parking/VehicleSlice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { addNewVehicle, updateVehicle } from "../../../data/car_parking/vehicle/vehicleServices";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    formSidePanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
    },
    formBody: {
        display: "contents",
    },
}));

const VehicleModule = () => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const {environment, vehicles} = useSelector((state) => state);

    const {accessToken} = environment;
    const [error, setError] = useState(null);
    const [editLoadingFinished, setEditLoadingFinished] = useState(false);
    const dispatch = useDispatch();

    const fields = [{name: t('common:car_parking.vehicle.maLoaiXe'), id: "ma_loai_xe", type:"text"},
        {name: t('common:car_parking.vehicle.tenLoaiXe'), id: "ten_loai_xe", type:"text"},
        {name: t('common:car_parking.vehicle.giaBanNgay'), id: "gia_ban_ngay", type:"number"},
        {name: t('common:car_parking.vehicle.giaBenDem'), id: "gia_ban_dem", type:"number"},
        {name: t('common:car_parking.vehicle.giaCaNgay'), id: "gia_ca_ngay", type:"number"}];

    const initUpdateFields = [{name: t('common:car_parking.vehicle.maLoaiXe'), id: "ma_loai_xe", type:"text", allowEdit: false, hidden: true},
        {name: t('common:car_parking.vehicle.tenLoaiXe'), id: "ten_loai_xe", type:"text", allowEdit: false},
        {name: t('common:car_parking.vehicle.giaBanNgay'), id: "gia_ban_ngay", type:"number"},
        {name: t('common:car_parking.vehicle.giaBenDem'), id: "gia_ban_dem", type:"number"},
        {name: t('common:car_parking.vehicle.giaCaNgay'), id: "gia_ca_ngay", type:"number"},
        {name: t('common:misc.status'), id: "status", type:"selectFromArray", options:[{name:'Active', value: 1}, {name:'Disable', value:0}], allowEdit: false, hidden: true}];

    const [updateFields, setUpdateFields] = useState(initUpdateFields);


    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={() => setError(null)}/>

    useEffect(() => {
        if(!vehicles.showEditVehicle || vehicles.showEditVehicle === false){
            return;
        }
        if(vehicles.rowDataSelected === null){
            setError(t('common:error.pleaseSelectRowEdit'));

            dispatch(setShowEditVehicle(false));
            return;
        }
        const updateFieldsTmp = updateFields.map(item => {
            return { ...item, value: vehicles.rowDataSelected[item.id]}
        })

        setUpdateFields(updateFieldsTmp);
        setEditLoadingFinished(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicles.showEditVehicle])

    useEffect(() => {
        const updateFieldsTmp = updateFields.map(item => {
            const field = initUpdateFields.find(item2=>item2.id == item.id);
            return { ...item, name: field.name};
        })
        setUpdateFields(updateFieldsTmp);
    }, [i18n.language])

    const okAddFormClickHandle = (fields) => {
        console.log(fields);
        const vehicle = {}
        fields.forEach(item => {
            if(!item.hidden){
                vehicle[item.id] = item.value;
            }
        })
        console.log(vehicle);

        addNewVehicle(vehicle, accessToken).then((res) => {
            dispatch(setShowAddVehicle(false));
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
        })
    }

    const okEditFormClickHandle = (fields) => {
        console.log(fields);
        const vehicle = {}
        fields.forEach(item => {
            vehicle[item.id] = item.value;
        })
        console.log(vehicle);

        updateVehicle(vehicle, accessToken).then((res) => {
            dispatch(setShowEditVehicle(false));
            setEditLoadingFinished(false);
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
        })
    }

    const cancelAddFormClickHandle = () => {
        dispatch(setShowAddVehicle(false))
    }

    const cancelEditFormClickHandle = () => {
        dispatch(setShowEditVehicle(false));
        setEditLoadingFinished(false);
    }

    const sidePanel = () =>
        <div id="form-side-panel" className={classes.formSidePanel}>
            {!vehicles.showAddVehicle && !vehicles.showEditVehicle && <VehicleDashboard environment={environment} loading={false}/>}
        </div>

    const formBody = () => (
        <div id="form-body" className={classes.formBody}>
            {showErrorMessage()}
            {!vehicles.showAddVehicle && !vehicles.showEditVehicle && <VehicleData environment={environment} module={vehicles} />}
            {vehicles.showAddVehicle && <SHAddForm environment={environment} module={vehicles} title={t('common:car_parking.vehicle.addVehicle')} fields={fields} onOk={okAddFormClickHandle} onClose={cancelAddFormClickHandle}/>}
            {vehicles.showEditVehicle && editLoadingFinished && <SHAddForm environment={environment} module={vehicles} title={t('common:car_parking.vehicle.updateVehicle')} fields={updateFields} onOk={okEditFormClickHandle} onClose={cancelEditFormClickHandle} okBtnLabel={t('common:button.update')}/>}
        </div>
    )

    return (
        <IconThemeProvider>
            <DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()}/>
        </IconThemeProvider>
    );
}


export default VehicleModule;