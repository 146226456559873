import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import _ from 'lodash';
import {Alert, AlertTitle, Snackbar} from "@mui/material";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
}));

const SHMessage = ({message, severity, onMessageClosed}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onMessageClosed()
    };

    useEffect(() => {
    }, [message])

    return (
       <div className={classes.root}>
           <Snackbar open={open}
                     anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'right'
                     }}
                     autoHideDuration={10000}
                     style={{ height: "20%" }}
                     onClose={handleClose}>
               <Alert onClose={handleClose} severity={severity}>
                   <AlertTitle>{_.capitalize(severity)}</AlertTitle>
                   {message}
               </Alert>
           </Snackbar>
       </div>
    )
}

export default SHMessage