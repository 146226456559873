import axios from "axios";
import { apiHeaderX } from "./dataServices";
import { API } from "./../../config";

export const loadChargerHistoryRecordsQuery = async ( filter, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/chargerhistory/query`,
        filter,
        config
    )
}