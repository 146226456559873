import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import TopUpDashboard from "./TopUpDashboard";
import TopUpData from "./TopUpData";
import { useDispatch, useSelector } from "react-redux";
import SHMessage from "../../../../custom-components/SHMessage";
import { IconThemeProvider } from "../../../../custom-components/context/IconThemesContext";
import DashboardSplitPane from "../../../core/Dashboard/DashboardSplitPane";
import SHAddForm from "../../common/SHAddForm";
import {setShowAddTopUp, setShowEditTopUp} from "../../../../slices/TopUpSlice";
import { addNewTopUp, updateTopUp } from "../../../data/topUpServices"
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    formSidePanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
    },
    formBody: {
        display: "contents",
    },
}));

const TopUpModule = () => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const {environment, topups} = useSelector((state) => state);

    const {accessToken} = environment;
    const [error, setError] = useState(null);
    const [editLoadingFinished, setEditLoadingFinished] = useState(false);

    const dispatch = useDispatch();

    const initAddFields = [
        {name: t('common:user.userId'), id: "user_id", type:"text", hidden: true},
        {name: t('common:user.userName'), id: "name", type:"text", allowEdit: false},
        {name: t('common:user.amount'), id: "so_tien", type:"number"},
        {name: t('common:user.topUpDate'), id: "ngay_nap", type:"datetime"},
        {name: t('common:user.note'), id: "ghi_chu", type:"text"}
    ];
    const [fields, setFields] = useState(initAddFields);

    const initUpdateFields = [
        {name: t('common:user.topUpId'), id: "id", type:"text", hidden: true},
        {name: t('common:user.userName'), id: "name", type:"text", allowEdit: false},
        {name: t('common:user.amount'), id: "so_tien", type:"number"},
        {name: t('common:user.topUpDate'), id: "ngay_nap", type:"datetime"},
        {name: t('common:user.note'), id: "ghi_chu", type:"text"},
        {name: t('common:user.status'), id: "status", type:"selectFromArray", options:[{name:'Active', value: 1}, {name:'Delete', value:0}]}
    ];
    const [updateFields, setUpdateFields] = useState(initUpdateFields);

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={() => setError(null)}/>

    useEffect(() => {
        if(!topups.showAddTopUp || topups.showAddTopUp === false){
            return;
        }

        const momentObj = moment(new Date());
        const fieldsTmp = fields.map(item => {
            switch(item.id){
                case "ngay_nap":
                    return { ...item, value: momentObj.format("YYYY-MM-DD HH:mm:ss")};
                default:
                    return { ...item, value: topups.dataGrid.user[item.id]}
            }
        })

        setFields(fieldsTmp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topups.showAddTopUp])

    useEffect(() => {
        if(!topups.showEditTopUp || topups.showEditTopUp === false){
            return;
        }
        if(topups.rowDataSelected === null){
            setError(t('common:error.pleaseSelectRowEdit'));

            dispatch(setShowEditTopUp(false));
            return;
        }
        const updateFieldsTmp = updateFields.map(item => {
            let momentObj;
            switch(item.id){
                case "name":
                    return { ...item, value: topups.dataGrid.user[item.id]}
                case "ngay_nap":
                    momentObj = moment(topups.rowDataSelected[item.id]);
                    return { ...item, value: momentObj.format("YYYY-MM-DD HH:mm:ss")};
                default:
                    return { ...item, value: topups.rowDataSelected[item.id]}
            }
        })

        setUpdateFields(updateFieldsTmp);
        setEditLoadingFinished(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topups.showEditTopUp])

    useEffect(() => {
        const updateFieldsTmp = updateFields.map(item => {
            const field = initUpdateFields.find(item2=>item2.id == item.id);
            return { ...item, name: field.name};
        })
        setUpdateFields(updateFieldsTmp);

        const fieldsTmp = fields.map(item => {
            const field = initAddFields.find(item2=>item2.id == item.id);
            return { ...item, name: field.name};
        })
        setFields(fieldsTmp);
    }, [i18n.language])
    
    const okAddFormClickHandle = (fields) => {
        console.log(fields);
        const topUp = {}
        fields.forEach(item => {
            topUp[item.id] = item.value;
        })
        console.log(topUp);

        addNewTopUp(topUp, accessToken).then((res) => {
            dispatch(setShowAddTopUp(false));
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
        })
    }

    const okEditFormClickHandle = (fields) => {
        console.log(fields);
        const topUp = {}
        fields.forEach(item => {
            topUp[item.id] = item.value;
        })
        console.log(topUp);

        updateTopUp(topUp, accessToken).then((res) => {
            dispatch(setShowEditTopUp(false));
            setEditLoadingFinished(false);
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
        })
    }

    const cancelAddFormClickHandle = () => {
        dispatch(setShowAddTopUp(false))
    }

    const cancelEditFormClickHandle = () => {
        dispatch(setShowEditTopUp(false));
        setEditLoadingFinished(false);
    }

    const sidePanel = () =>
        <div id="form-side-panel" className={classes.formSidePanel}>
            {!topups.showAddTopUp && !topups.showEditTopUp && <TopUpDashboard environment={environment} loading={false}/>}
        </div>

    const formBody = () => (
        <div id="form-body" className={classes.formBody}>
            {showErrorMessage()}
            {!topups.showAddTopUp && !topups.showEditTopUp && !topups.showTopUp && <TopUpData environment={environment} module={topups} />}
            {topups.showAddTopUp && <SHAddForm environment={environment} module={topups} title={t('common:topUp.addTopUp')} fields={fields} onOk={okAddFormClickHandle} onClose={cancelAddFormClickHandle}/>}
            {topups.showEditTopUp && editLoadingFinished && <SHAddForm environment={environment} module={topups} title={t('common:topUp.updateTopUp')} fields={updateFields} onOk={okEditFormClickHandle} onClose={cancelEditFormClickHandle} okBtnLabel={t('common:button.update')}/>}
        </div>
    )

    return (
        <IconThemeProvider>
            <DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()}/>
        </IconThemeProvider>
    );
}


export default TopUpModule;