import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    data: {
        totalCharger: 0,
        totalDevice: 0,
        totalCustomer: 0,
        totalPort: 0,
        totalRevenue: 0
    },
    filter: {
        startDate: null,
        endDate: null
    },
    refreshData: false,
};
const statisticSlice = createSlice({
    name: 'Statistic',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        setFilter:(state, action) => {
            return {...state, filter: action.payload};
        },
        setFilterStartDate:(state, action) => {
            return {...state, filter:{...state.filter, startDate: action.payload}};
        },
        setFilterEndDate:(state, action) => {
            return {...state, filter:{...state.filter, endDate: action.payload}};
        },
        setDataStatistic:(state, action) => {
            return {...state, refreshData: false, data: action.payload};
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, data: {}};
        }
    }
});

export const {
    reset,
    setRefreshData,
    setFilter,
    setFilterStartDate,
    setFilterEndDate,
    setDataStatistic} = statisticSlice.actions;
export default statisticSlice.reducer;