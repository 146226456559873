
import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { IconThemeContext } from "../../../custom-components/context/IconThemesContext";
import SHMessage from "../../../custom-components/SHMessage";

import LoadingSpinner from "../../../custom-components/LoadingSpinner";
import SkeletonLoaderAddForm from "../../../custom-components/skeletons/SkeletonLoaderAddForm";
import { Box, TextField } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { loadCustomData } from "../../data/dataServices";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import enUS from "date-fns/locale/en-US";
import moment from "moment";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ColorPicker } from 'material-ui-color';

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        }
    },
    dataGridToolbar: {
        minHeight: 20,
        paddingLeft: '0px',
        paddingBottom: '8px'
    },
    dialog: {
        '& .MuiButton-root': {
            textTransform: "none !important",
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
    },
    headingContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
    },
    headingLabel: {
        display: "block",
        minWidth: "200px",
    },
    headingAction: {
        display: "flex",
        alignItems: "center",
    },
    headingProjectSelection: {
        display: "inline-flex",
        width: "24em",
        maxWidth: "24em",
    },
    icon: props => ({
        color: props.color,
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
    formControl: {
        width: "100%",
        margin: "8px 0px !important",
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    formControlArray: {
        width: "100%",
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    alignRight: {
        textAlign: "right"
    },
    cardHeader:{
        border: "1px solid gray",
        backgroundColor: "lightgray",
        height: "40px",
        fontSize: "20px",
        padding: "10px",
        borderBottom: "none",
        display: "flex",
        alignItems: "center"
    },
    cardBody:{
        border: "1px solid gray",
        padding: "10px 20px",
    },
    cardFooter:{
        borderTop: "none",
        border: "1px solid gray",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    arrayItemRow:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .ColorPicker-MuiButtonBase-root .ColorPicker-MuiButton-label div':{
            border: '1px solid black !important'
        }
    },
    customValueBox: {
        width: 175,
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    dateField:{
        marginLeft: "0px"
    },
    odd:{
        backgroundColor: "azure",
        '& .Mui-readOnly': {
            backgroundColor: "lightgray",
        },
    },
    even:{
        backgroundColor: "white",
        '& .Mui-readOnly': {
            backgroundColor: "lightgray",
        },
    }
}));

const SHAddForm = (props) => {
    const {environment, title, fields, onOk, onClose, okBtnLabel, cancelBtnLabel, hideCloseBtn, hideOkBtn} = props;
    const [t] = useTranslation('common');

    const {accessToken} = environment;
    const {iconTheme} = useContext(IconThemeContext);
    const classes = useStyles(iconTheme);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);

    const [selectTableData, setSelectTableData] = useState({});
    const [hiddenFields, setHiddenFields] = useState({});


    const [viewData, setViewData] = useState({});
    const [isOpenDate, setIsOpenDate] = useState(false);

    const dateValueRef = useRef(null);

    const showLoading = () => {
        if(loading) return (
            <>
                <LoadingSpinner/>
                {<SkeletonLoaderAddForm/>}
            </>
        )
    }

    useEffect(() => {
        setLoading(true);
    }, [])

    useEffect(() => {
        if(loading){
            var  data = {};
            let filters = [];
            let hiddenFields = {};
            fields.forEach((item, index) => {
                if(item.showIfTrue !== null && item.showIfTrue !== undefined && item.value){
                    hiddenFields[item.showIfTrue] = false;
                }
                if(item.hidden && hiddenFields[item.id] !== false){
                    hiddenFields[item.id] = true;
                }
                if(item.type === 'selectFromTable'){
                    data[index] = item.value||'';
                    if(!selectTableData[item.table]){
                        selectTableData[item.table] = [];
                        let filter = {table: [item.table]};
                        if(item.filterForAccount){
                            filter.where = ["account_id = ?"];
                        }
                        switch(item.table){
                            case 'projects':
                                filter.fields = ['project_id as value', 'project_name as name'];
                                break;
                            case 'devices':
                                filter.fields = ['device_id as value', 'name'];
                                break;
                            case 'maintenance_plannings':
                                filter.fields = ['maintenance_planning_id as value', 'name'];
                                break;
                            case 'maintenance_contents':
                                filter.fields = ['maintenance_content_id as value', 'name'];
                                break;
                            case 'accounts':
                                filter.fields = ['account_id as value', 'account_name as name'];
                                break;
                            case 'tramsac':
                                filter.fields = ['ma_tram_sac as value', 'ten_tram_sac as name'];
                                break;
                            case 'cp_gia_gui_xe_thang':
                                filter.fields = ['ma_loai_xe as value', 'gia_tien as name'];
                                break;
                            case 'cp_loai_xe':
                                filter.table = ['cp_loai_xe as a left join cp_gia_gui_xe_thang as b ON a.account_id = b.account_id AND a.ma_loai_xe = b.ma_loai_xe AND a.status = 1'];
                                filter.fields = ['a.ma_loai_xe as value', 'a.ten_loai_xe as name'];
                                filter.where = ["a.account_id = ?"];
                                filter.resultName = "cp_loai_xe";
                                break;
                            default:
                                break;
                        }
                        if(item.onChangeDestinations && item.onChangeDestinations.length > 0){
                            filter.fields = [...filter.fields, ...item.onChangeDestinations];
                        }
                        filter.orderby = ['name'];

                        filters.push(filter);
                    }
                }else if(item.type === 'array'){
                    data[index] = item.value||[];
                }else if(item.type === 'image'){
                    data[index] = {value:item.value}||'';
                }else if(item.type === 'file'){
                    data[index] = {value:item.value}||'';
                }else{
                    if(item.value == null){
                        data[index] = item.value||'';
                    }else{
                        data[index] = item.value;
                    }
                }
                if(item.displayValue != null && item.displayValue != ""){
                    data[index] = item.displayValue;
                }
                if((data[index] == null || data[index] === '')){
                    if(item.setCurrentDate){
                        if(item.type === 'time'){
                            data[index] = moment().format('HH:mm:ss');
                        }else if(item.type === 'datetime'){
                            data[index] = moment().format('YYYY-MM-DD HH:mm:ss');
                        }else if(item.type === 'date'){
                            data[index] = moment().format('YYYY-MM-DD');
                        }
                    }else if(item.setFirstDayOfMonth){
                        if(item.type === 'time'){
                            data[index] = moment().startOf('month').format('HH:mm:ss');
                        }else if(item.type === 'datetime'){
                            data[index] = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                        }else if(item.type === 'date'){
                            data[index] = moment().startOf('month').format('YYYY-MM-DD');
                        }
                    }else if(item.setLastDayOfMonth){
                        if(item.type === 'time'){
                            data[index] = moment().endOf('month').format('HH:mm:ss');
                        }else if(item.type === 'datetime'){
                            data[index] = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
                        }else if(item.type === 'date'){
                            data[index] = moment().endOf('month').format('YYYY-MM-DD');
                        }
                    }
                    
                }
            })
            setHiddenFields(hiddenFields);
            setViewData(data);
            if(filters.length>0){
                loadCustomData(filters, accessToken).then(response => {
                    setSelectTableData(response.data.results);
                    setLoading(false);
                }).catch(err => {
                    setError(err.response ? 'Code ' + err.response.data.code + ": " + err.response.data.message : err.message);
                }).finally(() => {
                });
            }else{
                setLoading(false);
            }
        }
    }, [loading, accessToken, fields, selectTableData])

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed}/>

    const showInfoMessage = () =>
        info && <SHMessage message={info} severity={'success'} onMessageClosed={handleMessageClosed}/>

    const handleMessageClosed = () => {
        setError(null);
        setInfo(null);
    }

    const handleChange = (index) => event => {
        viewData[index] = event.target.value;
        setViewData({...viewData});
    }

    const handleSelectChange = (field, index) => event => {
        let hiddenFieldsTmp = {...hiddenFields};
        viewData[index] = event.target.value;
        if(field.onChangeDestinations && field.onChangeDestinations.length > 0){
            fields.forEach((item, ind)=>{
                if(field.onChangeDestinations.indexOf(item.id)>=0){
                    let value = '';
                    const lookupData = selectTableData[field.table].filter(itm=>itm.value === viewData[index]);
                    if(lookupData.length>0){
                        value = lookupData[0][item.id];
                    }
                    viewData[ind] = value;
                    if(item.showIfTrue !== null && item.showIfTrue !== undefined){
                        if(value){
                            hiddenFieldsTmp[item.showIfTrue] = false;
                        }else{
                            hiddenFieldsTmp[item.showIfTrue] = true;
                        }
                    }
                }
            })
            /*field.onChangeDestinations.forEach(item=>{
                const selectedFields = fields.filter(tmp=>tmp.id===item);
                if(selectedFields.length>0){
                    const lookupData = selectTableData[field.table].filter(itm=>itm.value === viewData[index]);

                }
            })*/
        }
        setViewData({...viewData});
        setHiddenFields(hiddenFieldsTmp);
    }

    const handleCheckboxChange = (field, index) => event => {
        viewData[index] = event.target.checked;
        if(field.showIfTrue){
            let hiddenFieldsTmp = {...hiddenFields}
            hiddenFieldsTmp[field.showIfTrue] = !viewData[index];
            setHiddenFields(hiddenFieldsTmp);
        }
        setViewData({...viewData});
    }

    const handleColorPickerChange = (field, index) => event => {
        viewData[index] = "#" + event.hex;
        setViewData({...viewData});
    }

    const handleChangeSingleDatePicker = (newValue, index, isOnInput) => {
        function handleDate (value) {
            let momentObj = moment(value);
            if(momentObj.isValid()){
                viewData[index] = momentObj.format("YYYY-MM-DD")
            }
        }
        if(isOnInput) {
            viewData[index] = newValue;
        } else {
            if(!isOpenDate) return;
            handleDate(newValue);
        }
        setViewData({...viewData});
    }

    const handleChangeSingleDateTimePicker = (newValue, index, isOnInput) => {
        function handleDate (value) {
            let momentObj = moment(value);
            if(momentObj.isValid()){
                viewData[index] = momentObj.format("YYYY-MM-DD HH:mm:ss")
            }
        }
        if(isOnInput) {
            viewData[index] = newValue;
        } else {
            if(!isOpenDate) return;
            handleDate(newValue);
        }
        setViewData({...viewData});
    }

    const handleFileUpload = (index) => event => {
        let file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            viewData[index] = {value: reader.result, file: file};
            setViewData({...viewData});
        };

        reader.readAsDataURL(file);
    }

    const handleArrayItemChange = (index, idx, fieldName) => event => {
        viewData[index][idx][fieldName] = event.target.value;
        setViewData({...viewData});
    }

    const handleArrayColorPickerChange = (index, idx, fieldName) => event => {
        viewData[index][idx][fieldName] = "#" + event.hex;
        setViewData({...viewData});
    }

    const handleClose = () => {
        onClose();
    }

    const handleOk = () => {
        const data = fields.map((item, index) => {
            item.value = viewData[index];
            if(item.hidden && hiddenFields[item.id] === false){
                item.hidden = false;
            }
            return {...item}
        });
        onOk(data);
    }

    const onAddItemArray = (field, index) =>{
        var newItem = {};
        newItem[field.columns[1].id] = "";
        newItem[field.columns[2].id] = "";
        if(!viewData[index]){
            viewData[index] = [];
        }
        viewData[index].push(newItem);
        setViewData({...viewData});
    }

    const onDeleteItemArray = (field, index, ind) =>{
        const idName = field.columns[0].id;
        if(viewData[index][ind][idName] !== null && viewData[index][ind][idName] !== undefined){
            viewData[index][ind]['delete'] = true;
        }else{
            delete viewData[index][ind];
        }
        setViewData({...viewData});
    }

    const renderArray = (field, index) => {
        return (<Box component="fieldset"
                    sx={{flexGrow: 1,
                        border: "1px solid gray",
                        '& .MuiTextField-root': { m: 1, width: '45%' },
                    }}
                >
                    <legend>{field.name}</legend>
                    {
                        viewData && viewData[index] !== undefined &&
                            viewData[index].map((item, ind) => {
                                if(item.delete) return '';
                                return <div className={`${classes.arrayItemRow} ${item.className=="odd"?classes.odd:classes.even}`}>
                                            <TextField id={item.id + '_ind_name'}
                                                className={classes.formControlArray}
                                                value={item[field.columns[1].id]}
                                                onChange={handleArrayItemChange(index, ind, field.columns[1].id)}
                                                label={field.columns[1].name}
                                                size="small"
                                                InputProps={{
                                                    readOnly: field.columns[1].allowEdit===false,
                                                }}
                                                variant="outlined" />
                                            {field.columns[2].type === 'colorPicker' && <ColorPicker hideTextfield id={item.id + '_ind_value'} value={item[field.columns[2].id]} onChange={handleArrayColorPickerChange(index, ind, field.columns[2].id)} />}
                                            {field.columns[2].type !== 'colorPicker' && <TextField id={item.id + '_ind_value'}
                                                        className={classes.formControlArray}
                                                        value={item[field.columns[2].id]}
                                                        onChange={handleArrayItemChange(index, ind, field.columns[2].id)}
                                                        label={field.columns[2].name}
                                                        size="small"
                                                        InputProps={{
                                                            readOnly: item.allowEdit===false,
                                                        }}
                                                        variant="outlined" />}

                                            {field.showDelete !== false && <IconButton size="small" edge="end" onClick={() => onDeleteItemArray(field, index, ind)}>
                                                <DeleteForeverIcon className={classes.icon}/>
                                            </IconButton>}
                                        </div>
                            })
                    }
                    { field.showAdd !== false && <div>
                        <IconButton size="small" edge="end" onClick={() => onAddItemArray(field, index)}>
                            <AddBoxIcon className={classes.icon}/>
                        </IconButton>
                    </div>}
                </Box>);
    }

    const renderSelectFromTable = (field, index) => {
        return (
            <FormControl className={classes.formControl} sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id={field.id + "-label"}>{field.name}</InputLabel>
                <Select
                    labelId={field.id + "-label"}
                    id={field.id + "-helper"}
                    value={viewData[index]}
                    label={field.name}
                    loading={true}
                    readOnly={field.allowEdit===false}
                    onChange={handleSelectChange(field, index)}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {selectTableData[field.table] && selectTableData[field.table].map(item=>
                        <MenuItem value={item.value}>{item.name}</MenuItem>
                    )}
                </Select>
            </FormControl>);
    }

    const renderSelectFromArray = (field, index) => {
        return (
            <FormControl className={classes.formControl} sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id={field.id + "-label"}>{field.name}</InputLabel>
                <Select
                    labelId={field.id + "-label"}
                    id={field.id + "-helper"}
                    value={viewData[index]}
                    label={field.name}
                    loading={true}
                    onChange={handleSelectChange(field, index)}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {field.options.map(item=> {
                        if(item.account_type != null){
                            if(item.account_type.includes(environment?.user?.account_type)){
                                return <MenuItem value={item.value}>{item.name}</MenuItem>
                            }
                        }else{
                            return <MenuItem value={item.value}>{item.name}</MenuItem>
                        }
                    }
                    )}
                </Select>
            </FormControl>);
    }

    const renderSingleDatePicker = (field, index) => {
        return (
            <LocalizationProvider
                dateAdapter={DateAdapter}
                locale={enUS}>
                <DatePicker
                    className={`${classes.formControl} ${classes.dateField}`}
                    dateFormat="yyyy-MM-dd"
                    id={field.id}
                    label={field.name}
                    selected={viewData[index]}
                    value={moment(viewData[index]).toDate()}
                    onChange={(newValue) => handleChangeSingleDatePicker(newValue, index, false)}
                    onOpen={() => setIsOpenDate(true)}
                    onClose={() => setIsOpenDate(false)}
                    readOnly={field.allowEdit===false}
                    renderInput={(params) =>
                        <TextField {...params}
                            className={classes.formControl}
                            inputRef={dateValueRef}
                            onChange={(e) => handleChangeSingleDatePicker(e.target.value, index, true)}
                            size="small"
                            variant="outlined"
                            InputProps={{
                                readOnly: field.allowEdit===false,
                            }}
                            label={field.name}>
                        </TextField>
                    }
                />
            </LocalizationProvider>
        )
    }

    const renderSingleDateTimePicker = (field, index) => {
        return (
            <LocalizationProvider
                dateAdapter={DateAdapter}
                locale={enUS}>
                <DateTimePicker
                    className={`${classes.formControl} ${classes.dateField}`}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    id={field.id}
                    label={field.name}
                    selected={viewData[index]}
                    value={moment(viewData[index]).toDate()}
                    onChange={(newValue) => handleChangeSingleDateTimePicker(newValue, index, false)}
                    onOpen={() => setIsOpenDate(true)}
                    onClose={() => setIsOpenDate(false)}
                    readOnly={field.allowEdit===false}
                    renderInput={(params) =>
                        <TextField {...params}
                            className={classes.formControl}
                            inputRef={dateValueRef}
                            onChange={(e) => handleChangeSingleDateTimePicker(e.target.value, index, true)}
                            size="small"
                            variant="outlined"
                            InputProps={{
                                readOnly: field.allowEdit===false,
                            }}
                            label={field.name}>
                        </TextField>
                    }
                />
            </LocalizationProvider>
        )
    }

    const renderCheckbox = (field, index) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={viewData[index] && viewData[index] !== ''}
                        onChange={handleCheckboxChange(field, index)}
                />}
                label={field.name}
            />
        )
    }

    const renderColorPicker = (field, index) => {
        return (
            <ColorPicker defaultValue={viewData[index]} onChange={handleColorPickerChange(field, index)} />
        )
    }


    const renderInputField = (index) => {
        const field = fields[index];
        if(field.enableWhen != null && field.enableWhen != environment?.user?.type){
            return "";
        }
        if(field.type === 'array'){
            return renderArray(field, index);
        }else if(field.type === 'selectFromTable'){
            return renderSelectFromTable(field, index);
        }else if(field.type === 'selectFromArray'){
            return renderSelectFromArray(field, index);
        }else if(field.type === 'date'){
            return renderSingleDatePicker(field, index);
        }else if(field.type === 'datetime'){
            return renderSingleDateTimePicker(field, index);
        }else if(field.type === 'checkbox'){
            return renderCheckbox(field, index);
        }else if(field.type === 'colorPicker'){
            return renderColorPicker(field, index);
        }else if(field.type === 'image'){
            return  (
                <div className={classes.formControl}>
                    <label>{field.name}</label>
                    {viewData[index] && viewData[index]['value'] && <img src={viewData[index]['value']} alt="Upload" height="300" />}
                </div>
            )
        }else if(field.type === 'file'){
            return  (
                <div className={classes.formControl}>
                    <label htmlFor="upload-image">
                        <Button variant="contained" component="span">
                            Upload image
                        </Button>
                        <input
                            id="upload-image"
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={handleFileUpload(index)}
                        />
                    </label>
                    {viewData[index] && viewData[index]['value'] && <img src={viewData[index]['value']} alt="Upload" height="300" />}
                </div>
            )
        }else if(field.type === 'number'){
            return <TextField id={field.id}
                className={classes.formControl}
                value={viewData[index]}
                onChange={handleChange(index)}
                label={field.name}
                size="small"
                type="number"
                InputProps={{
                    readOnly: field.allowEdit===false,
                }}
                variant="outlined" />
        }else if(field.type === 'password'){
            return <TextField id={field.id}
                className={classes.formControl}
                value={viewData[index]}
                onChange={handleChange(index)}
                label={field.name}
                size="small"
                type="password"
                InputProps={{
                    readOnly: field.allowEdit===false,
                }}
                variant="outlined" />
        }else if(field.multiline){
            return <TextField id={field.id}
                className={classes.formControl}
                value={viewData[index]}
                onChange={handleChange(index)}
                label={field.name}
                size="small"
                multiline
                InputProps={{
                    readOnly: field.allowEdit===false,
                }}
                variant="outlined" />
        }else{
            return <TextField id={field.id}
                className={classes.formControl}
                value={viewData[index]}
                onChange={handleChange(index)}
                label={field.name}
                size="small"
                InputProps={{
                    readOnly: field.allowEdit===false,
                }}
                variant="outlined" />
        }
    }

    const renderForm = () => {
        return (
            !loading && <Box
                sx={{flexGrow: 1,
                    '& .MuiTextField-root': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <div className={classes.cardHeader}>
                    <label>{title}</label>
                </div>
                <div className={classes.cardBody}>
                    { fields.map((field, index) => (!hiddenFields[field.id] &&
                        <div className="mb-3">
                            { renderInputField(index) }
                        </div>
                    ))}
                </div>
                <div className={classes.cardFooter}>
                    {!hideOkBtn && <button onClick={handleOk} style={{marginRight: '10px'}}>
                        {okBtnLabel?okBtnLabel:t('common:button.add')}
                    </button>
                    }
                    {!hideCloseBtn && <button onClick={handleClose}>
                            {cancelBtnLabel?cancelBtnLabel:t('common:button.cancel')}
                        </button>
                    }
                </div>
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            {showLoading()}
            {showErrorMessage()}
            {showInfoMessage()}
            {renderForm()}
        </div>
    )
}

export default SHAddForm;