import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { makeStyles } from '@mui/styles';
import { toNumber } from "lodash";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "stretch",
        height: "100vh",
    },
    topBar: {
        transform: "none",
    },
    main: {
        display: "flex",
        alignItems: "stretch",
        flex: "1 1 auto",
    },
    leftPanel: {
        display: "flex",
        height: "auto",
    },
    rightPanel: {
        display: "flex",
        height: "auto",
        flex: "1 1 auto",
    }
}));

const SkeletonLoaderApp = () => {

    const classes = useStyles();
    const leftPanelWidth = toNumber(localStorage.getItem("left_panel_width")) || 400;

    return (
        <Stack className={classes.root}>
            <Skeleton className={classes.topBar} animation="wave" variant="text" height={24}/>
            <div style={{display: "flex", marginTop: 10}}>
                <Skeleton animation="wave" variant="circular" width={100} height={100} sx={{ml: 2}}/>
            </div>
            <Skeleton animation="wave" variant="text" height={50}/>
            <div className={classes.main}>
                <Skeleton className={classes.leftPanel} animation="wave" variant="rectangular" width={leftPanelWidth} />
                <Skeleton className={classes.rightPanel} animation="wave" variant="rectangular" sx={{ml: 1}}/>
                {/*<Skeleton variant="rectangular" height={950} sx={{ml: 2}}/>*/}
            </div>
        </Stack>
    )
}

export default SkeletonLoaderApp