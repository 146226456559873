import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const SCREEN_KH05 = "screen-KH05";
export const ScreenKH05 = ({
    show,
    curDate,
    curTime,
    tenKhachHang,
    maKhachHang,
    address, 
    phone, 
    tienChuaThanhToan,
    listChuaThanhToan,
    listDaThanhToan,
    handleThoat,
    screenId
}) => {
    if(!show){
        return;
    }
    return (
        <Grid id="screen-KH05" className="hide-item" container spacing={2}>
            <Grid item xs={12}  align="center">
                <Typography variant="h6" className="title-info">
                    QUẢN LÝ TÀI KHOẢN
                </Typography>
                <Typography>
                    Ngày: {curDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thời gian: {curTime}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <label>Khách hàng:</label> <span className="lbl-ten-khach-hang">{tenKhachHang}</span>&nbsp;&nbsp;
                    <label>Mã số khách hàng:</label> <span className="lbl-ma-khach-hang">{maKhachHang}</span>
                </div>
                <div>
                    <label>Địa chỉ:</label> <span className="lbl-dia-chi-kh">{address}</span>&nbsp;&nbsp;
                    <label>Điện thoại:</label> <span className="lbl-dien-thoai-kh">{phone}</span>
                </div>
                <div>
                    <label>Mật khẩu:</label> <span className="lbl-mat-khau">******</span> <a href="#">&lt;Đổi mật khẩu truy cập&gt;</a>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <label>Tổng tiền sạc chưa thanh toán:</label> <span className="tien-chua-thanh-toan">{tienChuaThanhToan}</span> đồng <a href="#">&lt;Liệt kê các lần đã thanh toán&gt;</a>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <label>Giao dịch phát sinh chưa thanh toán:</label>
                </div>
                {listChuaThanhToan && listChuaThanhToan.map((item, index) => (
                    <Grid item xs={12} key={index}>
                        {item}
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
                <div>
                    <label>Giao dịch phát sinh đã thanh toán:</label>
                </div>
                {listDaThanhToan && listDaThanhToan.map((item, index) => (
                    <Grid item xs={12} key={index}>
                        {item}
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={handleThoat}>
                    Thoát
                </Button>
            </Grid>
        </Grid>
    );
};