import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ChargerHistoryDashboard from "./ChargerHistoryDashboard";
import ChargerHistoryData from "./ChargerHistoryData";
import { useDispatch, useSelector } from "react-redux";
import SHMessage from "../../../../custom-components/SHMessage";
import { IconThemeProvider } from "../../../../custom-components/context/IconThemesContext";
import DashboardSplitPane from "../../../core/Dashboard/DashboardSplitPane";
import SHAddForm from "../../common/SHAddForm";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    formSidePanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
    },
    formBody: {
        display: "contents",
    },
}));

const ChargerHistoryModule = () => {
    const classes = useStyles();
    const [t] = useTranslation('common');
    const {environment, chargerhistories} = useSelector((state) => state);

    const {accessToken} = environment;
    const [error, setError] = useState(null);

    const dispatch = useDispatch();


    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={() => setError(null)}/>

    const sidePanel = () =>
        <div id="form-side-panel" className={classes.formSidePanel}>
            <ChargerHistoryDashboard environment={environment} loading={false}/>
        </div>

    const formBody = () => (
        <div id="form-body" className={classes.formBody}>
            {showErrorMessage()}
            <ChargerHistoryData environment={environment} module={chargerhistories} />
        </div>
    )

    return (
        <IconThemeProvider>
            <DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()}/>
        </IconThemeProvider>
    );
}


export default ChargerHistoryModule;