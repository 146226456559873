import React from 'react';
import { Grid, Typography, Checkbox, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
export const SCREEN_KH01 = "screen-KH01";

export const ScreenKH01 = ({ show, curDate, curTime, address, phone, truSac, congSac, dienAp, cuongDo, giaDien, chkDongY, handleChange, username, password, handleDangNhap, handleQuenMatKhau, handleCheckChange, loading }) => {
    if(!show){
        return;
    }
    return (
        <Grid id="{SCREEN_KH01}" sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid justifyContent="center" item xs={12}>
                <Typography variant="body2" color="text.secondary" align="center">
                    CHÀO MỪNG BẠN ĐẾN VỚI DỊCH VỤ
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                    SẠC ĐIỆN XE MÁY THANH TOÁN TỰ ĐỘNG
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                    Ngày: {curDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thời gian: {curTime}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Địa chỉ: {address}</Typography>
                <Typography>Điện thoại hỗ trợ: {phone}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>Bạn đã chọn:</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>Trụ sạc: {truSac}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>Cổng sạc số: {congSac}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>Thông số cổng:</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>Điện áp: {dienAp}Vac</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>Dòng sạc tối đa: {cuongDo}A</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>Đơn giá sạc điện:</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{giaDien} đồng/kWh</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Quy định trạm sạc:</Typography>
                <Typography>- Chỉ được sạc xe đạp hay xe máy điện.</Typography>
                <Typography>- Thời gian sạc tối đa là 10h.</Typography>
                <Typography>- Không hoàn tiền nếu dừng sạc trước thời gian kết thúc dự kiến.</Typography>
                <Typography>
                    Bạn bấm chọn đồng ý: <Checkbox checked={chkDongY} onChange={handleCheckChange} name="chkDongY" />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={username}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="username"
                    label="Tên đăng nhập"
                    name="username"
                    autoComplete="username"
                    required
                    autoFocus
                />
                <TextField
                    value={password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="password"
                    label="Mật khẩu"
                    name="password"
                    autoComplete="current-password"
                    type="password"
                    required
                />
                <LoadingButton loading={loading} variant="contained" onClick={handleDangNhap} >
                    Đăng nhập
                </LoadingButton>
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handleQuenMatKhau}>
                    Quên mật khẩu
                </Button>
            </Grid>
        </Grid>
    );
};
