import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@UserStatus', type: 'EQ'}
        ]
    }
}

const initialUser = {
    name: '',
    email: '',
    phone: '',
    address: '',
    tong_tien_nap: '',
    tong_tien_su_dung: '',
    tong_tien_con_lai: ''
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        userColumns : [
            {name: 'Tên người dùng', translateId: 'common:user.userName', id: "name"},
            {name: 'Email', translateId: 'common:user.email', id: "email"},
            {name: 'Điện thoai', translateId: 'common:user.phone', id: "phone"},
            {name: 'Địa chỉ', translateId: 'common:user.address', id: "address"},
            {name: 'Số tiền đã nạp', translateId: 'common:tramsac.tienDaNap', id: "tong_tien_nap"},
            {name: 'Số tiền sử dụng', translateId: 'common:tramsac.tienSuDung', id: "tong_tien_su_dung"},
            {name: 'Số tiền còn trong TK', translateId: 'common:tramsac.tienConLai', id: "tong_tien_con_lai"},
        ],
        columns: [
            {name: 'Mã nạp tiền', translateId: 'common:topUp.maNapTien', id: "ma_nap_tien", hidden:true},
            {name: 'Số tiền nạp', translateId: 'common:topUp.soTienNap', id: "so_tien", type: "INTEGER"},
            {name: 'Ngày nạp', translateId: 'common:topUp.ngayNap', id: "ngay_nap", type: "DATETIME", format: "DD/MM/YYYY HH:mm"},
            {name: 'Ghi chú', translateId: 'common:user.note', id: "ghi_chu"}
        ],
        user: {
            name: '',
            email: '',
            phone: '',
            address: '',
            tong_tien_nap: '',
            tong_tien_su_dung: '',
            tong_tien_con_lai: ''
        },
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddTopUp: false,
    showEditTopUp: false,
    rowPerPage: 10
};
const topUpSlice = createSlice({
    name: 'topup',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addTopUp:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, user: {...action.payload.user},  records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, user: {...action.payload.user}, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddTopUp:(state, action) => {
            if(action.payload === false){
                return {...state, showAddTopUp: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddTopUp: action.payload};
            }
        },
        setShowEditTopUp:(state, action) => {
            if(action.payload === false){
                return {...state, showEditTopUp: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditTopUp: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var user = state.dataGrid.records.filter(item => item.ma_nap_tien === action.payload.ma_nap_tien);
            if(user.length>0){
                return {...state, rowDataSelected: user[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        },
        setFilter:(state, action) => {
            return {...state, refreshData:true, dataGrid: {...state.dataGrid, user: initialUser, records: [], query:{...state.dataGrid.query, filter:{...state.dataGrid.query.filter, user_id: action.payload}}}};
        }
    }
});

export const {
    reset,
    addTopUp,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddTopUp,
    setShowEditTopUp,
    setRowDataSelected,
    setFilter
 } = topUpSlice.actions;
export default topUpSlice.reducer;