import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { StyledEngineProvider } from '@mui/material/styles';
import common_en from "./translations/en/common.json";
import common_vi from "./translations/vi/common.json";
import reducers from './reducers';
import { Provider } from 'react-redux';
import { configureStore } from "@reduxjs/toolkit";


const store = configureStore({
  reducer: reducers,
});

const language = navigator.language.substring(0,2);

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: language,                              // default language from browser
  resources: {
    en: { common: common_en },          //English
    vi: { common: common_vi }           //Vietnamese
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <StyledEngineProvider injectFirst>
        <App id="app" />
      </StyledEngineProvider>
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
