import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@UserStatus', type: 'EQ'}
        ]
    }
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Mã người dùng', translateId: 'common:user.userId', id: "user_id", hidden:true},
            {name: 'Mã HKD', translateId: 'common:user.maHKD', id: "account_id"},
            {name: 'Tên người dùng', translateId: 'common:user.userName', id: "name"},
            {name: 'Email', translateId: 'common:user.email', id: "email"},
            {name: 'Điện thoai', translateId: 'common:user.phone', id: "phone"},
            {name: 'Tên Đăng nhập', translateId: 'common:user.login', id: "login"},
            {name: 'Mật khẩu', translateId: 'common:user.password', id: "password", hidden:true},
            {name: 'Trạng thái', translateId: 'common:user.status', id: "status"},
            {name: 'Url', translateId: 'common:user.url', id: "url", hidden: true},
            {name: 'Loại người dùng', translateId: 'common:user.type', id: "type"}],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddUser: false,
    showEditUser: false,
    showTopUp: false,
    rowPerPage: 10
};
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addUser:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddUser:(state, action) => {
            if(action.payload === false){
                return {...state, showAddUser: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddUser: action.payload};
            }
        },
        setShowEditUser:(state, action) => {
            if(action.payload === false){
                return {...state, showEditUser: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditUser: action.payload};
            }
        },
        setShowTopUp:(state, action) => {
            if(action.payload === false){
                return {...state, showTopUp: action.payload, rowDataSelected: null};
            }else{
                return {...state, showTopUp: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var user = state.dataGrid.records.filter(item => item.user_id === action.payload.user_id);
            if(user.length>0){
                return {...state, rowDataSelected: user[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        }
    }
});

export const {
    reset,
    addUser,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddUser,
    setShowEditUser,
    setShowTopUp,
    setRowDataSelected } = usersSlice.actions;
export default usersSlice.reducer;