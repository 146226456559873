import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@UserStatus', type: 'EQ'}
        ]
    }
}

const initialUser = {
    name: '',
    email: '',
    phone: '',
    address: '',
    tong_tien_nap: '',
    tong_tien_su_dung: '',
    tong_tien_con_lai: ''
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        userColumns : [
            {name: 'Tên người dùng', translateId: 'common:user.userName', id: "name"},
            {name: 'Email', translateId: 'common:user.email', id: "email"},
            {name: 'Điện thoai', translateId: 'common:user.phone', id: "phone"},
            {name: 'Địa chỉ', translateId: 'common:user.address', id: "address"},
            {name: 'Số tiền đã nạp', translateId: 'common:tramsac.tienDaNap', id: "tong_tien_nap"},
            {name: 'Số tiền sử dụng', translateId: 'common:tramsac.tienSuDung', id: "tong_tien_su_dung"},
            {name: 'Số tiền còn trong TK', translateId: 'common:tramsac.tienConLai', id: "tong_tien_con_lai"},
        ],
        columns: [
            {name: 'Mã sạc', translateId: 'common:tramsac.maSac', id: "ma_sac", hidden:true},
            {name: 'Trạm sạc', translateId: 'common:tramsac.tramSac', id: "ten_tram_sac"},
            {name: 'Trụ sạc', translateId: 'common:tramsac.truSac', id: "tru_sac"},
            {name: 'Cổng sạc', translateId: 'common:tramsac.congSac', id: "cong_sac"},
            {name: 'Thời gian chọn', translateId: 'common:tramsac.thoiGianChon', id: "time"},
            {name: 'Giá tiền', translateId: 'common:tramsac.giaTien', id: "price"},
            {name: 'Thời gian bắt đầu', translateId: 'common:tramsac.tgBatDau', id: "start", type:"DATETIME", format:"DD/MM/YYYY HH:mm"},
            {name: 'Thời gian kết thúc', translateId: 'common:tramsac.tgKetThuc', id: "end", type:"DATETIME", format:"DD/MM/YYYY HH:mm"},
            {name: 'Lượng điện tiêu thụ', translateId: 'common:tramsac.luongDienTT', id: "charged_wattage"},
        ],
        user: {
            name: '',
            email: '',
            phone: '',
            address: '',
            tong_tien_nap: '',
            tong_tien_su_dung: '',
            tong_tien_con_lai: ''
        },
        records: [],
        viewSession: false,
    },
    refreshData: false,
    rowPerPage: 10
};
const chargerHistorySlice = createSlice({
    name: 'chargerhistory',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, user: {...action.payload.user},  records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, user: {...action.payload.user}, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setRowDataSelected:(state, action) => {
            var user = state.dataGrid.records.filter(item => item.ma_nap_tien === action.payload.ma_nap_tien);
            if(user.length>0){
                return {...state, rowDataSelected: user[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        },
        setFilter:(state, action) => {
            return {...state, refreshData:true, dataGrid: {...state.dataGrid, user: initialUser, records: [], query:{...state.dataGrid.query, filter:{...state.dataGrid.query.filter, user_id: action.payload}}}};
        }
    }
});

export const {
    reset,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setRowDataSelected,
    setFilter
 } = chargerHistorySlice.actions;
export default chargerHistorySlice.reducer;