
import { makeStyles, styled } from "@mui/styles";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IconThemeContext } from "../../../../custom-components/context/IconThemesContext";
import SHMessage from "../../../../custom-components/SHMessage";

import LoadingSpinner from "../../../../custom-components/LoadingSpinner";
import SkeletonLoaderDataGrid from "../../../../custom-components/skeletons/SkeletonLoaderDataGrid";
import DMDataGrid from "../../../data/datagrid/DMDataGrid";
import { setDataGrid, setFilter, setFilterEndDate, setFilterStartDate, setFilterType, setRefreshData } from "../../../../slices/car_parking/BCSoLuotSlice";
import { loadBCSoLuotRecordsQuery } from "../../../data/car_parking/report/bcSoLuotServices"
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        }
    },
    resultGrid:{
        '& .MuiGrid-root': {
            '--Grid-borderWidth': '1px',
            borderTop: 'var(--Grid-borderWidth) solid',
            borderLeft: 'var(--Grid-borderWidth) solid',
            borderRight: 'var(--Grid-borderWidth) solid',
            borderBottom: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider'
        }
    },
    dataGridToolbar: {
        minHeight: 20,
        paddingLeft: '0px',
        paddingBottom: '8px'
    },
    dialog: {
        '& .MuiButton-root': {
            textTransform: "none !important",
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
    },
    headingContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
    },
    headingLabel: {
        display: "block",
        minWidth: "200px",
    },
    headingAction: {
        display: "flex",
        alignItems: "center",
    },
    headingBCSoLuotSelection: {
        display: "inline-flex",
        width: "24em",
        maxWidth: "24em",
    },
    icon: props => ({
        color: props.color,
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
}));

const BCSoLuotData = ({environment, module}) => {
    const {iconTheme} = useContext(IconThemeContext);
    const classes = useStyles(iconTheme);
    const [t, i18n] = useTranslation('common');

    const apiToken = environment.accessToken;
    const {
        action,
        dataGrid: { columns, query, queryView, records },
        viewData,
        rowsPerPage,
        clientFilters,
        refreshData
    } = module;

    const { filter } = query;
    const { startDate, endDate, type } = filter;
    const [translatedColumns, setTranslatedColumns] = useState(columns);
    
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);
    const [tongLuotTraTruoc, setTongLuotTraTruoc] = useState(0);
    const [tongTLCar, setTongTLCar] = useState(0);
    const [tongTLMotorbike, setTongTLMotorbike] = useState(0);
    const [tongTLBicycle, setTongTLBicycle] = useState(0);
    const [tongCong, setTongCong] = useState(0);

    const [recordLoading, setRecordLoading] = useState(false);

    useEffect(() => {
        console.log('useEffect has been called!');
        // Lấy đầu ngày
        const startDate = moment().startOf("month").format('YYYY-MM-DD');

        // Lấy cuối ngày
        const endDate = moment().endOf("month").format('YYYY-MM-DD');
        dispatch(setFilter({startDate, endDate}));
        setRecordLoading(true);
    }, []);

    useEffect(() => {
        const columnsTmp = columns.map(item => {
            if(item.translateId){
                return { ...item, name: t(item.translateId)};
            }else{
                return { ...item };
            }
        });
        setTranslatedColumns(columnsTmp);
    }, [i18n.language]);

    useEffect(() => {
        const loadRecords = () => {
            setLoading(true);
            loadBCSoLuotRecordsQuery(query.filter, apiToken).then(response => {
                dispatch(setDataGrid({
                    records: response.data.records,
                }));
            }).catch(err => {
                setError(err.response ? 'Code ' + err.response.data.code + ": " + err.response.data.message : err.message);
            }).finally(() => {
                setLoading(false);
            });
        }
        if(refreshData){
            console.log('useEffect has been called! refreshData');
            loadRecords();
        }
    }, [refreshData, apiToken]);


    useEffect(() => {
        const loadRecords = () => {
            setLoading(true);
            loadBCSoLuotRecordsQuery(query.filter, apiToken).then(response => {
                dispatch(setDataGrid({
                    records: response.data.records,
                }));
            }).catch(err => {
                setError(err.response ? 'Code ' + err.response.data.code + ": " + err.response.data.message : err.message);
            }).finally(() => {
                setLoading(false);
            });
        }
        if (recordLoading) {
            loadRecords();
            setRecordLoading(false);
        }
    }, [recordLoading, apiToken])

    useEffect(() => {
        if(records == null || records.length == 0){
            setTongLuotTraTruoc(0);
            setTongTLCar(0);
            setTongTLMotorbike(0);
            setTongTLBicycle(0);
            setTongCong(0);
        }else{
            var tongTT = 0;
            var tongTLC = 0;
            var tongTLM = 0;
            var tongTLB = 0;
            var tongLuot = 0;
            records.map(item=>{
                tongTT += item.tongLuotTraTruoc;
                tongTLC += item.tongLuotTLCar;
                tongTLM += item.tongLuotTLMotorbike;
                tongTLB += item.tongLuotTLBicycle;
                tongLuot += item.tong;
            })
            
            setTongLuotTraTruoc(new Intl.NumberFormat('en-US').format(tongTT));
            setTongTLCar(new Intl.NumberFormat('en-US').format(tongTLC));
            setTongTLMotorbike(new Intl.NumberFormat('en-US').format(tongTLM));
            setTongTLBicycle(new Intl.NumberFormat('en-US').format(tongTLB));
            setTongCong(new Intl.NumberFormat('en-US').format(tongLuot));
        }
    }, [records])

    const showLoading = () => {
        if(loading) return (
            <>
                <LoadingSpinner/>
            </>
        )
    }

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed}/>

    const showInfoMessage = () =>
        info && <SHMessage message={info} severity={'success'} onMessageClosed={handleMessageClosed}/>

    const handleMessageClosed = () => {
        setError(null);
        setInfo(null);
    }

    const handleSearch = () => {
        dispatch(setRefreshData(true));
    }

    const handleLoadAllRecords = () => {
    }

    const handleRowSelected = (newRowSelectionModel) => {
    }

    const showSearchCondition = () => 
        <Grid container
                direction="row"
                justifyContent="flex-end"
                justify="flex-end"
                alignItems="center"
                spacing={1}
                className={classes.buttonsGroup}>
            <Grid item xs={2}>
                <TextField
                        margin="normal"
                        fullWidth
                        name="startDate"
                        value={startDate}
                        onChange={(e) => dispatch(setFilterStartDate(e.target.value))}
                        label={ t('common:misc.startDate') }
                        type="date"
                        id="startDate"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                        margin="normal"
                        fullWidth
                        name="endDate"
                        value={endDate}
                        onChange={(e) => dispatch(setFilterEndDate(e.target.value))}
                        label={ t('common:misc.endDate') }
                        type="date"
                        id="endDate"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl
                    margin="normal"
                    fullWidth
                >
                    <InputLabel id="type-label">{ t('common:car_parking.type') }</InputLabel>
                    <Select
                        labelId="type-label"
                        id="type-helper"
                        value={type}
                        label={ t('common:car_parking.type') }
                        loading={true}
                        onChange={(e) => dispatch(setFilterType(e.target.value))}
                    >
                        <MenuItem value="">
                            All
                        </MenuItem>
                        <MenuItem value='1'>{t('common:misc.xeDangTrongBai')}</MenuItem>
                        <MenuItem value='2'>{t('common:misc.xeDaRoiBai')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={handleSearch}>
                    <SearchIcon></SearchIcon>
                </Button>
            </Grid>
            <Grid item xs={4}>
                &nbsp;
            </Grid>
        </Grid>

    const showActivityAction = () =>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        {columns.map((col) => (
                            <TableCell align={col.align?col.align:"left"}>{t(col.translateId)}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((row) => (
                        <TableRow>
                            {columns.map((col) => {
                                if(col.type === 'INTEGER'){
                                    return <TableCell align={col.align?col.align:"left"}>{new Intl.NumberFormat('en-US').format(row[col.id])}</TableCell>
                                }else if(col.type === 'DATE'){
                                    return <TableCell align={col.align?col.align:"left"}>{new Intl.NumberFormat('en-US').format(row[col.id])}</TableCell>
                                }else{
                                    return <TableCell align={col.align?col.align:"left"}>{row[col.id]}</TableCell>
                                }
                            })}
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell align="right">{t('common:car_parking.cong')}</TableCell>
                        <TableCell align="right">{tongLuotTraTruoc}</TableCell>
                        <TableCell align="right">{tongTLCar}</TableCell>
                        <TableCell align="right">{tongTLMotorbike}</TableCell>
                        <TableCell align="right">{tongTLBicycle}</TableCell>
                        <TableCell align="right">{tongCong}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    return (
        <div className={classes.root}>
            {showLoading()}
            {showErrorMessage()}
            {showInfoMessage()}
            {showSearchCondition()}
            {showActivityAction()}
        </div>
    )
}

export default BCSoLuotData;