export const CONST_STATE_DISCONNECT = -1;
export const CONST_STATE_CONNECTING = 0;
export const CONST_STATE_CONNECTED = 1;

export const CONST_CMD_STOP_CHARGE = 1;
export const CONST_CMD_CHARGE = 2;
export const CONST_CMD_REQUEST_INFO = 3;
export const CONST_CMD_REQUEST_INFO_RESPONSE = 4;
export const CONST_CMD_SETTING_CHANGE = 5;
export const CONST_CMD_STOP_CHARGE_RESPONSE = 6;
export const CONST_CMD_CHECK_BAT_CHANGE = 7;
export const CONST_CMD_CHECK_BAT_CHANGE_RESPONSE = 8;
export const CONST_CMD_BTN_BAT_DEN = 9;
export const CONST_CMD_BTN_BAT_DEN_RESPONSE = 10;
export const CONST_CMD_BTN_TAT_DEN = 11;
export const CONST_CMD_BTN_TAT_DEN_RESPONSE = 12;
export const CONST_CMD_DEVICE_BTN_PUSH = 13;
export const CONST_CMD_TURN_ON_RELAY = 14;
export const CONST_CMD_TURN_ON_RELAY_RESPONSE = 15;
export const CONST_CMD_TURN_OFF_RELAY = 16;
export const CONST_CMD_TURN_OFF_RELAY_RESPONSE = 17;
export const CONST_CMD_REQUEST_REGISTER_INFO = 18;
export const CONST_CMD_CHARGER_LOG = 19;
export const CONST_CMD_DEVICE_RESTART_AP_MODE = 20;
export const CONST_CMD_CHARGER_LOG_ID_RESPONSE = 21;
export const CONST_CMD_FIRMWARE_UPDATE = 22;
export const CONST_CMD_BROADCAST_IP = 23;

export const CONST_RELAY_STATE_WAITING = 2;
export const CONST_RELAY_STATE_ON = 1;
export const CONST_RELAY_STATE_OFF = 0;

export const FIRMWARE_VERSION = '1.0.17';
export const FIRMWARE_VERSION_ESP32 = 'ESP32.1.0.17';


//export const MQTT_HOST = "ws://gmcom.asia:8888";
export const MQTT_HOST = "wss://gmcom.asia:8888";


export const FIELD_NAME_START =              "S";
export const FIELD_NAME_END =                "E";
export const FIELD_NAME_DONG_DIEN =          "V";
export const FIELD_NAME_CUONG_DO =           "A";
export const FIELD_NAME_CONG_SUAT =          "P";
export const FIELD_NAME_GIA =                "G";
export const FIELD_NAME_PHONE =              "ph";
export const FIELD_NAME_ADDRESS =            "ad";
export const FIELD_NAME_DEVICE_NAME =        "DN";
export const FIELD_NAME_CONG_SUAT_TIEU_THU = "W";
export const FIELD_NAME_TIEN_SAC	=        "TS";

export const USER_TYPE_USER = "user";
export const USER_TYPE_MANAGER = "manager";
export const USER_TYPE_CUSTOMER = "customer";

export const ACCOUNT_TYPE_MANAGER = "manager";
export const ACCOUNT_TYPE_CHARGER = "charger";
export const ACCOUNT_TYPE_CAR_PARKING = "car_parking";