import React, { useCallback, useState } from 'react';
import {
    Alert,
    Box, Button,
    Container,
    CssBaseline,
    Grid, TextField,
    Typography
} from "@mui/material";
import { userResetPassword } from '../../user/userServices';
import { LoadingButton } from "@mui/lab";
import { useTranslation } from 'react-i18next';

export const SCREEN_FOGOT_PASSWORD = "screen-ForgotPassword";

export const ForgotPassword = (props) => {
    const {handleThoat} = props;
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [t] = useTranslation('common');

    const enabled = username.length > 0 && email.length > 0;

    const showError = useCallback(() =>
        error? <Alert severity="error" onClose={() => {setError(null)} }>{error}</Alert>: null
    , [error])

    const showWarning = useCallback(() =>
        warning? <Alert severity="warning" onClose={() => {setWarning(null)}}>{warning}</Alert>: null
    , [warning])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const queryString = window.location.search;
        let url = window.location.protocol + '//' + window.location.hostname;
        if(!(window.location.port == 80 || (window.location.protocol == 433 && window.location.port == 'https:'))){
            url+= ':' + window.location.port;
        }
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');
        userResetPassword({ email, username, path: url, id }).then((res) => {
            setWarning(`Password reset instructions sent to ${email}`);
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    };

    return (
        <div>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Quên mật khẩu
                    </Typography>
                    <Box mt={2} >
                        {showError()}
                        {showWarning()}
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            fullWidth
                            margin="normal"
                            id="username"
                            label= "Username"
                            name="username"
                            autoComplete="username"
                            required
                            autoFocus
                        />
                        <TextField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                            id="email"
                            label= "Email"
                            name="email"
                            autoComplete="email"
                            type="email"
                            required
                            autoFocus
                        />

                        <Grid container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}>
                            <Grid item xs={6}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={loading}
                                    color="inherit"
                                    disabled={!enabled}
                                    fullWidth>
                                    Đặt lại mật khẩu
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={6}>
                                <Button id="clearButton"
                                        variant="contained"
                                        color="inherit"
                                        onClick={handleThoat}
                                        fullWidth>
                                    Thoát
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </Box>
                </Box>
            </Container>
        </div>
    );
};
