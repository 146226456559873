import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@MonthlyParkingStatus', type: 'EQ'}
        ]
    }
}


const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Mã GX', id: "ma_gui_xe", hidden:true},
            {name: 'Mã thẻ', translateId: 'common:car_parking.monthly.maThe', id: "ma_the"},
            {name: 'Ma loại thẻ', id: "loai_the" , hidden:true},
            {name: 'Loại thẻ', translateId: 'common:car_parking.monthly.loaiThe', id: "ten_loai_the"},
            {name: 'Biển số', translateId: 'common:car_parking.monthly.bienSo', id: "bien_so"},
            {name: 'Tên chủ thẻ', translateId: 'common:car_parking.monthly.tenChuThe', id: "ten_chu_the"},
            {name: 'Điện thoại', translateId: 'common:misc.phone', id: "dien_thoai"},
            {name: 'Từ ngày', translateId: 'common:car_parking.monthly.tuNgay', id: "tu_ngay", type:"DATE", format:{date:"DD/MM/YYYY"}},
            {name: 'Đến ngày', translateId: 'common:car_parking.monthly.denNgay', id: "den_ngay", type:"DATE", format:{date:"DD/MM/YYYY"}},
            {name: 'Giá tiền', translateId: 'common:car_parking.monthly.giaTien', id: "gia_tien", type: "INTEGER"},
            {name: 'Hình', translateId: 'common:car_parking.monthly.hinh1', id: "hinh1", type: "image"},
            /*{name: 'Hình 2', translateId: 'common:car_parking.monthly.hinh2', id: "hinh2", type: "image"},
            {name: 'Hình 3', translateId: 'common:car_parking.monthly.hinh3', id: "hinh3", type: "image"},
            {name: 'Hình 4', translateId: 'common:car_parking.monthly.hinh4', id: "hinh4", type: "image"},
            {name: 'Hình 5', translateId: 'common:car_parking.monthly.hinh5', id: "hinh5", type: "image"},*/
            {name: 'Ghi chú', translateId: 'common:misc.ghiChu', id: "ghi_chu"},
            ],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddMonthlyParking: false,
    showEditMonthlyParking: false,
    rowPerPage: 10
};
const MonthlyParkingSlice = createSlice({
    name: 'cp_the_theo_thang',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addMonthlyParking:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddMonthlyParking:(state, action) => {
            if(action.payload === false){
                return {...state, showAddMonthlyParking: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddMonthlyParking: action.payload};
            }
        },
        setShowEditMonthlyParking:(state, action) => {
            if(action.payload === false){
                return {...state, showEditMonthlyParking: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditMonthlyParking: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var monthlyParking = state.dataGrid.records.filter(item => item.ma_gui_xe === action.payload.ma_gui_xe);
            if(monthlyParking.length>0){
                return {...state, rowDataSelected: monthlyParking[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        }
    }
});

export const {
    reset,
    addMonthlyParking,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddMonthlyParking,
    setShowEditMonthlyParking,
    setRowDataSelected } = MonthlyParkingSlice.actions;
export default MonthlyParkingSlice.reducer;