import { makeStyles } from "@mui/styles";
import {
    DataGrid
} from "@mui/x-data-grid";

import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo, useRef,
    useState
} from "react";
import { IconThemeContext } from "../../../custom-components/context/IconThemesContext";
import SHMessage from "../../../custom-components/SHMessage";
import { useFirstRender } from "../../../custom-components/hooks/useFirstRender";

import {
    parsedDataGridColumns, parsedDataGridRecords, parsedDataGridColumnsHide
} from "../dataHelpers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiButton-root': {
            textTransform: 'none',
        },
        '& .MuiList': {
            backgroundColor: 'ghostwhite'
        },
        '& .MuiDataGrid-footerContainer': {
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'space-between',
            '& .MuiTablePagination-selectLabel': {
                marginTop: '1em'
            },
            '& .MuiTablePagination-displayedRows': {
                marginTop: '1em'
            }
        },
        "& .MuiDataGrid-columnHeader": {
            "& .MuiDataGrid-columnHeaderTitle": {
                lineHeight: "20px",
                fontSize: "14px"
            },
        },
        "& .MuiDataGrid-main": {
            minHeight: "200px",
        },
        "&.grid-loaded .MuiDataGrid-main": {
            minHeight: "500px",
            overflowY: "hidden",
        },
        '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto !important'
        }
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    headingLabel: {
        display: "flex",
        paddingLeft: "10px",
        minHeight: "32px",
    },
    headingAction: {
        display: "flex",
        alignItems: "center",
    },
    gridContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "10px",
        height: "100%",
    },
    gridFooterActions: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    subheader: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-root": {
            textTransform: "none",
        },
    },
    dataGridToolbar: {
        minHeight: 20,
        paddingLeft: '0px',
        paddingBottom: '8px'
    },
    icon: props => ({
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
    loadAllIcon: props => ({
        '&:hover': {
            backgroundColor: "transparent",
        },
    }),
    cancelLoadingIcon: props => ({
        '&:hover': {
            backgroundColor: "transparent",
        },
    }),
    spanLink: {
        paddingTop: "10px",
    },
    updateAlert: {
        backgroundColor: '#1a6891',
        color: '#fff',
        textAlign: 'center',
        cursor: "pointer"
    },
    titleFooter: {
        display: "flex",
        marginLeft: "10px",
    },
    loadAllFooter: {
        display: "flex",
        marginLeft: "10px",
    },
    loadAllToolbar: {
        paddingBottom: "10px",
    },
}));

const DMDataGrid = (props) => {
    const {isLoading, setActionView, environment,
    columns, records, recordsLoading, allRecordsLoading, onHandleRowSelected} = props;
    const [t, i18n] = useTranslation('common');

    const {iconTheme} = useContext(IconThemeContext);
    const classes = useStyles(iconTheme);
    const isFirstRender = useFirstRender();
    const [action, setAction] = useState(null);
    const [paramSave, setParamSave] = useState(null);
    const [gridRowIDs, setGridRowIDs] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [info, setInfo ] = useState(null);
    const [translatedColumns, setTranslatedColumns ] = useState(columns);

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const isMounted = useRef(true);

    const showInfoMessage = () =>
        info && <SHMessage message={info} severity={'info'} onMessageClosed={() => setInfo(null)}/>

    useEffect(() => {
        isMounted.current = true
        return () => (isMounted.current = false);
    },[]);

    useEffect(() => {
        setTranslatedColumns(columns);
    },[columns]);

    useEffect(() => {
        const columnsTmp = columns.map(item => {
            if(item.translateId){
                return { ...item, name: t(item.translateId)};
            }else{
                return { ...item };
            }
        });
        setTranslatedColumns(columnsTmp);
    },[i18n.language]);

    useLayoutEffect(() => {
        setActionView?.(action)
    }, [action, setActionView])

    useLayoutEffect(() => {
        // first load
        if (recordsLoading === undefined) {
            setAction(null);
        }
    }, [recordsLoading])

    useEffect(() => {
        return () => window.sessionStorage.removeItem("grid-rows-tmp")
    }, [])

    useEffect(() => {
        if(!records) return;
        const gridRowsTmpSession = window.sessionStorage.getItem("grid-rows-tmp")
        const gridRowsTmpSessionData = gridRowsTmpSession ? JSON.parse(gridRowsTmpSession) : [];
        if(gridRowsTmpSessionData.length > 0 && isFirstRender) {
            setGridRows(gridRowsTmpSessionData);
            return;
        };
        let gridRowsTmp = parsedDataGridRecords(records, translatedColumns, environment, []);
        window.sessionStorage.setItem("grid-rows-tmp", JSON.stringify(gridRowsTmp));

        const keyInGridRowsTmp = gridRowsTmp?.[0] ? Object.keys(gridRowsTmp[0]) : [];
        window.sessionStorage.setItem("full_columns_keys", JSON.stringify(keyInGridRowsTmp));
        setGridRows(gridRowsTmp);

        let gridRowIDsTmp = [];
        for(var i=0;i<gridRowsTmp.length;i++){
            gridRowIDsTmp.push(gridRowsTmp[i].id);
        }
        setGridRowIDs(gridRowIDsTmp);
    }, [records, translatedColumns, environment, isFirstRender])

    useEffect(() => {
        if (!gridRowIDs || !gridRowIDs.length || !gridRows || !gridRows.length) return;
        for(var i=0;i<gridRows.length;i++){
            if(gridRowIDs[i] !== undefined){
                gridRows[i]["@displayId"] = gridRowIDs[i];
            }
        }

        setGridRows(gridRows);
    }, [gridRowIDs, gridRows])

    const gridColumns = useMemo(() => {
        if (!translatedColumns) return [];
        if (!translatedColumns.length) return [];
        return parsedDataGridColumns(environment, records, translatedColumns);
    }, [translatedColumns, environment, records, i18n])

    const gridColumnsHide = useMemo(() => {
        if (!translatedColumns) return [];
        if (!translatedColumns.length) return [];
        return parsedDataGridColumnsHide(environment, records, translatedColumns);
    }, [translatedColumns, environment, records, i18n])

    const renderGrid = () => {
        if (gridColumns.length === 0) return null;
        if(paramSave !== null && paramSave !== undefined){
            setParamSave(null);
            return;
        }

        return (
            <DataGrid
                 sx={{
                    height: "auto !important",
                }}
                className={`${classes.root} ${gridRows.length > 0 ? "grid-loaded" : ""}`}
                columns={gridColumns}
                columnVisibilityModel = { gridColumnsHide }
                onError={() => {console.log('error')}}
                disableColumnReorder={(isLoading || allRecordsLoading) ? true : false}
                rows={gridRows}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                    if(newRowSelectionModel.length > 0){
                        onHandleRowSelected(gridRows[newRowSelectionModel - 1]);
                    }

                }}
                rowSelectionModel={rowSelectionModel}
                loading={recordsLoading}/>
        )
    }

    return (
        <>
            {showInfoMessage(info)}
            {
                <>
                    <div className={classes.gridContainer}>
                        {renderGrid()}
                    </div>
                </>
            }
        </>
    )
}

export default DMDataGrid;