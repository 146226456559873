import axios from "axios";
import { API } from "./../../config";

export const userSignIn = async (user) =>  {

    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }

    return await axios.post(`${API}/api/auth/login`, user, config)
}

export const accountRecovering = async (val) =>  {

    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }
    const bodyParameters = {
        username: val,
    };

    return await axios.post(`/credentials/recover`,
        bodyParameters,
        config
    )
}

export const signin = user => {
    return fetch(`/tokens/user`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
};

export const signout = next => {
    if (typeof window !== "undefined") {
        sessionStorage.removeItem("jwt");
    }
    next();
};

export const userResetPassword = async (data) =>  {

    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }

    return await axios.post(`${API}/api/auth/forgotPassword`, data, config)
}

export const userSetNewPassword = async (data) =>  {

    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }

    return await axios.post(`${API}/api/auth/assignNewPassword`, data, config)
}

export const userVerifyResetPassword = async (data) =>  {

    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }

    return await axios.post(`${API}/api/auth/verifyResetPassword`, data, config)
}
