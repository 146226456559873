import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { CoreProvider } from "../custom-components/context/CoreContext";
import LoadingSpinner from "../custom-components/LoadingSpinner";
import SkeletonLoaderApp from "../custom-components/skeletons/SkeletonLoaderApp";
import DMRoutes from "../DMRoutes";
import NavLayout from "./core/Layouts/NavLayout";
import NavTabs from "./core/Layouts/NavTabs";
import Signout from "./user/Signout";

const AppFullScreen = ({isAuthenticated}) => {
    const initialState = {
        account: null,
        theme: null,
        userCurrent: null,
        translations: [],
        isSignedIn: false,
        error: null,
        loading: true,
        loaded: false
    }

    const [values, setValues] = useState(initialState);
    const {loading, loaded} = values;
    const [signOutOpen, setSignOutOpen] = useState(false);

    const showLoading = () =>
        loading && <>
            <LoadingSpinner/>
            <SkeletonLoaderApp/>
        </>

    const loadCoreData = async () => {
        setValues({...values, loading: false, loaded: true});
    }
    useEffect(() => {
        if (!isAuthenticated) return;
        loadCoreData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <>
            {showLoading()}
            {loaded && <CoreProvider values={values}>
                <NavLayout/>
                <BrowserRouter>
                    <NavTabs/>
                    <DMRoutes />
                </BrowserRouter>
            </CoreProvider>}
            {signOutOpen && <Signout open={signOutOpen} theme={values.theme} translations={values.translations} source="timeout" onClose={() => setSignOutOpen(false)} />}
        </>
    )
}

export default AppFullScreen;