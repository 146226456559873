import { combineReducers } from 'redux';
import devicesReducer from "../slices/DevicesSlice";
import environmentReducer from "../slices/EnvironmentSlice";
import usersReducer from "../slices/UsersSlice";
import hkdsReducer from "../slices/HKDsSlice";
import statisticReducer from "../slices/StatisticSlice";
import tramsacsReducer from "../slices/TramSacsSlice";
import topUpsReducer from "../slices/TopUpSlice";
import chargerhistoriesReducer from "../slices/ChargerHistorySlice";
import dashboardReducer from "../slices/DashboardSlice";
import blockReducer from "../slices/car_parking/BlockSlice";
import vehicleReducer from "../slices/car_parking/VehicleSlice";
import monthlyParkingReducer from "../slices/car_parking/MonthlyParkingSlice";
import cpMonthlyReducer from "../slices/car_parking/CPMonthlySlice";
import parkingInTurnReducer from "../slices/car_parking/ParkingInTurnSlice";
import hauKiemReducer from "../slices/car_parking/HauKiemSlice";
import bcDoanhThuReducer from "../slices/car_parking/BCDoanhThuSlice";
import bcSoLuotReducer from "../slices/car_parking/BCSoLuotSlice";
import bcTKBaoVeReducer from "../slices/car_parking/BCTKBaoVeSlice";
import cpDashboardReducer from "../slices/car_parking/CPDashboardSlice";
import dsLoaiXeReducer from "../slices/car_parking/DSLoaiXeSlice";

export default combineReducers({
    environment: environmentReducer,
    devices: devicesReducer,
    users: usersReducer,
    hkds: hkdsReducer,
    statistic: statisticReducer,
    tramsacs: tramsacsReducer,
    topups: topUpsReducer,
    chargerhistories: chargerhistoriesReducer,
    dashboard: dashboardReducer,
    vehicles: vehicleReducer,
    blocks: blockReducer,
    monthlyParkings: monthlyParkingReducer,
    cpMonthlys: cpMonthlyReducer,
    parkingInTurns: parkingInTurnReducer,
    hauKiem: hauKiemReducer,
    bcDoanhThu: bcDoanhThuReducer,
    bcSoLuot: bcSoLuotReducer,
    bcTKBaoVe: bcTKBaoVeReducer,
    cpDashboard: cpDashboardReducer,
    dsLoaiXe: dsLoaiXeReducer
})