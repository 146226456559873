
export const authenticate = (data) => {
    if (typeof window !== "undefined") {
        sessionStorage.setItem("jwt", JSON.stringify(data));
    }
};

export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (sessionStorage.getItem("jwt")) {
        return JSON.parse(sessionStorage.getItem("jwt"));
    } else {
        return false;
    }
};

export const isCoreLoaded = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (sessionStorage.getItem("coreLoaded")) {
        return JSON.parse(sessionStorage.getItem("coreLoaded"));
    } else {
        return false;
    }
};
