import React from 'react';
import { Grid, Typography, RadioGroup, Radio, Button } from '@mui/material';
export const SCREEN_KH02 = "screen-KH02";
export const ScreenKH02 = ({
    show,
    curDate,
    curTime,
    tenKhachHang,
    maKhachHang,
    truSac,
    congSac,
    dienAp,
    cuongDo,
    handleGiaTienChange,
    handleXemTaiKhoan,
    handleThoat,
    handleXacNhanSac,
    tienThue,
    thoiGianThue
}) => {
    if(!show){
        return;
    }
    return (
        <Grid id="screen-KH02" className="hide-item" container spacing={2}>
            <Grid item xs={12}>
                <Typography  align="center">
                    Ngày: {curDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thời gian: {curTime}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Khách hàng: {tenKhachHang}</Typography>
                <Typography>Mã số khách hàng: {maKhachHang}</Typography>
                <Typography>
                    Trụ sạc: {truSac}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cổng sạc số: {congSac}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Điện áp: {dienAp}Vac&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dòng sạc tối đa: {cuongDo}A
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Bạn vui lòng chọn gói thanh toán:</Typography>
                <RadioGroup onChange={handleGiaTienChange}>
                    <Typography>Gói theo tiền:</Typography>
                    <div>
                        <Radio name="radioTheoGiaTien" value="10000" /> 10K (tối đa 1h)
                        <Radio name="radioTheoGiaTien" value="20000" /> 20K (tối đa 2h)
                        <Radio name="radioTheoGiaTien" value="30000" /> 30K (tối đa 3h)
                        <Radio name="radioTheoGiaTien" value="40000" /> 40K (tối đa 4h)
                    </div>
                    <Typography>Gói theo giờ:</Typography>
                    <div>
                        <Radio name="radioTheoGiaTien" value="5000" /> 30 phút (5K)
                        <Radio name="radioTheoGiaTien" value="10000" /> 60 phút (10K)
                        <Radio name="radioTheoGiaTien" value="15000" /> 90 phút (15K)
                        <Radio name="radioTheoGiaTien" value="20000" /> 120 phút (20K)
                    </div>
                </RadioGroup>
            </Grid>
            <Grid item xs={12} className="tien-thue-session hide-item">
                <Typography>
                    Tổng giá tiền thuê: {tienThue} đồng, thời gian dùng tối đa là {thoiGianThue}h
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={handleXemTaiKhoan}>
                    Xem tài khoản
                </Button>&nbsp;&nbsp;
                <Button variant="contained" onClick={handleThoat}>
                    Thoát
                </Button>
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handleXacNhanSac}>
                    Bắt đầu sạc
                </Button>
            </Grid>
        </Grid>
    );
};
