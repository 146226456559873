import React from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Collapse, Grid, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
    appbar: props => ({
        background: props.backgroundColor,
        color: props.color,
        alignItems: 'center',
        display: 'grid',
        margin: 0,
        padding: 0,
        boxShadow: 'none',
        '& a' : {
            textDecoration: "none",
            color: "white"
        },
    }),
    menuButton: {
        marginRight: "16px",
    },

    title: props => ({
        //paddingTop: "20px"
    }),
    logo: props => ({
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "block",
        maxWidth: "100%",
        maxHeight: "62px",
    }),
    gridContainer : props => ({
        marginTop: '0px',
    }),
    gridItem : props => ({
        paddingTop: '0px',
    })
}));


const BannerNavLayout = () => {
    const { environment } = useSelector((state) => state);
    const navTheme = environment?.user?.theme?.navTheme || {};
    const classes = useStyles(navTheme);

	const showLogo = () =>{
        if(environment && environment.user && environment.user.logo){
            return <a href="/"><img alt="logo" src={ environment.user.logo } className={classes.logo} /></a>
        }else{
            return <a href="/"><span>Logo</span></a>
        }
    }

    const showTitle = () => {
        if(environment && environment.user && environment.user.title){
            return (
                <>
                    <Typography variant="h6" className={classes.title}>
                        {environment.user.title}
                    </Typography>
                    {environment.user.title2 && <Typography variant="h6" className={classes.title}>
                        {environment.user.title2}
                    </Typography>}
                </>
            )
        }else{
            return <span>Title</span>
        }
    }

    return (
        <AppBar id="midNavLayoutAppbar" position="static" className={classes.appbar}>
            <Collapse in={!environment.fullscreenMode}>
                <Toolbar>
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={2} sx={{ width: 64, maxHeight: 128 }}  style={{paddingTop: '0px'}}>
                            <div>{showLogo()}</div>
                        </Grid>
                        <Grid item xs={8}  style={{paddingTop: '0px'}}>
                            <div>{showTitle()}</div>
                        </Grid>
                        <Grid item xs style={{paddingTop: '0px'}}>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Collapse>
        </AppBar>
    );
}

export default  BannerNavLayout;