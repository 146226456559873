import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        status: 0
    }
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'STT', translateId: 'common:misc.stt', id: "stt"},
            {name: 'Mã Login', translateId: 'common:car_parking.maLogin', id: "login"},
            {name: 'Tên bảo vệ', translateId: 'common:car_parking.tenBaoVe', id: "name"},
            {name: 'Mật mã', translateId: 'common:misc.password', id: "mat_ma"},
            {name: 'Thông tin bảo vệ', translateId: 'common:car_parking.ttBaoVe', id: "ttbv"},
            {name: 'Trạng thái', translateId: 'common:misc.status', id: "status"}
            ],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    rowPerPage: 10
};

const BCTKBaoVeSlice = createSlice({
    name: 'BCTKBaoVe',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setFilterStatus:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, status: action.payload}}}};
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, data: {}};
        }
    }
});

export const {
    reset,
    setDataGrid,
    setRefreshData,
    setFilterStatus} = BCTKBaoVeSlice.actions;
export default BCTKBaoVeSlice.reducer;