
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as uuid from "uuid";
import { API } from "../../../../config";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "../../../data/dataHelpers";
import SHMessage from "../../../../custom-components/SHMessage";


import { base64Decode, getStoreData } from "../../../core/utils/utils";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from "moment";
import { SCREEN_LOGIN, ScreenLogin } from "./ScreenLogin";
import { SCREEN_CAP, ScreenCAP } from "./ScreenCAP";

import { generateShortUuid } from "../../../core/utils/utils";
import { CoreProvider } from "../../../../custom-components/context/CoreContext"; 
import {SCREEN_FOGOT_PASSWORD, ForgotPassword} from "./ForgotPassword";

import { userSignIn } from "../../../data/car_parking/cap/capServices";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root': {
            textDecoration: "none"
        }
    },
    dataGridToolbar: {
        minHeight: 20,
        paddingLeft: '0px',
        paddingBottom: '8px'
    },
    dialog: {
        '& .MuiButton-root': {
            textTransform: "none !important",
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
    },
    headingContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
    },
    headingLabel: {
        display: "block",
        minWidth: "200px",
    },
    headingAction: {
        display: "flex",
        alignItems: "center",
    },
    headingProjectSelection: {
        display: "inline-flex",
        width: "24em",
        maxWidth: "24em",
    },
    icon: props => ({
        color: props.color,
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
    formControl: {
        width: "100%",
        margin: "8px 0px !important",
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    formControlArray: {
        width: "100%",
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    alignRight: {
        textAlign: "right"
    },
    cardHeader: {
        border: "1px solid gray",
        backgroundColor: "lightgray",
        height: "40px",
        fontSize: "20px",
        padding: "10px",
        borderBottom: "none",
        display: "flex",
        alignItems: "center"
    },
    cardBody: {
        border: "1px solid gray",
        padding: "10px 20px",
    },
    cardFooter: {
        borderTop: "none",
        border: "1px solid gray",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    arrayItemRow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .ColorPicker-MuiButtonBase-root .ColorPicker-MuiButton-label div': {
            border: '1px solid black !important'
        }
    },
    customValueBox: {
        width: 175,
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },
    dateField: {
        marginLeft: "0px"
    },
    odd: {
        backgroundColor: "azure",
        '& .Mui-readOnly': {
            backgroundColor: "lightgray",
        },
    },
    even: {
        backgroundColor: "white",
        '& .Mui-readOnly': {
            backgroundColor: "lightgray",
        },
    }
}));
const goiTheoGiaTienArr = [
    {
        value: 10000,
        name: '10K (tối đa 1h)'
    },
    {
        value: 20000,
        name: '20K (tối đa 2h)'
    },
    {
        value: 30000,
        name: '30K (tối đa 3h)'
    },
    {
        value: 40000,
        name: '40K (tối đa 4h)'
    }];
const goiTheoGioArr = [
    {
        value: 5000,
        name: '30 phút (5K)'
    },
    {
        value: 10000,
        name: '60 phút (10K)'
    },
    {
        value: 15000,
        name: '90 phút (15K)'
    },
    {
        value: 20000,
        name: '120 phút (20K)'
    }];



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ItemLeft = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
}));

const CAPModule = (props) => {
    const { environment, onClose } = props;
    const [t] = useTranslation('common');

    const { accessToken } = environment;
    const [values, setValues] = useState({username: 'Bv001',
    password: 'abc123',
    account: 'car_parking'
    });
    const {
        username,
        password,
        account
        } = values;


    const [user, setUser] = useState({});
    const [showScreen, setShowScreen] = useState(SCREEN_LOGIN);
    const [showScreenBack, setShowScreenBack] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />

    const handleMessageClosed = () => {
        setError(null);
    }

    const setValue = (name, value) => {
        setValues({
            ...values,
            [name]: value
        });
    }

    const handleChange = (event) => {
        setValue(event.target.name, event.target.value);
    }

    const handleCheckChange = (event) => {
        setValue(event.target.name, event.target.checked);
    }

    useEffect(() => {

    }, [])

    const handleClose = () => {
        onClose();
    }

    const isShowScreen = (screenId) => {
        if (showScreen == screenId) {
            return true;
        }
        return false;
    }

    const handleDangNhap = () => {
        setLoading(true);
        userSignIn({ username, password, account, usertype: "cap" }).then((res) => {
            if (res.data.user.length === 0) {
                setError(t('common:signIn.signinError'));
            } else {
                setUser(res.data.user[0]);
                setShowScreen(SCREEN_CAP);
            }
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleQuenMatKhau = () => {
        setShowScreen(SCREEN_FOGOT_PASSWORD);
    }

    const handleThoat = () => {
        setShowScreen(SCREEN_LOGIN);
    }

    return (
        <>
            <CoreProvider values={values}>
                {showErrorMessage()}
                {ScreenLogin({
                    show: isShowScreen(SCREEN_LOGIN),
                    handleChange,
                    username,
                    password,
                    account,
                    handleDangNhap,
                    handleQuenMatKhau,
                    loading
                })}
                {ScreenCAP({
                    show: isShowScreen(SCREEN_CAP),
                    handleChange
                })}
                {isShowScreen(SCREEN_FOGOT_PASSWORD) && <ForgotPassword handleThoat={handleThoat}></ForgotPassword>}
            </CoreProvider>
        </>
    );
}

export default CAPModule;