import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        startDate: null,
        endDate: null,
        status: 1,
        maThe: ""
    }
}


const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Mã GX', id: "ma_gui_xe", hidden:true},
            {name: 'Mã thẻ', translateId: 'common:car_parking.monthly.maThe', id: "ma_the"},
            {name: 'Loại thẻ', translateId: 'common:car_parking.monthly.loaiThe', id: "loai_the"},
            {name: 'Biển số', translateId: 'common:car_parking.monthly.bienSo', id: "bien_so"},
            {name: 'Tên chủ thẻ', translateId: 'common:car_parking.monthly.tenChuThe', id: "ten_chu_the"},
            {name: 'Điện thoại', translateId: 'common:misc.phone', id: "dien_thoai"},
            {name: 'Ngày vào', translateId: 'common:car_parking.ngayVao', id: "ngay_vao", type:"datetime", format:{date:"DD/MM/YYYY hh:mm"}},
            {name: 'Ngày ra', translateId: 'common:car_parking.ngayRa', id: "ngay_ra", type:"datetime", format:{date:"DD/MM/YYYY hh:mm"}},
            {name: 'Hình xe vào', translateId: 'common:car_parking.hinhVao', id: "hinh_vao_1", type: "image"},
            {name: 'Hình xe ra', translateId: 'common:car_parking.hinhRa', id: "hinh_ra_1", type: "image"},
            {name: 'Bảo vệ vào', translateId: 'common:car_parking.bvVao', id: "bv_vao"},
            {name: 'Bảo vệ ra', translateId: 'common:car_parking.bvRa', id: "bv_ra"},
            {name: 'Ghi chú', translateId: 'common:misc.ghiChu', id: "ghi_chu"},
            ],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showMonthlyCarIn: false,
    showMonthlyCarOut: false,
    showMonthlyCarParkingInfo: false,
    rowPerPage: 10
};
const CPMonthlySlice = createSlice({
    name: 'cp_the_theo_thang',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addCPMonthly:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, dataGrid: {...state.dataGrid, records:[]}};
        },
        setShowMonthlyCarIn:(state, action) => {
            if(action.payload === false){
                return {...state, showMonthlyCarIn: action.payload, rowDataSelected: null};
            }else{
                return {...state, showMonthlyCarIn: action.payload};
            }
        },
        setShowMonthlyCarOut:(state, action) => {
            if(action.payload === false){
                return {...state, showMonthlyCarOut: action.payload, rowDataSelected: null};
            }else{
                return {...state, showMonthlyCarOut: action.payload};
            }
        },
        setShowMonthlyCarParkingInfo:(state, action) => {
            if(action.payload === false){
                return {...state, showMonthlyCarParkingInfo: action.payload, rowDataSelected: null};
            }else{
                return {...state, showMonthlyCarParkingInfo: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var cpMonthly = state.dataGrid.records.filter(item => item.ma_gui_xe === action.payload.ma_gui_xe);
            if(cpMonthly.length>0){
                return {...state, rowDataSelected: cpMonthly[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        },
        setFilterStartDate:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, startDate: action.payload}}}};
        },
        setFilterEndDate:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, endDate: action.payload}}}};
        },
        setFilterStatus:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, status: action.payload}}}};
        },
        setFilterMaThe:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, maThe: action.payload}}}};
        },
        setFilter:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, startDate: action.payload.startDate, endDate: action.payload.endDate}}}};
        }
    }
});

export const {
    reset,
    addCPMonthly,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowMonthlyCarIn,
    setShowMonthlyCarOut,
    setShowMonthlyCarParkingInfo,
    setRowDataSelected,
    setFilterStartDate,
    setFilterEndDate,
    setFilterStatus,
    setFilterMaThe,
    setFilter
 } = CPMonthlySlice.actions;
export default CPMonthlySlice.reducer;