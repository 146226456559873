import moment from "moment";
import _ from "lodash";

export const computeDateValue = (preset) => {
    let now = moment();
    let values = [];
    switch (preset) {
        case 'ALL':
        case 'EMPTY':
        case 'NOTEMPTY':
            values = [
                null
            ];
            break;

        case 'TODAY':
            values = [
                now.clone().startOf('day')
            ];
            break;

        case 'YESTERDAY':
            values = [
                now.clone().subtract(1, 'day')
            ];
            break;
        case 'TOMORROW':
            values = [
                now.clone().add(1, 'day')
            ];
            break;
        case 'THISWEEK':
            values = [
                now.clone().startOf('week'),
                now.clone().endOf('week')
            ];
            break;

        case 'THISMONTH':
            values = [
                now.clone().startOf('month'),
                now.clone().endOf('month')
            ];
            break;
        case 'LASTWEEK':
            values = [
                now.clone().subtract(1, 'week').startOf('week'),
                now.clone().subtract(1, 'week').endOf('week')
            ];
            break;

        case 'LASTMONTH':
            values = [
                now.clone().subtract(1, 'month').startOf('month'),
                now.clone().subtract(1, 'month').endOf('month')
            ];
            break;

        case 'LAST7DAYS':
            values = [
                now.clone().subtract(6, 'days'),
                now.clone()
            ];
            break;

        case 'LAST30DAYS':
            values = [
                now.clone().subtract(29, 'days'),
                now.clone()
            ];
            break;
        case 'NEXTWEEK':
            values = [
                now.clone().add(1, 'week').startOf('week'),
                now.clone().add(1, 'week').endOf('week')
            ];
            break;

        case 'NEXTMONTH':
            values = [
                now.clone().add(1, 'month').startOf('month'),
                now.clone().add(1, 'month').endOf('month')
            ];
            break;

        case 'NEXT7DAYS':
            values = [
                now.clone().add(1, 'day'),
                now.clone().add(7, 'days')
            ];
            break;

        case 'NEXT30DAYS':
            values = [
                now.clone().add(1, 'day'),
                now.clone().add(29, 'days')
            ];
            break;


        default:
            break;

    }
    return values;
}

export const compareDates = (dateA, dateB) => {
    let valueA = moment(dateA).valueOf() || 0;
    let valueB = moment(dateB).valueOf() || 0;
    return valueA - valueB;
}

export const formatDate = (date, offset, format) => {
    if(date === null || date === ""){
        return "";
    }
    let newDate = new Date(date);

    let m = moment(newDate);
    let formatUse = 'M/D/YYYY h:mm a';
    if (format) {
        // if (format.hasOwnProperty('date')) {
        if (_.has(format, 'date')) {
            formatUse = format.date;
        } else {
            formatUse = format;
        }
    }
    if (offset != null) m.utcOffset(offset);
    return m.isValid() ? m.format(formatUse) : '';
}

export const parseAs = (date, offset)  => {
    if (!(typeof date === 'string' || date instanceof Date || moment.isMoment(date))) {
        throw new Error('invalid argument: date must be of type string, Date or moment');
    }

    offset = offset || 0;
    let offHrs = Math.trunc(Math.abs(offset/60));
    let offMins = Math.abs(offset) % 60;

    let parsed = moment(date);
    if (!parsed.isValid()) throw new Error('invalid argument: date produces invalid moment');

    let formatted = parsed.format('YYYY-MM-DDTHH:mm:ss')
        + (offset < 0 ? '-' : '+')
        + (offHrs < 10 ? '0' : '') + offHrs.toString() + ':'
        + (offMins < 10 ? '0' : '') + offMins.toString();

    let result = moment.parseZone(formatted, moment.ISO_8601);
    return result;
}

export const computeDateRange = (date, offset) => {
    let begin;
    let end;

    if (Array.isArray(date)) {
        begin = parseAs(date[0], offset).startOf('day');
        end = parseAs(date[1], offset).startOf('day').add(1, 'day');
        if (begin.isAfter(end)) throw new Error('invalid argument: date[0] must come before date[1]');
    } else {
        begin = parseAs(date, offset).startOf('day');
        end = begin.clone().add(1, 'day');
    }

    return [begin.toISOString(true), end.toISOString(true)];
}

export const computeDateBetween = (date, offset) => {
    let begin;
    let end;

    if (Array.isArray(date)) {
        begin = date[0] ? parseAs(date[0], offset).startOf('day').toDate() : null;
        end = date[1] ? parseAs(date[1], offset).startOf('day').subtract(1, 'day').toDate() : null;
    }

    return [begin, end];
}