import axios from "axios";
import { apiHeaderX } from "../../dataServices";
import { API } from "./../../../../config";

export const loadBCSoLuotRecordsQuery = async ( filter, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    const { startDate, endDate, type } = filter;
    
    return await axios.post(`${API}/api/car_parking/bcSoLuot/query`,
        {tu_ngay: startDate, den_ngay: endDate, type: type},
        config
    )
}