import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        startDate: null,
        endDate: null,
        type: 0,
    }
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Ngày', translateId: 'common:misc.ngay', id: "ngay_tao"},
            {name: 'Thẻ trả tiền trước', translateId: 'common:car_parking.theTraTruoc', id: "tongLuotTraTruoc", type:"INTEGER", align: "right"},
            {name: 'Thẻ lượt ô tô', translateId: 'common:car_parking.theLuotOTo', id: "tongLuotTLCar", type:"INTEGER", align: "right"},
            {name: 'Thẻ lượt xe máy', translateId: 'common:car_parking.theLuotXeMay', id: "tongLuotTLMotorbike", type:"INTEGER", align: "right"},
            {name: 'Thẻ lượt xe đạp', translateId: 'common:car_parking.theLuotXeDap', id: "tongLuotTLBicycle", type:"INTEGER", align: "right"},
            {name: 'Cộng', translateId: 'common:car_parking.cong', id: "tong", type:"INTEGER", align: "right"}
            ],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    rowPerPage: 10
};

const BCSoLuotSlice = createSlice({
    name: 'BCSoLuot',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setFilter:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, startDate: action.payload.startDate, endDate: action.payload.endDate}}}};
        },
        setFilterStartDate:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, startDate: action.payload}}}};
        },
        setFilterEndDate:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, endDate: action.payload}}}};
        },
        setFilterType:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, type: action.payload}}}};
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, data: {}};
        }
    }
});

export const {
    reset,
    setDataGrid,
    setRefreshData,
    setFilter,
    setFilterStartDate,
    setFilterEndDate,
    setFilterType} = BCSoLuotSlice.actions;
export default BCSoLuotSlice.reducer;