import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@BlockStatus', type: 'EQ'}
        ]
    }
}

const defaultColumns = [
    {name: 'Mã block', translateId: 'common:car_parking.block.maBlock', id: "ma_block", hidden:true},
    {name: 'Tên block', translateId: 'common:car_parking.block.tenBlock', id: "ten_block"},
    {name: 'Số giờ', translateId: 'common:car_parking.block.soGio', id: "so_gio"},
    {name: 'Loại', translateId: 'common:misc.status', id: "status", hidden:true},
]

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: defaultColumns,
        customColumns: [],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddBlock: false,
    showEditBlock: false,
    rowPerPage: 10
};
const BlockSlice = createSlice({
    name: 'cp_block',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addBlock:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            var cols = [];
            if(action.payload.customColumns != null && action.payload.customColumns.length>0){
                for(var i=0; i < action.payload.customColumns.length; i++){
                    var item = action.payload.customColumns[i];
                    cols.push({
                        name: item.ma_loai_xe + " id",
                        id: "id_block_" + item.ma_loai_xe,
                        hidden:true,
                        parent: item.ma_loai_xe
                    });
                    cols.push({
                        name: item.ten_loai_xe,
                        id: item.ma_loai_xe,
                        type: 'INTEGER',
                        parent: item.ma_loai_xe
                    });
                }
            }
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records], columns:[...defaultColumns, ...cols], customColumns: [...cols]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddBlock:(state, action) => {
            if(action.payload === false){
                return {...state, showAddBlock: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddBlock: action.payload};
            }
        },
        setShowEditBlock:(state, action) => {
            if(action.payload === false){
                return {...state, showEditBlock: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditBlock: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var block = state.dataGrid.records.filter(item => item.ma_block === action.payload.ma_block);
            if(block.length>0){
                return {...state, rowDataSelected: block[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        }
    }
});

export const {
    reset,
    addBlock,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddBlock,
    setShowEditBlock,
    setRowDataSelected } = BlockSlice.actions;
export default BlockSlice.reducer;