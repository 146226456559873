
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconThemeContext } from "../../../../custom-components/context/IconThemesContext";
import SHMessage from "../../../../custom-components/SHMessage";

import LoadingSpinner from "../../../../custom-components/LoadingSpinner";
import SkeletonLoaderDataGrid from "../../../../custom-components/skeletons/SkeletonLoaderDataGrid";
import DMDataGrid from "../../../data/datagrid/DMDataGrid";
import { setDataGrid, setFilter, setRowDataSelected } from "../../../../slices/TopUpSlice";
import { loadTopUpRecordsQuery } from "../../../data/topUpServices"
import { useParams } from "react-router-dom";
import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    dataGridToolbar: {
        minHeight: 20,
        paddingLeft: '0px',
        paddingBottom: '8px'
    },
    dialog: {
        '& .MuiButton-root': {
            textTransform: "none !important",
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
    },
    headingContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
    },
    headingLabel: {
        display: "block",
        minWidth: "200px",
    },
    headingAction: {
        display: "flex",
        alignItems: "center",
    },
    headingTopUpSelection: {
        display: "inline-flex",
        width: "24em",
        maxWidth: "24em",
    },
    icon: props => ({
        color: props.color,
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
}));

const TopUpData = ({environment, module}) => {
    const {iconTheme} = useContext(IconThemeContext);
    const classes = useStyles(iconTheme);
    const [t, i18n] = useTranslation('common');
    const { id } = useParams();

    const apiToken = environment.accessToken;
    const {
        action,
        dataGrid: { userColumns, columns, query, queryView, user, records },
        topUpRefresh,
        topUpSelected,
        viewData,
        rowsPerPage,
        clientFilters,
        refreshData
    } = module;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);
    const [translatedUserColumns, setTranslatedUserColumns] = useState(userColumns);

    const [recordLoading, setRecordLoading] = useState(false);

    useEffect(() => {
        console.log('useEffect has been called!');
        dispatch(setFilter(id));
    }, []);

    useEffect(() => {
        const columnsTmp = userColumns.map(item => {
            if(item.translateId){
                return { ...item, name: t(item.translateId)};
            }else{
                return { ...item };
            }
        });
        setTranslatedUserColumns(columnsTmp);
    }, [i18n.language]);

    useEffect(() => {
        const loadRecords = () => {
            setLoading(true);
            loadTopUpRecordsQuery(query.filter, apiToken).then(response => {
                dispatch(setDataGrid({
                        user: response.data.user,
                        records: response.data.topups,
                    }));
            }).catch(err => {
                setError(err.response ? 'Code ' + err.response.data.code + ": " + err.response.data.message : err.message);
            }).finally(() => {
                setLoading(false);
            });
        }
        if(refreshData){
            console.log('useEffect has been called! refreshData');
            loadRecords();
        }
    }, [refreshData, apiToken, dispatch, query.filter]);

    const showLoading = () => {
        if(loading) return (
            <>
                <LoadingSpinner/>
                {_.isEmpty(viewData) && <SkeletonLoaderDataGrid/>}
            </>
        )
    }

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed}/>

    const showInfoMessage = () =>
        info && <SHMessage message={info} severity={'success'} onMessageClosed={handleMessageClosed}/>

    const handleMessageClosed = () => {
        setError(null);
        setInfo(null);
    }


    const handleLoadAllRecords = () => {
    }

    const handleRowSelected = (newRowSelectionModel) => {
        dispatch(setRowDataSelected(newRowSelectionModel));
    }

    const showActivityAction = () =>
        <>
            <Grid container spacing={0}>
                <Grid md={4}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="userName"
                        value={user.name}
                        label={ translatedUserColumns[0].name }
                        type="text"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid md={4}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="email"
                        value={user.email}
                        label={ translatedUserColumns[1].name }
                        type="text"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid md={4}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="phone"
                        value={user.phone}
                        label={ translatedUserColumns[2].name }
                        type="text"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item md={12}>
                 <TextField
                        margin="normal"
                        fullWidth
                        name="address"
                        value={user.address}
                        label={ translatedUserColumns[3].name }
                        type="text"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid md={4}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="tong_tien_nap"
                        value={user.tong_tien_nap}
                        label={ translatedUserColumns[4].name }
                        type="number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid md={4}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="tong_tien_su_dung"
                        value={user.tong_tien_su_dung}
                        label={ translatedUserColumns[5].name }
                        type="number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid md={4}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="tong_tien_con_lai"
                        value={user.tong_tien_con_lai}
                        label={ translatedUserColumns[6].name }
                        type="number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            <DMDataGrid action={action}
                                                  isLoading={loading}
                                                  isRefresh={topUpRefresh}
                                                  title={""}
                                                  environment={environment}
                                                  query={query}
                                                  viewData={viewData}
                                                  columns={columns}
                                                  records={records}
                                                  queryView={queryView}
                                                  clientFilters={clientFilters}
                                                  recordsLoading={false}
                                                  allRecordsLoading = {false}
                                                  topUpSelected={topUpSelected}
                                                  tab={"topUps"}
                                                  rowsPerPage={rowsPerPage}
                                                  setError={setError}
                                                  onHandledLoadAllRecords={handleLoadAllRecords}
                                                  onHandleRowSelected={handleRowSelected}/>
        </>

    return (
        <div className={classes.root}>
            {showLoading()}
            {showErrorMessage()}
            {showInfoMessage()}
            {showActivityAction()}
        </div>
    )
}

export default TopUpData;