import React from 'react';
import { Grid, Typography, Button } from '@mui/material';

export const SCREEN_KH04 = "screen-KH04";
export const ScreenKH04 = ({
    show,
    curDate,
    curTime,
    tenKhachHang,
    maKhachHang,
    truSac,
    congSac,
    tienThue,
    thoiGianThue,
    thoiGianKetThucSac,
    tongThoiGianSac,
    congSuatTieuThu,
    thanhTien,
    handleXemTaiKhoan,
    handleThoat
}) => {
    if(!show){
        return;
    }
    return (
        <Grid id="screen-KH04" className="hide-item" container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center">
                    Ngày: {curDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thời gian: {curTime}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Khách hàng: {tenKhachHang}</Typography>
                <Typography>Mã số khách hàng: {maKhachHang}</Typography>
                <Typography>
                    Trụ sạc: {truSac}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cổng sạc số: {congSac}
                </Typography>
                <Typography>
                    Gói đã chọn: {tienThue} đồng, thời gian sạc tối đa là {thoiGianThue}h
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Trạng thái sạc điện: Kết thúc sạc vào lúc {thoiGianKetThucSac}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Thời gian sạc: {tongThoiGianSac}&nbsp;&nbsp;&nbsp;&nbsp;Công suất tiêu thụ (kWh): {congSuatTieuThu}&nbsp;&nbsp;&nbsp;&nbsp;Thành tiền (đ): {thanhTien}
                </Typography>
            </Grid>
            <Grid item xs={12} className="footer-info">
                <Typography>CÁM ƠN BẠN ĐÃ SỬ DỤNG DỊCH VỤ SẠC ĐIỆN CỦA CHÚNG TÔI.</Typography>
                <Typography>HẸN SỚM GẶP LẠI BẠN!</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={handleXemTaiKhoan}>
                    Xem tài khoản
                </Button>
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handleThoat}>
                    Thoát
                </Button>
            </Grid>
        </Grid>
    );
};