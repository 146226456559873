import React, {lazy, Suspense} from "react";
import {Routes, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import LoadingSpinner from "./custom-components/LoadingSpinner";
import { ACCOUNT_TYPE_CAR_PARKING, USER_TYPE_MANAGER, USER_TYPE_USER } from "./constants";
import TopUpModule from "./components/pages/users/topup/TopUpModule";
import ChargerHistoryModule from "./components/pages/users/charger/ChargerHistoryModule";
import VehicleModule from "./components/pages/car_parking/vehicle/VehicleModule";
import BlockModule from "./components/pages/car_parking/block/BlockModule";
import MonthlyParkingModule from "./components/pages/car_parking/monthlyParking/MonthlyParkingModule";
import BCSoLuotModule from "./components/pages/car_parking/report/BCSoLuotModule";
const DevicesModule = lazy(() => import("./components/pages/devices/DevicesModule"));
const ManageSystemModule = lazy(() => import("./components/pages/manageSystem/ManageSystemModule"));
const UsersModule = lazy(() => import("./components/pages/users/UsersModule"));
const HKDsModule = lazy(() => import("./components/pages/hkd/HKDsModule"));
const StatisticModule = lazy(() => import("./components/pages/hkd/statistic/StatisticModule"));
const TramSacssModule = lazy(() => import("./components/pages/hkd/tramsacs/TramSacsModule"));
const DashboardModule = lazy(() => import("./components/pages/hkd/dashboard/DashboardModule"));
const CPMonthlyModule = lazy(() => import("./components/pages/car_parking/cpmonthly/CPMonthlyModule"));
const ParkingInTurnModule = lazy(() => import("./components/pages/car_parking/parking_in_turn/ParkingInTurnModule"));
const SettingModule = lazy(() => import("./components/pages/car_parking/SettingMocule"));
const HauKiemModule = lazy(() => import("./components/pages/car_parking/hauKiem/HauKiemModule"));
const BCDoanhThuModule = lazy(() => import("./components/pages/car_parking/report/BCDoanhThuModule"));
const BCTKBaoVeModule = lazy(() => import("./components/pages/car_parking/report/BCTKBaoVeModule"));
const CPDashboardModule = lazy(() => import("./components/pages/car_parking/dashboard/CPDashboardModule.js"));
const DSLoaiXeModule = lazy(() => import("./components/pages/car_parking/vehicle/DSLoaiXeModule.js"));

const DMRoutes = () => {
	const {environment} = useSelector((state) => state);

	return <Suspense fallback={<LoadingSpinner />}>
		<Routes>
			{environment?.user?.type == USER_TYPE_USER && <Route exact path="/" element={<DashboardModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/" element={<CPDashboardModule />} environment={environment} />}
			<Route exact path="/devices" element={<DevicesModule />} environment={environment} />
			<Route exact path="/manageSystem" element={<ManageSystemModule />} environment={environment} />
			<Route exact path="/manageUsers" element={<UsersModule />} environment={environment} />
			<Route path="/devices/:id" element={<DevicesModule />} environment={environment} />
			{environment?.user?.type == USER_TYPE_USER && <Route exact path="/thongke" element={<StatisticModule />} environment={environment} />}
			{environment?.user?.type == USER_TYPE_MANAGER && <Route exact path="/dshkd" element={<HKDsModule />} environment={environment} />}
			<Route exact path="/tramsac" element={<TramSacssModule />} environment={environment} />
			{environment?.user?.type == USER_TYPE_USER && <Route exact path="/topup/:id" element={<TopUpModule />} environment={environment} />}
			{environment?.user?.type == USER_TYPE_USER && <Route exact path="/chargerhistory/:id" element={<ChargerHistoryModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/vehicle" element={<VehicleModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/dsloaixe" element={<DSLoaiXeModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/block" element={<BlockModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/monthlyParking" element={<MonthlyParkingModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/cp_monthly" element={<CPMonthlyModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/parkingInTurns" element={<ParkingInTurnModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/settings" element={<SettingModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/hauKiem" element={<HauKiemModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/bcDoanhThu" element={<BCDoanhThuModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/bcSoLuot" element={<BCSoLuotModule />} environment={environment} />}
			{environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <Route exact path="/bcTKBaoVe" element={<BCTKBaoVeModule />} environment={environment} />}
		</Routes>
	</Suspense>
}

export default DMRoutes;