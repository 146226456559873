import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import MonthlyParkingDashboard from "./MonthlyParkingDashboard";
import MonthlyParkingData from "./MonthlyParkingData";
import { useDispatch, useSelector } from "react-redux";
import SHMessage from "../../../../custom-components/SHMessage";
import { IconThemeProvider } from "../../../../custom-components/context/IconThemesContext";
import DashboardSplitPane from "../../../core/Dashboard/DashboardSplitPane";
import SHAddForm from "../../common/SHAddForm";
import {setShowAddMonthlyParking, setShowEditMonthlyParking} from "../../../../slices/car_parking/MonthlyParkingSlice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { addNewMonthlyParking, updateMonthlyParking } from "../../../data/car_parking/monthlyParking/monthlyParkingServices";
import { uploadImage } from "../../../data/dataServices";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    formSidePanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
    },
    formBody: {
        display: "contents",
    },
}));

const MonthlyParkingModule = () => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const {environment, monthlyParkings} = useSelector((state) => state);

    const {accessToken} = environment;
    const [error, setError] = useState(null);
    const [editLoadingFinished, setEditLoadingFinished] = useState(false);
    const dispatch = useDispatch();

    const fields = [
        {name: t('common:car_parking.monthly.maThe'), id: "ma_the", type:"text"},
        {name: t('common:car_parking.monthly.loaiThe'), id: "loai_the", type:"selectFromTable", filterForAccount: true, table: "cp_loai_xe", onChangeDestinations: ["gia_tien"] },
        {name: t('common:car_parking.monthly.giaTien'), id: "gia_tien", type:"text", allowEdit: false},
        {name: t('common:car_parking.monthly.bienSo'), id: "bien_so", type:"text"},
        {name: t('common:car_parking.monthly.tenChuThe'), id: "ten_chu_the", type:"text"},
        {name: t('common:misc.phone'), id: "dien_thoai", type:"text"},
        {name: t('common:car_parking.monthly.tuNgay'), id: "tu_ngay", type:"date", setFirstDayOfMonth: true},
        {name: t('common:car_parking.monthly.denNgay'), id: "den_ngay", type:"date", setLastDayOfMonth: true},
        {name: t('common:car_parking.monthly.hinh1'), id: "hinh1", type:"file"},
        /*{name: t('common:car_parking.monthly.hinh2'), id: "hinh2", type:"file"},
        {name: t('common:car_parking.monthly.hinh3'), id: "hinh3", type:"file"},
        {name: t('common:car_parking.monthly.hinh4'), id: "hinh4", type:"file"},
        {name: t('common:car_parking.monthly.hinh5'), id: "hinh5", type:"file"},*/
        {name: t('common:misc.ghiChu'), id: "ghi_chu", type:"text"}];

    const initUpdateFields = [
        {name: t('common:car_parking.monthly.maThe'), id: "ma_gui_xe", type:"text", allowEdit: false, hidden: true},
        {name: t('common:car_parking.monthly.maThe'), id: "ma_the", type:"text"},
        {name: t('common:car_parking.monthly.loaiThe'), id: "loai_the", type:"selectFromTable", filterForAccount: true, table: "cp_loai_xe", onChangeDestinations: ["gia_tien"] },
        {name: t('common:car_parking.monthly.giaTien'), id: "gia_tien", type:"text", allowEdit: false},
        {name: t('common:car_parking.monthly.bienSo'), id: "bien_so", type:"text"},
        {name: t('common:car_parking.monthly.tenChuThe'), id: "ten_chu_the", type:"text"},
        {name: t('common:misc.phone'), id: "dien_thoai", type:"text"},
        {name: t('common:car_parking.monthly.tuNgay'), id: "tu_ngay", type:"date"},
        {name: t('common:car_parking.monthly.denNgay'), id: "den_ngay", type:"date"},
        {name: t('common:car_parking.monthly.hinh1'), id: "hinh1", type:"file"},
        /*{name: t('common:car_parking.monthly.hinh2'), id: "hinh2", type:"file"},
        {name: t('common:car_parking.monthly.hinh3'), id: "hinh3", type:"file"},
        {name: t('common:car_parking.monthly.hinh4'), id: "hinh4", type:"file"},
        {name: t('common:car_parking.monthly.hinh5'), id: "hinh5", type:"file"},*/
        {name: t('common:misc.ghiChu'), id: "ghi_chu", type:"text"},
        {name: t('common:misc.status'), id: "status", type:"selectFromArray", options:[{name:'Active', value: 1}, {name:'Disable', value:0}]}];

    const [updateFields, setUpdateFields] = useState(initUpdateFields);


    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={() => setError(null)}/>

    useEffect(() => {
        if(!monthlyParkings.showEditMonthlyParking || monthlyParkings.showEditMonthlyParking === false){
            return;
        }
        if(monthlyParkings.rowDataSelected === null){
            setError(t('common:error.pleaseSelectRowEdit'));

            dispatch(setShowEditMonthlyParking(false));
            return;
        }
        const updateFieldsTmp = updateFields.map(item => {
            return { ...item, value: monthlyParkings.rowDataSelected[item.id]}
        })

        setUpdateFields(updateFieldsTmp);
        setEditLoadingFinished(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthlyParkings.showEditMonthlyParking])

    useEffect(() => {
        const updateFieldsTmp = updateFields.map(item => {
            const field = initUpdateFields.find(item2=>item2.id == item.id);
            return { ...item, name: field.name};
        })
        setUpdateFields(updateFieldsTmp);
    }, [i18n.language])

    const okAddFormClickHandle = (fields) => {
        console.log(fields);
        const monthlyParking = {}
        fields.forEach(item => {
            if(!item.hidden){
                monthlyParking[item.id] = item.value;
            }
        })
        console.log(monthlyParking);
        if(monthlyParking.hinh1 && monthlyParking.hinh1.file != null && monthlyParking.hinh1.file !== undefined){
            uploadImage(monthlyParking.hinh1.file, accessToken).then((res) => {
                monthlyParking.hinh1 = res.data.path
                addNewMonthlyParking(monthlyParking, accessToken).then((res) => {
                    dispatch(setShowAddMonthlyParking(false));
                }).catch((err) => {
                    console.log(err);
                    setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
                }).finally(() => {
                })
            }).catch((err) => {
                console.log(err);
                setError(err.response.data.message);
            }).finally(() => {
            })
        }else{
            monthlyParking.hinh1 = monthlyParking.hinh1.value;
            addNewMonthlyParking(monthlyParking, accessToken).then((res) => {
                dispatch(setShowAddMonthlyParking(false));
            }).catch((err) => {
                console.log(err);
                setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
            }).finally(() => {
            })
        }
    }

    const okEditFormClickHandle = (fields) => {
        console.log(fields);
        const monthlyParking = {}
        fields.forEach(item => {
            monthlyParking[item.id] = item.value;
        })
        console.log(monthlyParking);

        if(monthlyParking.hinh1 && monthlyParking.hinh1.file != null && monthlyParking.hinh1.file !== undefined){
            uploadImage(monthlyParking.hinh1.file, accessToken).then((res) => {
                monthlyParking.hinh1 = res.data.path
                updateMonthlyParking(monthlyParking, accessToken).then((res) => {
                    dispatch(setShowEditMonthlyParking(false));
                    setEditLoadingFinished(false);
                }).catch((err) => {
                    console.log(err);
                    setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
                }).finally(() => {
                })
            }).catch((err) => {
                console.log(err);
                setError(err.response.data.message);
            }).finally(() => {
            })
        }else{
            monthlyParking.hinh1 = monthlyParking.hinh1.value;
            updateMonthlyParking(monthlyParking, accessToken).then((res) => {
                dispatch(setShowEditMonthlyParking(false));
                setEditLoadingFinished(false);
            }).catch((err) => {
                console.log(err);
                setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
            }).finally(() => {
            })
        }

        
    }

    const cancelAddFormClickHandle = () => {
        dispatch(setShowAddMonthlyParking(false))
    }

    const cancelEditFormClickHandle = () => {
        dispatch(setShowEditMonthlyParking(false));
        setEditLoadingFinished(false);
    }

    const sidePanel = () =>
        <div id="form-side-panel" className={classes.formSidePanel}>
            {!monthlyParkings.showAddMonthlyParking && !monthlyParkings.showEditMonthlyParking && <MonthlyParkingDashboard environment={environment} loading={false}/>}
        </div>

    const formBody = () => (
        <div id="form-body" className={classes.formBody}>
            {showErrorMessage()}
            {!monthlyParkings.showAddMonthlyParking && !monthlyParkings.showEditMonthlyParking && <MonthlyParkingData environment={environment} module={monthlyParkings} />}
            {monthlyParkings.showAddMonthlyParking && <SHAddForm environment={environment} module={monthlyParkings} title={t('common:car_parking.monthly.addMonthlyParking')} fields={fields} onOk={okAddFormClickHandle} onClose={cancelAddFormClickHandle}/>}
            {monthlyParkings.showEditMonthlyParking && editLoadingFinished && <SHAddForm environment={environment} module={monthlyParkings} title={t('common:car_parking.monthly.updateMonthlyParking')} fields={updateFields} onOk={okEditFormClickHandle} onClose={cancelEditFormClickHandle} okBtnLabel={t('common:button.update')}/>}
        </div>
    )

    return (
        <IconThemeProvider>
            <DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()}/>
        </IconThemeProvider>
    );
}


export default MonthlyParkingModule;