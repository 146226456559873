import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import BCSoLuotDashboard from "./BCSoLuotDashboard";
import BCSoLuotData from "./BCSoLuotData";
import { useDispatch, useSelector } from "react-redux";
import SHMessage from "../../../../custom-components/SHMessage";
import { IconThemeProvider } from "../../../../custom-components/context/IconThemesContext";
import DashboardSplitPane from "../../../core/Dashboard/DashboardSplitPane";
import SHAddForm from "../../common/SHAddForm";
import {setShowAddBCSoLuot, setShowEditBCSoLuot} from "../../../../slices/car_parking/BCSoLuotSlice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    formSidePanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
    },
    formBody: {
        display: "contents",
    },
}));

const BCSoLuotModule = () => {
    const classes = useStyles();
    const [t] = useTranslation('common');
    const {environment, bcSoLuot} = useSelector((state) => state);

    const {accessToken} = environment;
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={() => setError(null)}/>

    const sidePanel = () =>
        <div id="form-side-panel" className={classes.formSidePanel}>
            <BCSoLuotDashboard environment={environment} loading={false}/>
        </div>

    const formBody = () => (
        <div id="form-body" className={classes.formBody}>
            {showErrorMessage()}
            <BCSoLuotData environment={environment} module={bcSoLuot} />
        </div>
    )


    return (
        <IconThemeProvider>
            <DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()}/>
        </IconThemeProvider>
    );
}


export default BCSoLuotModule;