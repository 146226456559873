import axios from "axios";
import { apiHeaderX } from "../../dataServices";
import { API } from "./../../../../config";

export const userSignIn = async (user) =>  {

    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }

    return await axios.post(`${API}/api/car_parking/auth/login`, user, config)
}