import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@LoaiXeStatus', type: 'EQ'}
        ]
    }
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Mã loại xe', translateId: 'common:car_parking.vehicle.maLoaiXe', id: "ma_loai_xe", hidden:true},
            {name: 'Tên loại xe', translateId: 'common:car_parking.vehicle.tenLoaiXe', id: "ten_loai_xe"},
            {name: 'Giá ban ngày', translateId: 'common:car_parking.vehicle.giaBanNgay', id: "gia_ban_ngay"},
            {name: 'Giá ban đêm', translateId: 'common:car_parking.vehicle.giaBenDem', id: "gia_ban_dem"},
            {name: 'Giá cả ngày', translateId: 'common:car_parking.vehicle.giaCaNgay', id: "gia_ca_ngay"},
            {name: 'Loại', translateId: 'common:misc.status', id: "status", hidden:true}],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddVehicle: false,
    showEditVehicle: false,
    rowPerPage: 10
};
const LoaiXeSlice = createSlice({
    name: 'cp_loai_xe',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addLoaiXe:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddVehicle:(state, action) => {
            if(action.payload === false){
                return {...state, showAddVehicle: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddVehicle: action.payload};
            }
        },
        setShowEditVehicle:(state, action) => {
            if(action.payload === false){
                return {...state, showEditVehicle: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditVehicle: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var loaiXe = state.dataGrid.records.filter(item => item.ma_loai_xe === action.payload.ma_loai_xe);
            if(loaiXe.length>0){
                return {...state, rowDataSelected: loaiXe[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        }
    }
});

export const {
    reset,
    addLoaiXe,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddVehicle,
    setShowEditVehicle,
    setRowDataSelected } = LoaiXeSlice.actions;
export default LoaiXeSlice.reducer;