import './App.css';
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from 'react';
import SignIn from "./components/user/SignIn";
import { isAuthenticated } from "./components/auth/AuthServices";
import { setUser, initializedEnvironment, setAccessToken } from "./slices/EnvironmentSlice";
import LoadingSpinner from "./custom-components/LoadingSpinner";
import AppFullScreen from "./components/AppFullScreen";
import CustomerModule from './components/pages/customer/CustomerModule';
import {
  requestNewAccessToken, handleSignOut
} from "./components/data/dataServices";
import { ResetPasswordModule } from './components/pages/customer/ResetPasswordModule';
import CAPModule from './components/pages/car_parking/cap/CAPModule';

const App = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const environment = useSelector((state) => state.environment);

  const dispatch = useDispatch();
  const [userSession, setUserSession] = useState(isAuthenticated());
  const showLoading = () => environment.isLoading && <LoadingSpinner />;

  const renderApp = () => {
    let id = null;
    window.Buffer = window.Buffer || require("buffer-from").Buffer;
    console.log(window.location.pathname);
    if (window.location.pathname === "/cap") {
      return <CAPModule environment={environment} />
    }else if (window.location.pathname === "/customer" || window.location.hostname.startsWith("customer.")) {
      id = queryParameters.get("id");
    } else if (window.location.pathname === "/password-reset") {
      const hash = queryParameters.get("hash");
      const user_id = queryParameters.get("user_id");
      return <ResetPasswordModule hash={hash} user_id={user_id}></ResetPasswordModule>
    }
    if (id !== undefined && id !== null && id !== '') {
      return <CustomerModule id={id} environment={environment} />
    } else {
      return userSession ? (
        <AppFullScreen
          isAuthenticated={environment.isAuthenticated}
          apiToken={environment.accessToken}
        />
      ) : (window.location.hostname.startsWith('cvp.') )?(
        <SignIn cvp={true}/>
      ):(
        <SignIn/>
      );
    }
  }

  const checkSession = () =>
    userSession && (
      dispatch(setUser({ user: userSession.user[0], refreshToken: userSession.refreshToken })),
      requestNewAccessToken(userSession.refreshToken).then(response => {
        dispatch(setAccessToken(response.data.accessToken));
      }).catch(err => {
        handleSignOut(dispatch);
      }).finally(() => {
      })
    );

  useEffect(() => {
    dispatch(initializedEnvironment());
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserSession(environment.isAuthenticated);
  }, [environment.isAuthenticated]);

  return (
    <div>
      {showLoading()}
      {renderApp()}
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    environment: state.environment,
    devices: state.devices,
  };
};

export default connect(mapStateToProps)(App);
