import React from 'react';
import { Grid, Typography, Checkbox, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
export const SCREEN_LOGIN = "screen-LOGIN";

export const ScreenLogin = ({ show, handleChange, username, password, account, handleDangNhap, handleQuenMatKhau, loading }) => {
    if(!show){
        return;
    }
    return (
        <Grid id="{SCREEN_LOGIN}" sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    value={username}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="username"
                    label="Tên đăng nhập"
                    name="username"
                    autoComplete="username"
                    required
                    autoFocus
                />
                <TextField
                    value={password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="password"
                    label="Mật khẩu"
                    name="password"
                    autoComplete="current-password"
                    type="password"
                    required
                />
                <TextField
                    value={account}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="account"
                    label="Tài khoản"
                    name="account"
                    autoComplete="current-account"
                    required
                />
                <LoadingButton variant="contained" onClick={handleDangNhap} >
                    Đăng nhập
                </LoadingButton>
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handleQuenMatKhau}>
                    Quên mật khẩu
                </Button>
            </Grid>
        </Grid>
    );
};
