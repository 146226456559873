import React from "react";
import TopNavLayout from "./TopNavLayout";
import BannerNavLayout from "./BannerNavLayout";

const NavLayout = () => {

    return (
        <>
            <TopNavLayout/>
            <BannerNavLayout/>
        </>
    )
};

export default NavLayout;
