import React from 'react';
import { Grid, Typography, Checkbox, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
export const SCREEN_CAP = "screen-CAP";

export const ScreenCAP = ({ show, handleChange, username, password, account, handleDangNhap, handleQuenMatKhau, loading }) => {
    if(!show){
        return;
    }

    const handleXeVao = () => {

    }

    const handleXeRa = () => {
        
    }
    return(
        <Grid id="{SCREEN_CAP}" sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
                <Button 
                variant="contained" 
                fullWidth
                onClick={handleXeVao}>
                    Xe vào
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button 
                variant="contained" 
                fullWidth
                onClick={handleXeRa}>
                    Xe ra
                </Button>
            </Grid>
        </Grid>
    );
}