import axios from "axios";
import { apiHeaderX } from "../../dataServices";
import { API } from "./../../../../config";

export const monthlyCarIn = async ( monthlyParking, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/car_parking/monthly/carIn`,
        monthlyParking,
        config
    )
}

export const getMonthlyCarInInfo = async ( monthlyParking, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/car_parking/monthly/getCarIn`,
        monthlyParking,
        config
    )
}

export const getMonthlyCarOut = async ( monthlyParking, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/car_parking/monthly/carOut`,
        monthlyParking,
        config
    )
}

export const addNewMonthlyParking = async ( monthlyParking, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/car_parking/monthly/add`,
        monthlyParking,
        config
    )
}

export const updateMonthlyParking = async ( monthlyParking, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };

    return await axios.post(`${API}/api/car_parking/monthly/update`,
        monthlyParking,
        config
    )
}

export const loadMonthlyParkingRecordsQuery = async ( filter, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };
    let deviceFilter = filter.conditions.filter(cond => cond.target !== "@DeviceNumber");

    let bodyParameters = {
        filter: {conditions: deviceFilter},
    };

    return await axios.post(`${API}/api/car_parking/monthly/query`,
        bodyParameters,
        config
    )
}
