import ConstructionIcon from '@mui/icons-material/Construction';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setFullScreenMode } from "../../../slices/EnvironmentSlice";
import { useNavigate } from "react-router-dom";
import {setNavigateToPage} from "../../../slices/EnvironmentSlice";
import { useUrlQueryParams } from "../../../custom-components/hooks/useUrlQueryParams";
import SchoolIcon from '@mui/icons-material/School';
import { ACCOUNT_TYPE_CAR_PARKING, USER_TYPE_MANAGER, USER_TYPE_USER } from '../../../constants';
import BarChartIcon from '@mui/icons-material/BarChart';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';

const useStyles = makeStyles(() => ({
    menuAppbar: props => ({
        height: '30px',
        border: 'none',
        display:  'flex',
        flexWrap: 'wrap',
        paddingLeft: 0,
        marginBottom: 0,
        listStyle: 'none',
        overflow: 'hidden'
    }),
    toolbar: {
        minHeight: 'fit-content'
    },
    menuNavLink: props => ({
        display: 'flex',
        padding: '0.2rem 1rem',
        "&:hover": {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    }),
    menuNavIconLink: props => ({
        display: 'flex',
        padding: '0.2rem 0.5rem',
        color: props.color,
        "&:hover": {
            textDecoration: 'none'
        }
    }),

    icon: props => ({
        fontSize: '1.5em'
    })
}));

const NavTabs = () => {
    const urlQueryParam = useUrlQueryParams();
    const fullscreenQuery = urlQueryParam.get("fullscreen");

    const {environment} = useSelector((state) => state);

    const classes = useStyles();

    const [t] = useTranslation('common');
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [iconMenuSelected, setIconMenuSelected] = useState(null);
    const [fullscreenMode, setFullscreenMode] = useState(fullscreenQuery==='1'?true:false);
    const [fullscreenHover, setFullscreenHover] = useState(false);
    const [fullscreenExitHover, setFullscreenExitHover] = useState(false);

    const initialMenuItemSelectedState = {
        devicesMenuSelected: false,
        
        manageSystemMenuSelected: false,
        manageUsersMenuSelected: false,
        manageHKDsMenuSelected: false,
        manageTramSacsMenuSelected: false,
        manageDSLoaiXeMenuSelected: false,
        manageDSBlockMenuSelected: false
    }
    const [menuItemSelected, setMenuItemSelected] = useState(initialMenuItemSelectedState);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!environment.isLoggedIn){
            navigate('/');
        }
    }, [environment.isLoggedIn, navigate])

    useEffect(() => {
        if(environment.navToPage && environment.navToPage !== ''){
            dispatch(setNavigateToPage(""));
            navigate(environment.navToPage);
        }
    }, [environment.navToPage, dispatch, navigate])

    const handleToggleFullscreen = (e, toggle) => {
        setFullscreenMode(toggle);
        dispatch(setFullScreenMode(toggle));

        if (toggle) {
            setTimeout(() => {
                setFullscreenHover(false);
            }, 500);
        } else {
            setTimeout(() => {
                setFullscreenExitHover(false);
            }, 500);
        }
    }

    const handleMenuIconClick = (e, target) => {
        setAnchorEl(e.currentTarget);
        setIconMenuSelected(target);

        switch(target){
            case 'question':
                break;
            case 'video':
                break;
            case 'headset':
                break;
            case 'contruction':
                break;
            case 'statistic':
                break;
            case 'report':
                break;
            case 'setting':
                break;
            default:
                return;
        }
        setOpen(true);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const setShowNavMenu = (menuName) => {
        switch(menuName){
            case 'devices':
                setMenuItemSelected({...initialMenuItemSelectedState, devicesMenuSelected: true});
                navigate('/devices');
                break;
            case 'cards':
                setMenuItemSelected({...initialMenuItemSelectedState, cardsMenuSelected: true});
                navigate('/cards');
                break;
            case 'monthlyParking':
                setMenuItemSelected({...initialMenuItemSelectedState, monthlyParkingMenuSelected: true});
                navigate('/monthlyParking');
                break;
            case 'carParkingMonthly':
                setMenuItemSelected({...initialMenuItemSelectedState, carParkingMonthlyMenuSelected: true});
                navigate('/cp_monthly');
                break;
            case 'parkingInTurns':
                setMenuItemSelected({...initialMenuItemSelectedState, parkingInTurnsMenuSelected: true});
                navigate('/parkingInTurns');
                break;
            case 'manage_system':
                setMenuItemSelected({...initialMenuItemSelectedState, manageSystemMenuSelected: true});
                navigate('/manageSystem');
                break;
            case 'manage_users':
                setMenuItemSelected({...initialMenuItemSelectedState, manageUsersMenuSelected: true});
                navigate('/manageUsers');
                break;
            case 'manage_hkds':
                setMenuItemSelected({...initialMenuItemSelectedState, manageHKDsMenuSelected: true});
                navigate('/dshkd');
                break;
            case 'statistic':
                setMenuItemSelected({...initialMenuItemSelectedState, statisticMenuSelected: true});
                navigate('/thongke');
                break;
            case 'hauKiem':
                setMenuItemSelected({...initialMenuItemSelectedState, hauKiemMenuSelected: true});
                navigate('/hauKiem');
                break;
            case 'bcDoanhThu':
                setMenuItemSelected({...initialMenuItemSelectedState, bcDoanhThuMenuSelected: true});
                navigate('/bcDoanhThu');
                break;
            case 'bcSoLuot':
                setMenuItemSelected({...initialMenuItemSelectedState, bcSoLuotMenuSelected: true});
                navigate('/bcSoLuot');
                break;
            case 'bcTKBaoVe':
                setMenuItemSelected({...initialMenuItemSelectedState, bcTKBaoVeMenuSelected: true});
                navigate('/bcTKBaoVe');
                break;
            case 'manage_tramsacs':
                setMenuItemSelected({...initialMenuItemSelectedState, manageTramSacsMenuSelected: true});
                navigate('/tramsac');
                break;
            case 'manage_vehicle':
                setMenuItemSelected({...initialMenuItemSelectedState, manageDSLoaiXeMenuSelected: true});
                navigate('/vehicle');
                break;
            case 'manage_dsloaixe':
                setMenuItemSelected({...initialMenuItemSelectedState, manageDSLoaiXeMenuSelected: true});
                navigate('/dsloaixe');
                break;
            case 'manage_dsblock':
                setMenuItemSelected({...initialMenuItemSelectedState, manageDSBlockMenuSelected: true});
                navigate('/block');
                break;
            case 'manage_setting':
                setMenuItemSelected({...initialMenuItemSelectedState, manageSettingMenuSelected: true});
                navigate('/settings');
                break;
            default:
                break;
        }
    }

    const handleDevicesClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('devices');
    };

    const handleCardsClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('cards');
    };

    const handleMonthlyParkingClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('carParkingMonthly');
    };

    const handleSettingMonthlyParkingClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('monthlyParking');
    };

    const handleParkingInTurnsClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('parkingInTurns');
    };

    const handleManageSystemClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_system');
    };

    const handleUsersClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_users');
    };

    const handleHKDsClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_hkds');
    };

    const handleTramSacsClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_tramsacs');
    };

    const handleLoaiXeClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_vehicle');
    };

    const handleDSLoaiXeClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_dsloaixe');
    };

    const handleBlockClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_dsblock');
    };

    const handleStatisticClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('statistic');
    };

    const handleHauKiemClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('hauKiem');
    };

    const handleBCDoanhThuClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('bcDoanhThu');
    };

    const handleBCSoLuotClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('bcSoLuot');
    };

    const handleBCTKBaoVeClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('bcTKBaoVe');
    };
    

    const handleSettingClick = () => {
        setAnchorEl(null);
        setOpen(false);
        setShowNavMenu('manage_setting');
    };

    const showNavMenuDevices = () => {
        if(menuItemSelected.devicesMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/devices"
                    >
                        {t('common:tabs.devices')}
                    </NavLink>
                </li>
            )
        }
        if(menuItemSelected.cardsMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/cards"
                    >
                        {t('common:tabs.cards')}
                    </NavLink>
                </li>
            )
        }
        if(menuItemSelected.monthlyParkingMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/monthlyParking"
                    >
                        {t('common:tabs.monthlyParking')}
                    </NavLink>
                </li>
            )
        }
        if(menuItemSelected.carParkingMonthlyMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/carParkingMonthly"
                    >
                        {t('common:tabs.monthlyParking')}
                    </NavLink>
                </li>
            )
        }
        if(menuItemSelected.parkingInTurnsMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/parkingInTurns"
                    >
                        {t('common:tabs.parkingInTurns')}
                    </NavLink>
                </li>
            )
        }
    }

    const showNavMenuManageUsers = () => {
        if(menuItemSelected.manageUsersMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/manageUsers"
                    >
                        {t('common:tabs.manageUser')}
                    </NavLink>
                </li>
            )
        }
    }

    const showNavMenuManageHKDs = () => {
        if(menuItemSelected.manageHKDsMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/dshkd"
                    >
                        {t('common:tabs.hkd')}
                    </NavLink>
                </li>
            )
        }
    }

    const showNavMenuManageTramSacs = () => {
        if(menuItemSelected.manageTramSacsMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/tramsac"
                    >
                        {t('common:tabs.dstramsac')}
                    </NavLink>
                </li>
            )
        }
    }
    
    const showNavMenuManageSystem = () => {
        if(menuItemSelected.manageSystemMenuSelected){
            return (
                <li className="nav-item">
                    <NavLink
                        className={classes.menuNavLink}
                        activeClassName={classes.selected}
                        to="/manageSystem"
                    >
                        {t('common:tabs.manageSystem')}
                    </NavLink>
                </li>
            )
        }
    }

    return (
        <div>
            <ul className={classes.menuAppbar}>
                { showNavMenuDevices() }

                { showNavMenuManageSystem() }
                { showNavMenuManageUsers() }
                { showNavMenuManageHKDs() }
                { showNavMenuManageTramSacs() }

                <li className="nav-item" style={{position: 'absolute', right: 0}}>
                    <NavLink
                        className={classes.menuNavIconLink}
                        to="#"
                        onClick={e => handleMenuIconClick(e, 'setting')}
                    >
                        <SettingsIcon className={classes.icon}/>
                    </NavLink>
                </li>
                <li className="nav-item" style={{position: 'absolute', right: 40}}>
                    <NavLink
                        className={classes.menuNavIconLink}
                        to="#"
                        onClick={e => handleMenuIconClick(e, 'contruction')}
                    >
                        <ConstructionIcon className={classes.icon}/>
                    </NavLink>
                </li>
                <li className="nav-item" style={{position: 'absolute', right: 80}}>
                    <NavLink
                        className={classes.menuNavIconLink}
                        to="#"
                        onClick={e => handleMenuIconClick(e, 'report')}
                    >
                        <PlagiarismIcon className={classes.icon}/>
                    </NavLink>
                </li>
                {environment?.user?.type == USER_TYPE_USER && <li className="nav-item" style={{position: 'absolute', right: 120}}>
                    <NavLink
                        className={classes.menuNavIconLink}
                        to="#"
                        onClick={e => handleMenuIconClick(e, 'statistic')}
                    >
                        <BarChartIcon className={classes.icon}/>
                    </NavLink>
                </li>}

                <li className="nav-item" style={{position: 'absolute', right: 160}}>
                    {fullscreenMode ? <Tooltip
                        key="expand"
                        title={`${t('tooltip.expand')}`}
                        arrow
                        placement="bottom-start"
                        disableInteractive
                        disableHoverListener
                        open={!fullscreenHover && fullscreenExitHover}
                    >
                        <NavLink
                            className={classes.menuNavIconLink}
                            to="#"
                            onClick={(e) => handleToggleFullscreen(e, !fullscreenMode)}
                            onMouseEnter={() => setFullscreenExitHover(true)}
                            onMouseLeave={() => setFullscreenExitHover(false)}
                        >
                            <FullscreenExitIcon className={classes.icon}/>
                        </NavLink>
                    </Tooltip> : <Tooltip
                        key="fullscreen"
                        title={`${t('tooltip.fullscreen')}`}
                        arrow
                        placement="bottom-start"
                        disableInteractive
                        disableHoverListener
                        open={!fullscreenExitHover && fullscreenHover}
                    >
                        <NavLink
                            className={classes.menuNavIconLink}
                            to="#"
                            onClick={(e) => handleToggleFullscreen(e, !fullscreenMode)}
                            onMouseEnter={() => setFullscreenHover(true)}
                            onMouseLeave={() => setFullscreenHover(false)}
                        >
                            <FullscreenIcon className={classes.icon}/>
                        </NavLink>
                    </Tooltip>}
                </li>
            </ul>

            {iconMenuSelected === 'contruction' && 
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    {environment?.user?.account_type != ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleDevicesClick(e)}>{t('common:tabs.devices')}</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleMonthlyParkingClick(e)}>{t('common:tabs.monthlyParking')}</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleParkingInTurnsClick(e)}>{t('common:tabs.parkingInTurns')}</MenuItem>}
                </Menu>
            }
            {iconMenuSelected === 'setting' &&
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >

                    <MenuItem onClick={e => handleManageSystemClick(e)}>{ t('common:tabs.manageSystem') }</MenuItem>
                    <MenuItem onClick={e => handleUsersClick(e)}>{  t('common:tabs.manageUser') }</MenuItem>
                    {environment?.user?.type == USER_TYPE_MANAGER && <MenuItem onClick={e => handleHKDsClick(e)}>{  t('common:tabs.hkd')  }</MenuItem>}
                    {environment?.user?.account_type != ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleTramSacsClick(e)}>{  t('common:tabs.dstramsac')  }</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleDSLoaiXeClick(e)}>{  t('common:tabs.dsLoaiXe')  }</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleLoaiXeClick(e)}>{  t('common:tabs.bangGiaVeLuot')  }</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleBlockClick(e)}>{  t('common:tabs.dsBlock')  }</MenuItem>}
                    {false && environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleCardsClick(e)}>{t('common:tabs.cards')}</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleSettingMonthlyParkingClick(e)}>{t('common:tabs.monthlyCards')}</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleSettingClick(e)}>{t('common:tabs.cp_setting')}</MenuItem>}
                </Menu>
            }
            {iconMenuSelected === 'statistic' &&
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    {environment?.user?.account_type != ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleStatisticClick(e)}>{t('common:tabs.statistic')}</MenuItem>}
                </Menu>
            }
            {iconMenuSelected === 'report' &&
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleHauKiemClick(e)}>{t('common:tabs.hauKiem')}</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleBCDoanhThuClick(e)}>{t('common:tabs.bcDoanhThu')}</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleBCSoLuotClick(e)}>{t('common:tabs.bcSoLuot')}</MenuItem>}
                    {environment?.user?.account_type == ACCOUNT_TYPE_CAR_PARKING && <MenuItem onClick={e => handleBCTKBaoVeClick(e)}>{t('common:tabs.bcTKBaoVe')}</MenuItem>}
                </Menu>
            }
        </div>
    );
}

export default  NavTabs;