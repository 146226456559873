import React, { useEffect, useContext } from 'react';
//import 'icheck/skins/all.css';
import '../../../assets/css/blue.css';
import logoZalopay from "../../../assets/images/logo-zalopay.svg";
import { makeStyles } from '@mui/styles';
import {
    Box, Button,
    Container,
    CssBaseline,
    Typography
} from "@mui/material";

export const SCREEN_PAYMENT = "screen-Payment";



const useStyles = makeStyles(() => ({
    root: {
        padding: '20px'
    },
    iradio_flat_blue: {
        top: '-2px',
        marginRight: '5px'
    },
    container: {
        '& label': {
            display: 'inline-block',
            marginBottom: '.5rem'
        },
        '& img': {
            verticalAlign: 'middle',
            borderStyle: 'none'
        },
        '& .txtGray': {
            color: '#798594'
        }
    }

}));

export const PaymentForm = ({ show }) => {
    const classes = useStyles();

    useEffect(() => {
    }, []);

    const handleSubmit = () =>{

    }

    if(!show){
        return;
    }
    return (
        <div className={classes.container}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Typography component="h1" variant="h5">
                        Vui lòng chọn hình thức thanh toán:
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <div className="mb-1">
                            <label><input type="radio" name="iCheck" className={`${classes.iradio_flat_blue} ${"iradio_flat-blue"}`} /> Ví <img src={logoZalopay} alt="" /></label>
                        </div>
                        <div className="mb-1">
                            <label><input type="radio" name="iCheck" className={`${classes.iradio_flat_blue} ${"iradio_flat-blue"}`} /> Visa, Mastercard, JCB <span className="txtGray">(qua cổng ZaloPay)</span></label>
                        </div>
                        <div className="mb-1">
                            <label><input type="radio" name="iCheck" className={`${classes.iradio_flat_blue} ${"iradio_flat-blue"}`} defaultChecked /> Thẻ ATM <span className="txtGray">(qua cổng ZaloPay)</span></label>
                        </div>
                        <div>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth>
                                Thanh toán
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};