import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../../custom-components/LoadingSpinner";
import SkeletonLoaderSidePanel from "../../../../custom-components/skeletons/SkeletonLoaderSidePanel";
import { NavLink } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import CachedIcon from '@mui/icons-material/Cached';
import {setRefreshData, setShowAddBlock, setShowEditBlock} from "../../../../slices/car_parking/BlockSlice";
import EditNoteIcon from '@mui/icons-material/EditNote';
import SHMessage from "../../../../custom-components/SHMessage";

const useStyles = makeStyles(() => ({
    leftMenuBar: props => ({
        height: '100%',
        border: 'none',
        display:  'block',
        flexWrap: 'wrap',
        paddingLeft: 0,
        marginBottom: 0,
        listStyle: 'none',
        overflow: 'hidden'
    }),
    menuNavLink: props => ({
        display: 'flex',
        padding: '0.2rem 1rem',
        "&:hover": {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    }),
    menuNavIconLink: props => ({
        display: 'flex',
        padding: '0.2rem 0.5rem',
        color: props.color,
        "&:hover": {
            textDecoration: 'none'
        }
    }),
    icon: props => ({
        fontSize: '1.5em'
    }),
    blockHeading: {
        display: "flex",
        alignSelf: "stretch",
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingRight: 0,
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        "& .MuiTypography-root": {
            fontWeight: "bold",
            display: "block",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        }
    },
    blockItem: {
        padding: 0,
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "stretch",
    },
    blockItemBtn: {
        display: "flex",
        padding: "1px 0px 1px 16px",
        "&:hover": {
            backgroundColor: 'white',
        },
        "& .MuiTypography-root": {
            display: "block",
            fontSize: "15px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        }
    },
    blockItemToolbar: {
        position: "relative",
        top: "auto",
        right: "auto",
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        backgroundColor: "#fff",
        transform: "none",
        paddingRight: "2px",
    },
    blockGroup: {
        padding: 0,
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "stretch",
    },
    blockGroupToolbar: {
        position: "relative",
        top: "auto",
        right: "auto",
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        transform: "none",
        paddingRight: "2px",
    },
    blockMenuListContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
    },
    blockMenuList: {
        paddingTop: 0,
        overflow: "hidden",
        height: "inherit",
        "&:not(.loading):hover": {
            overflowY: "auto",
        },
        "& .MuiListItem-root": {
            flexDirection: "column",
        },
        "& .MuiCollapse-root": {
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
        },
        "& .MuiListItem-container": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        "& .MuiListItem-container:hover .MuiTypography-root": {
            textDecoration: "underline",
        },
    },
    blockMenuChildList: {
        "& ul li:hover .MuiTypography-root": {
            textDecoration: 'underline',
        },
    },
    loadingWrapper: {
        position: "absolute",
        top: "20%",
        left: "45%",
        zIndex: "99999",
    },
}));

const DSBlockashboard = (props) => {
    const [t] = useTranslation('common');
    const classes = useStyles();
    const {environment, loading} = props;

    const {blocks} = useSelector((state) => state);

    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!environment.isBlockFormsLoaded || !environment.blocks.length) return;
        if (isEmpty(environment.dispatchForm) || !environment.blockCurrent?.blockForm) return;
    }, [environment.dispatchForm, environment.blocks, environment.isBlockFormsLoaded, environment.blockCurrent])

    const showLoading = () => (
        <>
            <div className={classes.loadingWrapper}>
                <LoadingSpinner withStyle={false}/>
            </div>
            {<SkeletonLoaderSidePanel/>}
        </>
    )

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed}/>

    const handleAddBlockClick = () => {
        dispatch(setShowAddBlock(true))
    }

    const handleRefreshIconClick = () => {
        dispatch(setRefreshData(true))
    }

    const handleEditBlockClick = () => {
        if(!blocks || blocks.rowDataSelected == null){
            setError(t('common:error.pleaseSelectRowEdit'));
            return;
        }
        dispatch(setShowEditBlock(true))
    }

    const handleMessageClosed = () => {
        setError(null);
    }

    return (
        <>
            <div className={classes.blockMenuListContainer}>
                {loading && showLoading()}
                {showErrorMessage()}
                <ul className={classes.leftMenuBar}>
                    {false && <li className="nav-item" style={{right: 0}}>
                        <NavLink
                            className={classes.menuNavIconLink}
                            to="#"
                            onClick={e => handleAddBlockClick(e)}
                        >
                            <AddIcon className={classes.icon}/>
                        </NavLink>
                    </li>}
                    <li className="nav-item" style={{right: 0}}>
                        <NavLink
                            className={classes.menuNavIconLink}
                            to="#"
                            onClick={e => handleEditBlockClick(e)}
                        >
                            <EditNoteIcon className={classes.icon}/>
                        </NavLink>
                    </li>
                    {false && <li className="nav-item" style={{right: 0}}>
                        <NavLink
                            className={classes.menuNavIconLink}
                            to="#"
                            onClick={e => {}}
                        >
                            <InfoIcon className={classes.icon}/>
                        </NavLink>
                    </li>}
                    <li className="nav-item" style={{right: 0}}>
                        <NavLink
                            className={classes.menuNavIconLink}
                            to="#"
                            onClick={e => handleRefreshIconClick(e)}
                        >
                            <CachedIcon className={classes.icon}/>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default DSBlockashboard;