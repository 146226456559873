import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { accountRecovering, userSignIn } from "./userServices";
import { authenticate } from "../auth/AuthServices";
import { useDispatch } from "react-redux";
import { setUser } from "../../slices/EnvironmentSlice";
import {
    Alert,
    Autocomplete, Box, Button,
    Checkbox,
    Container,
    createTheme, CssBaseline,
    FormControlLabel,
    FormGroup, Grid, TextField,
    ThemeProvider, Typography
} from "@mui/material";
import RecoverAccount from "./RecoverAccount";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiButton-root': {
            textTransform: "none !important",
        },

    },
    buttonsGroup: {
        marginTop: "8px",
    },
    logo: {
        width: "8em",
    },
    signInButton: {
        '&:hover':{
            backgroundColor: "#F86900",
            color: "#fff",
        },
    },
    selectPortalButton: {
        textTransform: "none",
    },
    cancelButton: {
        textTransform: "none",
    },
}));

const theme = createTheme();

const SignIn = (props) => {
    const {cvp} = props;
    const classes = useStyles();
    const [t] = useTranslation('common');
    const dispatch = useDispatch();

    const initialState = {
        user: null,
        username: "",
        password: "",
        account: "",
        code: null,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
    }
    const [values, setValues] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);

    const [userData, setUserData] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [showAccount, setShowAccount] = useState(false);
    const [recoverAccount, setRecoverAccount] = useState(false);

    const {user, username, password, account, code, isAuthenticated, accessToken, refreshToken} = values;

    const enabled = username.length > 0 && password.length > 0;

    useEffect(() => {
        if(!isAuthenticated) return;
        authenticate({user, refreshToken});
        dispatch(setUser({user: user[0], accessToken: accessToken,
            refreshToken: refreshToken}));
    }, [user, isAuthenticated, accessToken, refreshToken, dispatch])

    const showError = useCallback(() =>
        error? <Alert severity="error" onClose={() => {setError(null)} }>{error}</Alert>: null
    , [error])

    const showWarning = useCallback(() =>
        warning? <Alert severity="warning" onClose={() => {setWarning(null)}}>{warning}</Alert>: null
    , [warning])

    const onAccountChange = (event, results) => {
        setValues({ ...values, account: results });
    };


    const onAccountInputChange = (event, newInputValue, reason) => {
        if (reason === 'reset' || reason === 'clear') {
            setValues({ ...values, account: null });
            return
        } else {
            setValues({ ...values, account: newInputValue });
        }
    };

    const toggleShowAccount = () => {
        localStorage.setItem("sign_in_specify_account", !showAccount);
        setShowAccount(!showAccount);
    };

    // Handlers
    const handleRecoverAccount = () => {
        setRecoverAccount(true);
    }

    const recoverAccountClosed =() => {
        setRecoverAccount(false);
    }

    const handleChange = name => event => {
        setValues({ ...values,
            [name]: event.target.value });
    }

    const handleClear = (event) => {
        event.preventDefault()
        setValues(initialState);
        setAccounts([]);
        setShowAccount(false);
    }

    const recoverAccountConfirmed = (event) => {
        event.preventDefault();
        const usernameValue = event.target.username;

        setLoading(true);
        setValues({ ...initialState});
        accountRecovering(usernameValue).then(() => {
            setWarning(t('common:signIn.recoveredAccountMessage'))
            recoverAccountClosed();
        }).catch((err) => {
            setError(`Error ${err.response.status}. Please check your username again and retry.`)
            recoverAccountClosed();
        }).finally(() => {
            setLoading(false);
        })
    }

    const goToNewPortal = (data = null) => {
        setValues({ ...values, user: data.user || userData, isAuthenticated: true, accessToken: data.accessToken,
            refreshToken: data.refreshToken});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setWarning(null);
        setError(null);
        setLoading(true);
        userSignIn({username, password, account, code}).then((res) => {
            if(res.data.user.length === 0){
                setError(t('common:signIn.signinError'));
            }else{
                setUserData(res.data.user);
                goToNewPortal(res.data);
            }
        }).catch((err) => {
            if(err.response && err.response.data){
                if(err.response.data.code == 402){
                    setShowAccount(true);
                }
                console.log(err.response);
                setError(err.response.data.message);
            }else{
                console.log(err);
                setError(err.message);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    return <>
        <ThemeProvider theme={theme}>
            {!cvp && <>
                    <Typography 
                        fullWidth 
                        textAlign="center"
                        component="h1" variant="h5">
                        { t('common:nav.tramSac') } 
                    </Typography>
                    <Typography 
                        fullWidth 
                        textAlign="center"
                        component="h1" variant="h5">
                        { t('common:nav.tramSac_en') } 
                    </Typography>
                </>
            }
            {cvp && <>
                    <Typography fullWidth textAlign="center" component="h1" variant="h5">
                        { t('common:nav.cvp') } 
                    </Typography>
                    <Typography fullWidth textAlign="center" component="h1" variant="h5">
                        { t('common:nav.cvp_en') } 
                    </Typography>
                </>
            }
            <Container component="main" maxWidth="xs" className={classes.root}>
                <CssBaseline />
                <Box sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        { t('common:nav.signIn') } 
                    </Typography>
                    <Box mt={2} >
                        {showError()}
                        {showWarning()}
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            value={username}
                            onChange={handleChange("username")}
                            fullWidth
                            margin="normal"
                            id="username"
                            label= { t('common:signIn.username') }
                            name="username"
                            autoComplete="username"
                            required
                            autoFocus
                        />
                        <TextField
                            value={password}
                            onChange={handleChange("password")}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={ t('common:signIn.password') }
                            type="password"
                            id="password"
                            autoComplete="password"
                        />
                        {showAccount && <Autocomplete
                            id="account"
                            value={account}
                            freeSolo
                            options={accounts.map((option) => option)}
                            fullWidth
                            onChange={onAccountChange}
                            onInputChange={onAccountInputChange}
                            sx={{ mt: 2 }}
                            renderInput={(params) => <TextField {...params} label={ t('common:signIn.account') } />}
                        />}

                        <Grid container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              spacing={1}
                              className={classes.buttonsGroup}>
                            <Grid item xs={6}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={loading}
                                    className={classes.signInButton}
                                    color="inherit"
                                    disabled={!enabled}
                                    fullWidth>
                                    { t('common:nav.signIn') }
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={6}>
                                <Button id="clearButton"
                                        variant="contained"
                                        color="inherit"
                                        onClick={handleClear}
                                        fullWidth>
                                    { t('common:signIn.clear') }
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{mt:2}}>
                            <Grid item xs={12}>
                                <Button variant="text" onClick={handleRecoverAccount} fullWidth>
                                { t('common:signIn.forgotPassword') }
                                </Button>
                            </Grid>
                        </Grid>
						<Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{mt:2}}>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {recoverAccount && <RecoverAccount open={recoverAccount} loading={loading}
                                                   onClose={recoverAccountClosed} onConfirmed={recoverAccountConfirmed} />}
            </Container>
            <Typography 
                fullWidth 
                textAlign="center"
                position="fixed"
                bottom="0"
                left="0"
                right="0">
                { t('common:nav.footer') } 
            </Typography>
        </ThemeProvider>
    </>


}

export default SignIn;
