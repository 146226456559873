import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        conditions: [
            {preset: 'TODAY', target: '@StartTime', type: 'EQ'},
            {preset: 'ALL', target: '@HKDStatus', type: 'EQ'}
        ]
    }
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Mã trạm sạc', translateId: 'common:tramsac.maTramSac', id: "ma_tram_sac"},
            {name: 'Tên trạm sạc', translateId: 'common:tramsac.tenTramSac', id: "ten_tram_sac"},
            {name: 'Địa chỉ', translateId: 'common:misc.address', id: "address"},
            {name: 'DC', id: "dia_chi", hidden:true},
            {name: 'Phường xã', translateId: 'common:misc.phuongXa', id: "phuong_xa", hidden:true},
            {name: 'Quận huyện', translateId: 'common:misc.quanHuyen', id: "quan_huyen", hidden:true},
            {name: 'Tỉnh/Thành phố', translateId: 'common:misc.tinhTP', id: "thanh_pho", hidden:true},
            {name: 'Danh sách thiết bị', translateId: 'common:tramsac.dsThietBi', id: "ds_thiet_bi"},
            {name: 'Ghi chú', translateId: 'common:misc.note', id: "ghi_chu"}],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    showAddTramSac: false,
    showEditTramSac: false,
    rowPerPage: 10
};
const tramsacsSlice = createSlice({
    name: 'TramSacs',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        addTramSac:(state, action) => {
            return {...state, data: [...state.data, action.payload]};
        },
        setRowPerPage:(state, action) => {
            return {...state, rowPerPage: action.payload};
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, records: []};
        },
        setShowAddTramSac:(state, action) => {
            if(action.payload === false){
                return {...state, showAddTramSac: action.payload, rowDataSelected: null};
            }else{
                return {...state, showAddTramSac: action.payload};
            }
        },
        setShowEditTramSac:(state, action) => {
            if(action.payload === false){
                return {...state, showEditTramSac: action.payload, rowDataSelected: null};
            }else{
                return {...state, showEditTramSac: action.payload};
            }
        },
        setRowDataSelected:(state, action) => {
            var tramsac = state.dataGrid.records.filter(item => item.ma_tram_sac === action.payload.ma_tram_sac);
            if(tramsac.length>0){
                return {...state, rowDataSelected: tramsac[0]};
            }else{
                return {...state, rowDataSelected: null};
            }
        }
    }
});

export const {
    reset,
    addTramSac,
    setRowPerPage,
    setDataGrid,
    setRefreshData,
    setShowAddTramSac,
    setShowEditTramSac,
    setRowDataSelected } = tramsacsSlice.actions;
export default tramsacsSlice.reducer;