import React from "react";
import { makeStyles } from "@mui/styles";
import {CircularProgress} from "@mui/material";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        zIndex: "99999",
    },
    icon: {
        position: "absolute",
        top: "50%",
        left: "50%",
    }
}));

const LoadingSpinner = ({withStyle, size}) => {
    const classes = useStyles();
    return (
    <div className={withStyle === false? null: classes.root}>
        <CircularProgress className={withStyle === false? null: classes.icon}
                          size={size? size: 40}
                          style={{color: "#F86900"}}/>
    </div>
    )
}

export default LoadingSpinner;