import React, { useCallback, useState } from 'react';
import {
    Alert,
    Box, Button,
    Container,
    CssBaseline,
    Grid, TextField,
    Typography
} from "@mui/material";
import { userResetPassword } from '../../../user/userServices';
import { LoadingButton } from "@mui/lab";
import { useTranslation } from 'react-i18next';

export const SCREEN_FOGOT_PASSWORD = "screen-ForgotPassword";

export const ForgotPassword = (props) => {
}