import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SHMessage from "../../../custom-components/SHMessage";
import { signOut } from "../../../slices/EnvironmentSlice";
import { signout } from "../../../components/user/userServices";
import { reset as resetDevices } from "../../../slices/DevicesSlice";
import DropdownLanguage from "../../pages/common/DropdownLanguage";

const useStyles = makeStyles(() => ({
    root: props => ({
        background: props.backgroundColor,
        color: 'white',
        '& .MuiButton-root': {
            textTransform: "none !important",
            color: props.color,
            borderColor: "white !important",
            '&:hover': {
                backgroundColor: "transparent",
            },
        },
        '& .MuiTypography-root': {
            color: props.color,
        }

    }),

    toolbar: {
        minHeight: 15,
        maxHeight: 24,
        justifyContent: "end",
        padding: 0,
        WebkitFontSmoothing: "antialiased",
    },

    button: {
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "24px",
        fontWeight: "normal",
        margin: "0 7px",
        letterSpacing: 0,
        paddingTop: 0,
        paddingBottom: 0,
        color: 'white'
    },

    label: {
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "24px",
        margin: "0 15px",
        letterSpacing: 0,
    },

    separator: props => ({
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "24px",
        marginTop: "-3px",
        color: props.color,
    }),

    helperIcon: {
        zIndex: 1000,
    }

}));

const TopNavLayout = () => {
    const [t] = useTranslation('common')
    const environment = useSelector((state) => state.environment);

    const navTheme = environment?.user?.theme?.navTheme || {};
    const classes = useStyles(navTheme);

    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={"error"} onMessageClosed={handleMessageClosed}/>

    const handleMessageClosed = () => {
        setError(null);
    }

    const handleProfileMenuOpen = (event) => {
    };

    const handleSignOut = (event) => {
        event.preventDefault();
        signout(() => {
            dispatch(signOut());
            dispatch(resetDevices());
        })
    }

    return (
        <>
             {showErrorMessage()}
            <AppBar id="appBar" position="static" className={classes.root}>
                <Toolbar id="toolBar" className={classes.toolbar}>
                <DropdownLanguage></DropdownLanguage>
                <span className={classes.separator}>|</span>
                <Typography
                    className={classes.label}
                >
                    { t('nav.user') } : { environment?.user?.login }
                </Typography>
                <span className={classes.separator}>|</span>
                <Button
                    className={classes.button}
                    onClick={handleProfileMenuOpen}
                >
                    { t('nav.myProfile') }
                </Button>
                <span className={classes.separator}>|</span>
                <Button
                    className={classes.button}
                    onClick={handleSignOut}
                >
                    { t('common:nav.signOut') }
                </Button>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default  TopNavLayout;