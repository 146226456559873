import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import BlockDashboard from "./BlockDashboard";
import BlockData from "./BlockData";
import { useDispatch, useSelector } from "react-redux";
import SHMessage from "../../../../custom-components/SHMessage";
import { IconThemeProvider } from "../../../../custom-components/context/IconThemesContext";
import DashboardSplitPane from "../../../core/Dashboard/DashboardSplitPane";
import SHAddForm from "../../common/SHAddForm";
import {setShowAddBlock, setShowEditBlock} from "../../../../slices/car_parking/BlockSlice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { addNewBlock, updateBlock } from "../../../data/car_parking/block/blockServices";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    formSidePanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
    },
    formBody: {
        display: "contents",
    },
}));

const BlockModule = () => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const {environment, blocks} = useSelector((state) => state);

    const {
        dataGrid: { customColumns},
    } = blocks;

    const {accessToken} = environment;
    const [error, setError] = useState(null);
    const [editLoadingFinished, setEditLoadingFinished] = useState(false);
    const dispatch = useDispatch();

    const fields = [
        {name: t('common:car_parking.block.tenBlock'), id: "ten_block", type:"text"},
        {name: t('common:car_parking.block.soGio'), id: "so_gio", type:"number"}];

    const initUpdateFields = [{name: t('common:car_parking.block.maBlock'), id: "ma_block", type:"text", allowEdit: false, hidden: true},
        {name: t('common:car_parking.block.tenBlock'), id: "ten_block", type:"text", allowEdit: false},
        {name: t('common:car_parking.block.soGio'), id: "so_gio", type:"number"},
        {name: t('common:misc.status'), id: "status", type:"selectFromArray", options:[{name:'Active', value: 1}, {name:'Disable', value:0}], allowEdit: false, hidden: true}];
    const [updateFields, setUpdateFields] = useState(initUpdateFields);


    const showErrorMessage = () =>
        error && <SHMessage message={error} severity={'error'} onMessageClosed={() => setError(null)}/>

    useEffect(() => {
        if(customColumns != null && customColumns.length>0){
            setUpdateFields([...initUpdateFields, ...customColumns]);
        }
    }, [customColumns]);

    useEffect(() => {
        if(!blocks.showEditBlock || blocks.showEditBlock === false){
            return;
        }
        if(blocks.rowDataSelected === null){
            setError(t('common:error.pleaseSelectRowEdit'));

            dispatch(setShowEditBlock(false));
            return;
        }
        const updateFieldsTmp = updateFields.map(item => {
            return { ...item, value: blocks.rowDataSelected[item.id]}
        })

        setUpdateFields(updateFieldsTmp);
        setEditLoadingFinished(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocks.showEditBlock])

    useEffect(() => {
        const updateFieldsTmp = updateFields.map(item => {
            const field = initUpdateFields.find(item2=>item2.id == item.id);
            return { ...item, name: field.name};
        })
        setUpdateFields(updateFieldsTmp);
    }, [i18n.language])

    const okAddFormClickHandle = (fields) => {
        console.log(fields);
        const block = {}
        fields.forEach(item => {
            if(!item.hidden){
                block[item.id] = item.value;
            }
        })
        console.log(block);

        addNewBlock(block, accessToken).then((res) => {
            dispatch(setShowAddBlock(false));
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
        })
    }

    const okEditFormClickHandle = (fields) => {
        console.log(fields);
        const block = {}
        const customData = {};
        fields.forEach(item => {
            if(item.parent == null){
                block[item.id] = item.value;
            }else{
                if(customData[item.parent] == null){
                    customData[item.parent] = {ma_loai_xe: item.parent};
                }
                if(item.hidden){
                    customData[item.parent].id = item.value;
                }else{
                    customData[item.parent].gia = item.value;
                }
            }
        })
        var dataGia = [];
        for(var k in customData){
            const item = customData[k];
            dataGia.push(item)
        }

        block['dataGia'] = dataGia;
        console.log(block);

        updateBlock(block, accessToken).then((res) => {
            dispatch(setShowEditBlock(false));
            setEditLoadingFinished(false);
        }).catch((err) => {
            console.log(err);
            setError(err.response.data.message_id?t(err.response.data.message_id):err.response.data.message);
        }).finally(() => {
        })
    }

    const cancelAddFormClickHandle = () => {
        dispatch(setShowAddBlock(false))
    }

    const cancelEditFormClickHandle = () => {
        dispatch(setShowEditBlock(false));
        setEditLoadingFinished(false);
    }

    const sidePanel = () =>
        <div id="form-side-panel" className={classes.formSidePanel}>
            {!blocks.showAddBlock && !blocks.showEditBlock && <BlockDashboard environment={environment} loading={false}/>}
        </div>

    const formBody = () => (
        <div id="form-body" className={classes.formBody}>
            {showErrorMessage()}
            {!blocks.showAddBlock && !blocks.showEditBlock && <BlockData environment={environment} module={blocks} />}
            {blocks.showAddBlock && <SHAddForm environment={environment} module={blocks} title={t('common:car_parking.block.addBlock')} fields={fields} onOk={okAddFormClickHandle} onClose={cancelAddFormClickHandle}/>}
            {blocks.showEditBlock && editLoadingFinished && <SHAddForm environment={environment} module={blocks} title={t('common:car_parking.block.updateBlock')} fields={updateFields} onOk={okEditFormClickHandle} onClose={cancelEditFormClickHandle} okBtnLabel={t('common:button.update')}/>}
        </div>
    )

    return (
        <IconThemeProvider>
            <DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()}/>
        </IconThemeProvider>
    );
}


export default BlockModule;