// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("blue.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("blue@2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* iCheck plugin Flat skin, blue
----------------------------------- */
.icheckbox_flat-blue,
.iradio_flat-blue {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-blue {
    background-position: 0 0;
}
    .icheckbox_flat-blue.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-blue.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-blue.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-blue {
    background-position: -88px 0;
}
    .iradio_flat-blue.checked {
        background-position: -110px 0;
    }
    .iradio_flat-blue.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-blue.checked.disabled {
        background-position: -154px 0;
    }

/* HiDPI support */
@media (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_flat-blue,
    .iradio_flat-blue {
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
        background-size: 176px 22px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/blue.css"],"names":[],"mappings":"AAAA;qCACqC;AACrC;;IAEI,qBAAqB;KACrB,eAAgB;IAChB,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,WAAW;IACX,YAAY;IACZ,6DAAmC;IACnC,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;IACI;QACI,4BAA4B;IAChC;IACA;QACI,4BAA4B;QAC5B,eAAe;IACnB;IACA;QACI,4BAA4B;IAChC;;AAEJ;IACI,4BAA4B;AAChC;IACI;QACI,6BAA6B;IACjC;IACA;QACI,6BAA6B;QAC7B,eAAe;IACnB;IACA;QACI,6BAA6B;IACjC;;AAEJ,kBAAkB;AAClB;IACI;;QAEI,yDAAkC;QAElC,2BAA2B;IAC/B;AACJ","sourcesContent":["/* iCheck plugin Flat skin, blue\n----------------------------------- */\n.icheckbox_flat-blue,\n.iradio_flat-blue {\n    display: inline-block;\n    *display: inline;\n    vertical-align: middle;\n    margin: 0;\n    padding: 0;\n    width: 20px;\n    height: 20px;\n    background: url(blue.png) no-repeat;\n    border: none;\n    cursor: pointer;\n}\n\n.icheckbox_flat-blue {\n    background-position: 0 0;\n}\n    .icheckbox_flat-blue.checked {\n        background-position: -22px 0;\n    }\n    .icheckbox_flat-blue.disabled {\n        background-position: -44px 0;\n        cursor: default;\n    }\n    .icheckbox_flat-blue.checked.disabled {\n        background-position: -66px 0;\n    }\n\n.iradio_flat-blue {\n    background-position: -88px 0;\n}\n    .iradio_flat-blue.checked {\n        background-position: -110px 0;\n    }\n    .iradio_flat-blue.disabled {\n        background-position: -132px 0;\n        cursor: default;\n    }\n    .iradio_flat-blue.checked.disabled {\n        background-position: -154px 0;\n    }\n\n/* HiDPI support */\n@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {\n    .icheckbox_flat-blue,\n    .iradio_flat-blue {\n        background-image: url(blue@2x.png);\n        -webkit-background-size: 176px 22px;\n        background-size: 176px 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
