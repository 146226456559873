import { Collapse, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { round } from "lodash";
import { Resizable } from "re-resizable";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IconThemeContext } from "../../../custom-components/context/IconThemesContext";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        margin: "0",
        backgroundColor: "white",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "row",
        overflow: "hidden",
    },
    leftPanel: {
        display: "flex",
        position: "relative",
        boxShadow: "rgb(0 0 0 / 20%) -4px 0px 10px 0px",
    },
    collapseBtnContainer: {
        display: "block",
        position: "absolute",
        top: 0,
        right: "-25px",
        zIndex: 2,
    },
    collapseBtn: {
        display: "block",
        width: "50px",
        height: "50px",
        "&:hover": {
            background: "transparent",
        },
    },
    resizePanel: {
        height: "100% !important",
        position: "relative",
        borderRight: "rgba(0, 0, 0, 0.2)",
    },
    sidePanel: {
        width: "100%",
        height: "100%",
        paddingRight: "2px",
    },
    mainContent: {
        flexGrow: 2,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        // maxHeight: "500vh",
        overflow: "hidden",
        overflowY: "auto",
    },
    roundedIconBtn: props => ({
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        width: 20,
        height: 20,
        borderColor: props.color,
        borderWidth: "1.5px",
        borderStyle: "solid",
        borderRadius: 15,
        padding: 1,
        margin: "0 auto",
        backgroundColor: "white",
    }),
    collapseIcon: props => ({
        color: props.color,
        fontSize: 12,
        '&:hover': {
            backgroundColor: "transparent"
        },
    }),
    animRotateIcon: {
        animation: "$spin .5s",
    },
    animRotateRevIcon: {
        animation: "$spinRev .5s",
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(180deg)",
        }
    },
    "@keyframes spinRev": {
        "0%": {
            transform: "rotate(180deg)",
        },
        "100%": {
            transform: "rotate(0deg)",
        }
    },
}));

const DashboardSplitPane = (props) => {
    const { environment } = useSelector((state) => state);

    const {iconTheme} = useContext(IconThemeContext);
    const classes = useStyles(iconTheme);

    const {sidePanel, mainContent, sidePanelWidth} = props;

    const resizableBoxRef = useRef(null);

    const defaultWidth = 400;

    const [maxPanelWidth, setMaxPanelWidth] = useState(30);
    const [panelWidth, setPanelWidth] = useState(30);
    const [isLeftPanelHidden, setIsLeftPanelHidden] = useState(!environment.showLeftPanel);

    const handleResizeStopPanel = (e, direction, ref, d) => {
        setPanelWidth(ref.clientWidth || defaultWidth);
        localStorage.setItem("left_panel_width", ref.clientWidth || defaultWidth);
    }

    useLayoutEffect(() => {
        function updateSize() {
            const halfWindowWidth = round(window.innerWidth / 2);
            setMaxPanelWidth(halfWindowWidth);
            if (resizableBoxRef.current.state.width > halfWindowWidth) {
                setPanelWidth(halfWindowWidth);
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
      }, []);

    const onEnterCollapseLeftPanel = (node, isAppearing) => {

    }

    const onEnteredCollapseLeftPanel = (node, isAppearing) => {
        setIsLeftPanelHidden(false);
    }

    const onExitCollapseLeftPanel = (node, isAppearing) => {

    }

    const onExitedCollapseLeftPanel = (node, isAppearing) => {
        setIsLeftPanelHidden(true);
    }

    return (
        <div className={classes.root}>
            <div className={classes.leftPanel}>
                <Collapse
                    appear={false}
                    in={environment.showLeftPanel}
                    collapsedSize={12}
                    orientation="horizontal"
                    onEnter={onEnterCollapseLeftPanel}
                    onEntered={onEnteredCollapseLeftPanel}
                    onExit={onExitCollapseLeftPanel}
                    onExited={onExitedCollapseLeftPanel}
                >

                    <Resizable
                        ref={resizableBoxRef}
                        onResizeStop={handleResizeStopPanel}
                        className={classes.resizePanel}
                        defaultSize={{ width: panelWidth }}
                        minWidth={sidePanelWidth?sidePanelWidth:0}
                        maxWidth={maxPanelWidth}
                        handleStyles={{ right: { cursor: "ew-resize" } }}
                        enable={{ top:false, right:environment.showLeftPanel, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                    >
                        <Paper
                            className={classes.sidePanel}
                            elevation={0}
                            square={true}
                        >
                            {!isLeftPanelHidden || environment.showLeftPanel ? sidePanel : null}
                        </Paper>

                    </Resizable>
                </Collapse>
            </div>
            <div className={classes.mainContent} style={{width: "initial"}}>
                {mainContent}
            </div>
        </div>
    );
}
export default DashboardSplitPane