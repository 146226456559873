import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const SkeletonLoaderSidePanel = () => {

    return (
        <Stack sx={{mt: "-10px"}}>
            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
            <div style={{display: "flex", flexDirection: "column"}}>
                <Skeleton animation="wave" variant="text" width={"50%"} height={50} sx={{ml: 2}}/>
                <Skeleton animation="wave" variant="text" width={"30%"} height={50} sx={{ml: 2}}/>
            </div>
            <Skeleton animation="wave" variant="text" height={50} sx={{ml: 0.5, mr: 0.5}}/>
            <div style={{display: "flex", flexDirection: "column"}}>
                <Skeleton animation="wave" variant="text" width={"50%"} height={50} sx={{ml: 2}}/>
                <Skeleton animation="wave" variant="text" width={"30%"} height={50} sx={{ml: 2}}/>
                <Skeleton animation="wave" variant="text" width={"75%"} height={50} sx={{ml: 2}}/>
                <Skeleton animation="wave" variant="text" width={"30%"} height={50} sx={{ml: 2}}/>
            </div>
        </Stack>
    )
}

export default SkeletonLoaderSidePanel