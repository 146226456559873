import { createSlice } from "@reduxjs/toolkit";

const defaultQuery = {
    columns: [],
    filter: {
        startDate: null,
        endDate: null,
        status: 1,
        maThe: "",
        maBVVao: "",
        maBVRa: "",
        maLoaiXe: "",
        nghiVan: false
    }
}

const initialState = {
    data: [],
    dataGrid: {
        title: null,
        query: defaultQuery,
        queryView: {},
        columns: [
            {name: 'Mã thẻ', translateId: 'common:car_parking.monthly.maThe', id: "ma_the"},
            {name: 'Loại thẻ', translateId: 'common:car_parking.monthly.loaiThe', id: "loai_the"},
            {name: 'Ngày vào', translateId: 'common:car_parking.ngayVao', id: "ngay_vao", type:"datetime", format:{date:"DD/MM/YYYY hh:mm"}},
            {name: 'Bảo vệ vào', translateId: 'common:car_parking.bvVao', id: "bv_vao"},
            {name: 'Ngày ra', translateId: 'common:car_parking.ngayRa', id: "ngay_ra", type:"datetime", format:{date:"DD/MM/YYYY hh:mm"}},
            {name: 'Bảo vệ ra', translateId: 'common:car_parking.bvRa', id: "bv_ra"},
            {name: 'Giá tiền', translateId: 'common:car_parking.giaTien', id: "gia_tien", type:"INTEGER"},
            {name: 'Nghi vấn', translateId: 'common:misc.nghiVan', id: "nghi_van"},
            ],
        records: [],
        viewSession: false,
    },
    refreshData: false,
    rowPerPage: 10
};

const hauKiemSlice = createSlice({
    name: 'HauKiem',
    initialState,
    reducers: {
        reset:(state, action) => {
            return initialState;
        },
        setDataGrid:(state, action) => {
            if(action.payload.apply){
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...state.dataGrid.records, ...action.payload.records]}};
            }else{
                return {...state, refreshData:false, dataGrid: {...state.dataGrid, records:[...action.payload.records]}};
            }
        },
        setFilter:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, startDate: action.payload.startDate, endDate: action.payload.endDate}}}};
        },
        setFilterStartDate:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, startDate: action.payload}}}};
        },
        setFilterEndDate:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, endDate: action.payload}}}};
        },
        setFilterStatus:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, status: action.payload}}}};
        },
        setFilterMaThe:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, maThe: action.payload}}}};
        },
        setFilterBVVao:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, maBVVao: action.payload}}}};
        },
        setFilterBVRa:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, maBVRa: action.payload}}}};
        },
        setFilterMaLoaiXe:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, maLoaiXe: action.payload}}}};
        },
        setFilterNghiVan:(state, action) => {
            return {...state, dataGrid: {...state.dataGrid, query:{ ...state.dataGrid.query, filter:{...state.dataGrid.query.filter, nghiVan: action.payload}}}};
        },
        setRefreshData:(state, action) => {
            return {...state, refreshData: action.payload, data: {}};
        }
    }
});

export const {
    reset,
    setDataGrid,
    setRefreshData,
    setFilter,
    setFilterStartDate,
    setFilterEndDate,
    setFilterStatus,
    setFilterMaThe,
    setFilterBVVao,
    setFilterBVRa,
    setFilterMaLoaiXe,
    setFilterNghiVan} = hauKiemSlice.actions;
export default hauKiemSlice.reducer;